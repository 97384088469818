/*------------------------------------*\
    # LOCAL GENERAL SETTINGS
\*------------------------------------*/

/*------------------------------------*\
    ## COLOURS
\*------------------------------------*/

/**
 * All colours should be defined here; colour values should not be set in
 * partials.
 *
 * All colour variables should be prefixed with `$color-`. For example,
 * `$color-red`. Note the US spelling; this has been chosen to reduce
 * typographic errors in the `color` property.
 *
 * Colour variables can either be descriptive (eg `$color-red`) or
 * functional (eg `$color-text`).
 *
 * Tints or shades should use the parent variables with a `--` suffix. For
 * examples, `$color-red--light`.
 **/

$color-background: #f2f2f2;
$color-background-content: #fff;
$color-links: inherit;
$color-text: #333;
$color-shop-accent: #DE0031;
$color-shop-accent-hover: #C7002C;
$color-sale: #DE0031;
$color-header-background: #000;
$color-menu-items-border: #D3D3D3;
$color-navigation-border: #000;
$color-top-info-bar-text: #FFFFFF;
$return-button-colour: $color-sale;
$color-input-placeholder: #666;
$color-border-outline: #666;
$color-border-outline-light: #CCC;
$color-red: #dc0031;
$color-grey: #e8e8e8;
$color-grey-accent: #dadada;
$color-dark-grey: #737373;
$color-dark-grey-accent: #5e5e5e;
$color-grey-accent: #dadada;
$color-darker-grey: #454545;
$color-darker-grey-accent: #292929;
$color-black: #000;
$color-black-accent: #5e5e5e;
$color-white: #FFFFFF;
$color-background-counter-number: $color-shop-accent;
$color-swatch-border: $color-shop-accent;
$color-light-hover: #DE00310f;
$address-validation-hover: $color-light-hover;

/*------------------------------------*\
    ## BREAKPOINTS
\*------------------------------------*/
$breakpoint1: 1280px;
$breakpoint2: 960px;
$breakpoint3: 768px;
$breakpoint4: 600px;
$breakpoint5: 450px;

/*------------------------------------*\
    ## FONTS
\*------------------------------------*/

/**
 * All fonts should be defined here.
 *
 * All font-family variables should be prefixed with `$font-family-`.
 **/

//Fonts to be used are defined in the base theme
$font-family-base: "proxima-nova", Helvetica, Arial, sans-serif;
$font-family-headings: "proxima-nova", Helvetica, Arial, sans-serif;
$font-family-base-functional: "proxima-nova", Helvetica, Arial, sans-serif;
$heading-font-family: "proxima-nova", Helvetica, Arial, sans-serif;
$heading-letter-spacing: 0.1rem;
$subtitle-letter-spacing: 0.05rem;
$font-size-icon-navigation-bar: 46px;
$font-size-icon-navigation-bar-mobile: 34px;

/*------------------------------------*\
    ## MISC
\*------------------------------------*/
$arrowWidth: 5px; // Width of css arrows

$product-list-brand-display: block;

/*------------------------------------*\
    ## PRODUCT BADGES
\*------------------------------------*/
$product-badge__text-transform: uppercase;
$product-badge__font-size: 12px;

$product-badge__background--new: $color-white;
$product-badge__border--new: 2px solid $color-red;
$product-badge__color--new: $color-red;

$product-badge__background--sale: $color-red;
$product-badge__border--sale: none;
$product-badge__color--sale: $color-white;
