.checkout-index-index { // Scoping to the checkout, as the class name seems a bit generic and could be used elsewhere
  .modal-popup.popup-authentication._show { // Required to beat specificity
    @include loginRegisterModal;

    .modal-title {
      display: none;
    }

    .fieldset {
      .field {
        margin: 0;
      }
    }
  }
}
