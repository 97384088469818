.size-recommend-wrapper {
  display: none;
}

.recommended-size-wrapper {
  padding: 10px;
  margin: 10px 0;
  background-color: #F6F6F6;
}

.recommended-size-wrapper p {
  margin-bottom: 0;
}

.recommended-size-wrapper p span {
  color: $color-shop-accent;
}