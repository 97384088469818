@use "sass:math";
/*------------------------------------*\
    #MY ACCOUNT
\*------------------------------------*/

@include private {
  $breakpoint: medium-and-up;

  .my-account {
    @extend %clearfix;
  }

  .my-account__group {
    margin-bottom: $layout-gutter;
  }

  .my-account__group--dashboard-info {
  }

  .my-account__group--dashboard-address {
  }

  .my-account__group--address-book-contact,
  .my-account__group--address-book-address {
    @extend %u-1-of-2-#{$breakpoint};

    float: left;
  }

  @include media-query($breakpoint) {
    .my-account__group--address-book-contact {
      padding-right: math.div($layout-gutter, 2);
    }

    .my-account__group--address-book-address {
      padding-left: math.div($layout-gutter, 2);
    }
  }

  .my-account__section-container {
    @extend %clearfix;
    @extend %layout;

    font-size: 0;
    letter-spacing: 0;
  }

  .my-account__section {
    @extend %layout__item;

    font-size: rems($font-size-base);
  }

  .my-account__section--contact,
  .my-account__section--newsletter {
    @extend %u-1-of-2-#{$breakpoint};
  }

  .my-account__section--billing {
    @extend %u-1-of-2-#{$breakpoint};
  }

  .my-account__section--information,
  .my-account__section--password {
    @extend %u-1-of-2-#{$breakpoint};
  }

  .my-account__section--order-shipping-address,
  .my-account__section--order-shipping-method,
  .my-account__section--order-billing-address,
  .my-account__section--order-payment-method {
    @extend %u-1-of-2-small-and-up;
  }

  .my-account__section--order-payment-method {
    .payment-method {
      dt,
      dd {
        float: none;
      }
    }

    .table {
      @extend %responsive-table;
      @extend %responsive-table-no-caption;
    }

    #payment_table {
      tbody tr {
        th,
        td {
          text-align: left;
        }
      }
    }
  }

  .my-account__section--password {
    display: none; // Hidden by default; enabled via Magento JavaScript

    @include media-query($breakpoint) {
      float: right; // Use float because Magento JavaScript adds `display:block`
    }
  }

  .my-account__heading {
    @extend %beta;
    font-weight: 500;

    .my-account__group--address-book-contact &,
    .my-account__group--address-book-address & {
      @extend %u-visually-hidden;
    }
  }

  .my-account__list {
    @extend %form__list;

    clear: both;
  }

  .my-account__list--action {
    @extend %form__list--action;
    text-align: left;
    margin-top: 0;
  }

  .my-account__list--contact {
    margin-bottom: $spacing-unit-base;
  }

  .my-account__action {
    @extend %form__action;
  }

  .my-account__action--list {
    @extend %form__action--list;
  }

  .my-account__action--back {
    @extend %form__action--back;
  }

  .my-account__action--header,
  .my-account__action--inline {
    @include font-action(inherit);

    background: none;
    border: none;
    min-width: unset;
    padding: 0;
  }

  .my-account__action--edit,
  .my-account__action--password {
    @extend %button--small;
    @extend %form__action--left;
  }

  .my-account__header {
    margin-bottom: $layout-gutter;
  }

  .my-account__heading {
    .my-account__section--information &,
    .my-account__section--password & {
      @extend %u-visually-hidden;
    }
  }

  .my-account__heading--header {
    display: inline-block;
    margin-bottom: 0;
  }

  .my-account__order-navigation {
    @extend %list-bare;

    margin-bottom: $layout-gutter;

    & > * {
      @include media-query($breakpoint) {
        display: inline-block;
      }
    }
  }

  .my-account__order-navigation-link {
    @extend %button;

    margin-bottom: 0.3em;
    width: 100%;

    @include media-query($breakpoint) {
      margin: 0 0.3em 0 0;
      width: auto;
    }

    .my-account__order-navigation-item--current & {
      @extend %button--outline;

      color: $color-text;
    }
  }

  .my-account__order-list {
    margin-bottom: $layout-gutter;
  }

  .my-account__order-title {
    @extend %delta;

    display: block;
  }
}

.customer-account-edit .my-account__section--information .form__field--email-address {
  display: none !important;
}

/* 2.2.3 Fixes for account dashbaord */
.account-nav-content ul {
  font-family: $font-family-base;
  text-transform: capitalize;
  overflow: hidden;
  color: #000;
  font-size: 16px;
  @media screen and (min-width: 960px) {
    height: auto;
    visibility: visible;
  }
}

.account-nav-content ul li {
  border-color: #000;
  border-style: none none solid;
  border-width: 1px;
  display: table;
  width: 100%;
}

.account-nav-content ul li:first-child {
  border-top-style: solid;
}

.account-nav-content ul .nav > * {
  display: table-cell;
  height: 2.5rem;
  padding: 4px 8px;
  text-decoration: none;
  -webkit-transition: background 450ms;
  -o-transition: background 450ms;
  transition: background 450ms;
  vertical-align: middle;
}
.account-nav-content ul .nav.current > * {
  color: $color-shop-accent;
}

/* 1609 - Wishlist Non Logged In fixes */
.non-logged-in-user .account-nav-content .items .item {
  display: none !important;
}
.non-logged-in-user .account-nav-content .items .item.current {
  display: block !important;
  border-top: 1px solid #000 !important;
}

/* MMI-392 */
.sales-order-view .order-table__data--price .sale-item-cart-rrp {
  display: none;
}

.order-table__row--grand_total_incl .order-table__header,
.order-table__row--grand_total_incl .order-table__data {
  font-weight: bold;
}

.customer-account-edit .mage-error {
  color: red;
}
.customer-account-edit #custom-errors-password {
  color: red;
}
.customer-account-edit #custom-errors-password.valid {
  color: green;
}

.customer-account-edit .password-status,
.customer-account-edit .confirm-password-status {
  top: 28px;
}

@media (max-width: 960px) {
    .my-account__group--address-book-contact {
        margin-bottom: 0;
    }
}
