.rewards-slider__bar {
  background-color: $color-grey-accent;
  height: 5px;

  .ui-slider-range {
    display: inline-block;
    position: absolute;
    top: 0;
    height: 5px;
    border-radius: 10px;
    background: linear-gradient(to right, $color-shop-accent, darken($color-shop-accent-hover, 10%));
  }
}

.rewards-slider__handle {
  width: 35px;
  height: 35px;
  border: 1px solid $color-grey-accent;
  background-color: $color-white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin-top: -15px;
  margin-left: -16px !important;
}

.rewards-slider__input {
  background-color: $color-white;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  width: 65px;
  font-weight: bold;
  vertical-align: middle;
  display: inline-block;
  padding: 0 0.5rem;
  margin: 0 0.3rem;
}

.rewards-slider__label {
  padding: 1rem 0;
  font-size: 0.9rem;
  text-align: center;
}

.rewards-slider__body {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 1rem 0 0.3rem;
  font-size: 0.8rem;
  background-color: $color-background;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
  }
}

.rewards-slider__heading {
  font-weight: bold;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    &:first-child {
      padding-right: 1rem;
    }
  }
}

.use-reward-points-container {
  @include styled-checkbox();
}

.rewards-slider__heading-value {
  font-weight: lighter;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-left: 7px;
}

.rewards-slider__heading-icon {
  width: 1em;
  font-size: 2rem;
  margin-right: 10px;
  line-height: 1;
  color: $color-shop-accent;
}
.rewards-slider__toolbar {
  position: relative;
  padding: 1rem;
  background-color: $color-background;

  .react-checkout-loading__block {
    height: 100%;
  }
}

.rewards-slider__buttons {
  display: flex;
  justify-content: space-around;
  margin: 1rem -0.5rem 0;
}

.rewards-slider__button {
  margin: 0 0.5rem;
}

.checkout-rewards-slider__component {
  margin: 2rem 0;

  .checkout-app-tab-section__content & {
    margin-top: 0;
  }
}

.rewards-slider__slider-wrapper {
  display: flex;
  align-items: center;
}

.rewards-rewards-slider__mark {
  text-transform: uppercase;
  font-weight: bold;
}

.rewards-slider__label-scale {
  text-align: center;
  padding: 0.5rem;
  line-height: 1;

  &:last-child {
    padding-left: 1rem;
    padding-right: 0;
  }

  &:first-child {
    padding-right: 1rem;
    padding-left: 0;
  }
}

.react-rewards-slider {
  position: relative;
  width: 100%;

  .rc-slider-rail {
    background-color: $color-grey-accent;
    height: 5px;
  }

  .rc-slider-track {
    display: inline-block;
    position: absolute;
    top: 0;
    height: 5px;
    border-radius: 10px;
    background: linear-gradient(to right, $color-shop-accent, darken($color-shop-accent-hover, 10%));
  }

  .rc-slider-handle {
    position: absolute;
    top: 0;
    width: 35px;
    height: 35px;
    border: 1px solid $color-grey-accent;
    background-color: $color-white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin-top: -15px;
    margin-left: -16px !important;
    transform: translate3d(0, 0, 0) !important;
  }

}

.rewards-slider__earning-points {
  margin: 0;
  text-align: center;
  font-size: 0.9rem;
}

.order-earning__rewards--popup {
  position: relative;
  background-color: $color-background;
  width: 100%;
  padding: 1.5rem;
  max-width: 450px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  z-index: 1;

  .react-checkout-app__messages--button {
    top: 0.5rem;
  }
}

.order-earning__rewards--link {
  text-decoration: none;
}

.order-earning__rewards--icon {
  color: $color-shop-accent;
  vertical-align: middle;

  &::after {
    @include icomoon('info');
    line-height: inherit;
    vertical-align: baseline;
  }
}
