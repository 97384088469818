.popup_overlay {
  @include popup_overlay;
}

.returning_visitors_overlay {
  @include popup_overlay;
}

.popup_content {
  background-color: $color-popup-background;
  width: 90%;
  padding: 20px;
  position: relative;
  //margin: 5% auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: auto;

  .popup-close {
    position: absolute;
    right: 20px;

    button:before {
      @include icon-font;
      content: "\E14C";
    }
  }
}

.popup_title {
  @include functional-text-black;
  font-size: 1.5em;
  padding: 0;
  text-align: center;
}

.popup_info_text {
  @include functional-font-base;
  text-transform: inherit !important; /* IE Fix */
  text-transform: initial !important;
}

.popup-close-button {
  @include icon-font;
  content: "\e14c";
  display: block;
  height: 23px;
  line-height: 17px;
}

// Tablet Screen
@include media-query(small-and-up) {
  .popup_content {
    width: 80%;
  }
}

// Desktop Screen
@include media-query(medium-and-up) {
  .popup_content {
    width: 40%;
  }
}

.popup_default_btn{
  @include popup_default_btn;

}

.popup_button_wrapper{
  width: 50%;
  float: left;
}

.popup_returning_visitors_box {
  top: 30%;
  left: 15%;
  position: absolute;
  height: 226px;

  strong {
    font-weight: bold;
  }

  .returning-visitors-popup__content {
    padding-top: 10px;
    text-align: center;
  }

  .returning-visitors-popup__buttons {
    padding-top: 10px;
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
    }
  }
}

.popup_submit_btn{
  @include popup_submit_btn;
}

.popups-util__header {
  .popups-util__title {
    @include functional-text-grey;
    font-size: 1.5em;
    margin-top: 0;
  }
}

//Need to account for the $10 off badge that is negatively position at the top of the popup
.popup__returning-visitor {
  margin-top: 25%;
  @include media-query(small-and-up) {
    margin-top: 15%;
  }
  @include media-query(large-and-up) {
    margin-top: 10%;
  }

  .popup_wrapper {
    text-align: center;

    .newsletter_popup_header_image {
      margin-top: -100px;
    }
  }
}

.popup_wrapper {
  background: $color-popup-background;
  position: fixed;
  z-index: 9999;
  opacity: 0.95;
  padding: 20px;
  width: 70%;
  top: 30%;
}

/***********************************
 *  Returning Visitor Mobile Fixes *
 ********************************* */
@media (max-width: 960px) {
  #popup_returning_visitors_box .popup_wrapper {
    width: 70%;
  }

  .popup_returning_visitors_box {
    left: 15% !important;
  }
}
@media (max-width: 440px) {
  #popup_returning_visitors_box .popup_wrapper {
    top: 21%;
  }
  .returning-visitors-popup__buttons {
    display: block !important;
  }
  .returning-visitors-popup__buttons .popup_default_btn {
    display: block;
    text-align: center;
  }
  .returning-visitors-popup__buttons .popup_submit_btn {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}

.newsletter_popup_wrapper {
  left: 15%;
}
.nl-compliance-text {
  margin-top: -40px;
}
.checkout-compliance-text {
  font-size: 12px;
  margin-bottom: 10px;
}
.site-footer__subscribe .nl-compliance-text {
  font-size: 14px;
}

.popup__returning-visitor {
  transform: initial !important;
  left: 20% !important;
}