.site-header__strip {
  background-color: $color-black;

  .header-strip__list {
    .header-strip-item {
      a {
        color: $color-white;
        &:hover {
          color: $color-white;
        }
      }
    }
  }

  .header-strip-item--storefinder {
    flex: 0 auto;
  }

  .header-strip-item--account {
    margin-left: auto;
  }
}
