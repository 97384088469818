.cart-info-links {

  .icon-returns:before {
    @include icomoon('return');
  }

  .icon-fast-shipping:before {
    @include icomoon('delivery');
  }

}


#maincontent .cart-page-heading {
  width: 50%;
  flex-grow: 0;
}

#shopping-cart-table {

  .cart-item-actions__remove-btn,
  .cart-item-actions__wishlist-btn,
  .cart-item-actions__edit-btn {
    color: $color-black;

    &:hover,
    &:focus {
      color: $color-white;
      border-color: $color-shop-accent-hover;
    }
  }

  .cart__action--update {
    color: $color-black;

    &:hover,
    &:focus {
      background: none;
      color: $color-shop-accent;
    }
  }
}

.cart__list--action .cart__action--checkout {
  &:hover,
  &:focus {
    border-color: $color-shop-accent-hover;
    background-color: $color-shop-accent-hover;
  }
}
