/*------------------------------------*\
    #BREADCRUMBS
\*------------------------------------*/

/*ul*/
.breadcrumbs {
    @extend %list-inline;

    padding-bottom: $spacing-unit-base;

    & > li {
        // Target only list items that are proceeded by a list item
        & + li {
            &:before {
                content: "\2192";
            }
        }
    }
}
