.item-summary-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

/**
 * Item Summary
 */
.item-summary {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  position: relative;
}


.item-summary__product-image img {
  width: 100%;
}

.item-summary__option {
  display: flex;
  flex-direction: row;
}

.item-summary__option .select-input {
  appearance: none;
}
.item-summary__options--size .item-summary__option-label {
  display: none;
}
.item-summary__options--qty .item-summary__option-label {
  display: none;
}

.item-summary__product-actions .remove-item {
  position: absolute;
  top: 0;
  right: 0;
}

.checkout-app-shopping-bag {
  flex-grow: 1;

  .items {
    position: relative;

    .react-checkout-loading__block {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.checkout-app-shopping-bag__list {
  margin-bottom: 1rem;
}

$item-summary__image-width: 96px;
$item-summary__image-padding: 15px;
$item-summary__close-size: 16px;

.checkout-app-shopping-bag-item-summary {
  border-bottom: 1px solid $color-grey;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  position: relative;
}

.checkout-app-shopping-bag-item-summary__product-image {
  margin-right: $item-summary__image-padding;
  display: flex;
}

.checkout-app-shopping-bag-item-summary__product-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 75%;
  flex-grow: 1;

  align-content: flex-start;
  padding-bottom: 1rem;
}

.checkout-app-shopping-bag-item-summary__title {
  font-family: $font-family-base;
  font-size: 1rem;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  margin-bottom: 0;
  width: 100%;
}

.checkout-app-shopping-bag-item-summary__link {
  text-decoration: none;
}

.checkout-app-shopping-bag-item-summary__subtitle {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: normal;
}

.checkout-app-shopping-bag-item-summary__price {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.checkout-app-shopping-bag-item-summary__option {
  width: auto;
  margin-bottom: 5px;
}

.checkout-app-shopping-bag-item-summary__option:nth-child(odd) {
  margin-left: auto;
}

.checkout-app-shopping-bag-item-summary__option .select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkout-app-shopping-bag-item-summary__option .select-label {
  margin-right: 8px;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.checkout-app-shopping-bag-item-summary__option .select-input {
  text-align: center;

  height: auto;
  padding: 2px 8px;
  padding-right: 22px;

  border-radius: 5px;
  border-color: $color-grey-accent;

  cursor: pointer;
}

.checkout-app-shopping-bag-item-summary__actions {
  margin-left: $item-summary__image-width + $item-summary__image-padding;
  width: 100%;

  display: flex;
  justify-content: flex-end;
}

.checkout-app-shopping-bag-item-summary__action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.checkout-app-shopping-bag-item-summary__action--icon-left {
  flex-direction: row-reverse;
}

.checkout-app-shopping-bag-item-summary__action-label {
  text-transform: uppercase;
  font-size: 12px;
}

.checkout-app-shopping-bag-item-summary__action-icon {
  flex-grow: 0;
  font-size: 22px;
  display: flex;
}

.checkout-app-shopping-bag-item-summary__action:focus,
.checkout-app-shopping-bag-item-summary__action:hover {
  background: transparent;
}

.checkout-app-shopping-bag-item-summary__action--remove-item {
  position: absolute;
  top: 0.45rem;
  right: 0.1rem;
  color: black;
}

.checkout-app-shopping-bag-item-summary__action-icon--remove-item {
  font-size: $item-summary__close-size;
}

.checkout-app-shopping-bag-item-summary__action--move-to-wishlist::after {
  font-size: 22px;
}

.item-summary__configurables {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

.checkout-app-shopping-bag-item-summary__item-price {
  font-weight: bold;
  display: inline-block;
  margin-right: 1rem;

  &.item-price__onSale {
    text-decoration: line-through;
  }
}

.checkout-app-shopping-bag-item-summary__item-price--sale {
  color: #DE0031;
}

.checkout-app__totals {
  position: relative;
  padding: 1rem;
  margin-top: 2rem;
  background-color: $color-background;

  .react-checkout-loading__block {
    height: 100%;
  }
}

.checkout-app__totals-heading {
  margin: 0 0 1rem;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.checkout-app__subtotals {
  position: relative;
  padding: 1rem;

  .react-checkout-loading__block {
    height: 100%;
  }
}

.checkout-app__totals-summary .price-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.checkout-app__totals-summary .price-display__price {
  font-weight: bold;
}

.order-totals__grand_total {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid $color-black;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
}

.order-totals__total__gst {
  font-size: 0.9rem;
  font-weight: normal;
}

.checkout-app-summary__title {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: 1rem 0;
}

.order-earning__rewards {
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
  background-color: $color-background;
  text-align: center;
}

.order-earning__points {
  font-weight: bold;
}
