/**
 * Styling for the Afterpay PDP Popup 
 */
.pdppopup {
    padding: 0 24px !important;
    background: #b2fce4 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 2px; 
    width:100% !important;
    line-height: 1.1;
    display: block;
    overflow: auto; 
    margin-top: 10px;         
}

.pdppopup .popup-close button:before {
    font-family: "Material Icons";
    font-size: 26px;
    line-height: 2.3em !important;
    content: "\E14C";
}

@media (min-width: 960px) {
    .popup_content.pdppopup {
      width: 60% !important;
      max-height: 100% !important;
      margin-top: 0px !important;
    }  
} 
