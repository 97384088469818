@media (max-width: 960px) {
    .block-photo-slider .photos-slick li.instagram-photo.default {
        width: 48% !important;
        padding-top: 50% !important;
        margin: 0 5px 5px 5px;
        padding-bottom: 0 !important;
    }
    .block-photo-slider .photos-slick li.instagram-photo {
        display: inline-block;
    }
    .instashop-wrapper-parent .block-photo-slider ul.photos-slick {
        margin-top: 10px;
    }
}
