.rv-popup-wrapper .popup_wrapper {
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  top: 20%;
  width: 40%;
}

.rv-popup-wrapper #returning-visitor-image-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
}

.rv-popup-wrapper .popup_title {
  margin-top: 10px;
  font-size: 40px;
}

.rv-popup-wrapper .returning-visitors-popup__content {
  font-size: 18px;
  padding: 6px 50px;
}

@media (max-width: 690px) {
  .rv-popup-wrapper .popup_title {
    font-size: 30px;
  }
}