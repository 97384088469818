.react-checkout .afterpay-info {
  text-align: center;
  padding-top: 10px;
}

.afterpay-info__title img {
  height: 50px;
  margin-right: 5px;
}

.afterpay-info__more-info {
  font-size: 11px;
}

.afterpay-info__payments-total {
  font-weight: 600;
}

.afterpay-info__redirect-info {
  font-size: 14px;
  margin: 1em 0 0;
  padding: 0 30px;
}

.checkout-app-tab-section.payment .afterpay-info .button--link {
  text-transform: uppercase;
}

.checkout-app-tab-section.payment .afterpay-info__payments-wrapper {
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
}

.checkout-app-tab-section.payment .afterpay-info__payments-wrapper .afterpay-info__installment-wrapper {
  -webkit-flex: 1 1 25%;
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  padding: 0 5px;
}

.checkout-app-tab-section.payment .afterpay-info__payments-wrapper .afterpay-info__installment-wrapper .afterpay-info__payment-circle img {
  max-height: 40px;
}

.checkout-app-tab-section.payment .afterpay-info__payments-wrapper .afterpay-info__installment-wrapper .afterpay-info__payment-splits {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}

.checkout-app-tab-section.payment .afterpay-info__payments-wrapper .afterpay-info__installment-wrapper .afterpay-info__payment-installment {
  margin-bottom: 5px;
  font-size: 12px;
}
