/*
  Infinite AJAX Scrolling for the PLP/Search results styling
*/
.ias-trigger {
  padding-top: 10px;
}
.ias-load-button {
  @include widget-button;
  width: 195px;
  font-weight: 500;

  &.loading:before {
    @include icon-font;
    @include animate-spin-counter-clockwise;
    display: inline-block;
    content: '\e86a';
    vertical-align: bottom; //This somehow lines up the text perfectly in the middle
    margin-right: 10px;
  }
}
