/*------------------------------------*\
    #INPUT-GROUP
\*------------------------------------*/

/*div, form*/
.input-group,
%input-group {
    display: table;
    height: $form-input-height;
    margin-bottom: distance-from-multiple($form-input-height, $spacing-unit-base);
}


.input-group__input,
%input-group__input {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}