@mixin styled-checkbox($label-class: '.input-label', $input-class: '.form-input') {
  display: flex;
  flex-direction: row;

  #{$input-class} {
    @include u-visually-hidden;
  }

  #{$label-class} {
    display: flex;
    position: relative;
  }

  #{$label-class}::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $color-border-outline-light;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  #{$input-class}:checked + #{$label-class}::before {
    background-color: $color-shop-accent;
    border-color: $color-shop-accent;
  }

  #{$input-class}:focus + #{$label-class}::before {
    border-color: $color-black;
  }

  #{$input-class}:checked + #{$label-class}::after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 4px;
    margin-right: 1rem;
    color: $color-white;
    font-family: 'FontAwesome';
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    flex-shrink: 0;
    content: "\f00c"; //tick
  }

  #{$input-class}:checked:focus + #{$label-class}::before {
    border-color: $color-shop-accent;
  }
}

.checkout-app-form {

  .button {
    border-radius: 5px;
  }

  .checkbox-container {
    @include styled-checkbox;
  }

  .radio-container {
    @include styled-checkbox;
  }

}