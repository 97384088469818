.react-plp-app-product {
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: relative;
  border: 1px solid transparent;
  transition: border 0.2s ease;
  overflow: hidden;
}
/**
  * Product Images
  */
.react-plp-app-product__images {
  flex-grow: 1;
  flex-shrink: 0;
  align-self: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.react-plp-app-product__images-link {
  display: flex;
}

.react-plp-app-product__image-file {
  display: block;
  height: auto;
  max-width: 100%;

}

.react-plp-app-product__image-file--as-bg {
  content: '';
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.react-plp-app-product__image-file--ratio-threeqaurter {
  padding-top: 75%;
}

.react-plp-app-product__image-file--ratio-oneandaqaurter {
  padding-top: 125%;
}

.react-plp-app-product__image-file--ratio-two {
  padding-top: 125%;
}

.react-plp-app-product__image-file-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
}

.react-plp-app-product__image-file-bg--centered {
  background-position: center;
}

.react-plp-app-product__image-file-bg--covers {
  background-size: cover;
}

.react-plp-app-product__image-file-bg--contains {
  background-size: contain;
}

/**
  * On Hover Action Bar
  */
.react-plp-app-product__actions {
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  background: rgba(#FFFFFF, 0.85);
}

.react-plp-app-product__actions--offcanvas {
  overflow: hidden;
  opacity: 0;
}

.react-plp-app-product__action {
  -webkit-appearance: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 0;
  outline: none;

  padding: 0.25rem 0;
  margin-right: 0.5rem;

  color: #000;
  font-size: 1rem;
  text-align: center;
  min-width: 0;

  &:last-child {
    margin: 0;
  }
}

.react-plp-app-product__action-icon {
  text-align: center;
  color: #000;
  transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s;
  margin-right: 3px;
  font-size: 26px;
}

.react-plp-app-product__action-text {
  display: block;
  text-transform: initial;
}

.react-plp-app-product__wishlist--saved {
  color: $color-shop-accent;

  .react-plp-app-product__action-icon {
    color: $color-shop-accent;
  }
}

.react-plp-app-product__action:focus,
.react-plp-app-product__action:hover {
  color: #000000;

  .react-plp-app-product__action-icon {
    color: #000000;
  }
}

.react-plp-app-product__action:focus,
.react-plp-app-product__action:hover {
  color: $color-shop-accent-hover;
  background-color: transparent;

  .react-plp-app-product__action-icon {
    color: $color-shop-accent-hover;
  }
}

/**
    * Product Description
    */

/**
  * Description Panel
  * 1. space for .react-plp-app-product__actions
  */
.react-plp-app-product__description {
  padding-top: 0.75rem;

  overflow: hidden; // 2

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 0;
  flex-shrink: 1;
}

.react-plp-app-product__link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.react-plp-app-product__title {
  padding: 0;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: left;
  margin-bottom: 0;
  text-transform: uppercase;
}

.react-plp-app-product__subtitle {
  display: block;
  line-height: 1.2rem;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0;
}

.product__out-of-stock-message {
  margin-top: 0.75rem;
  margin-bottom: 0;
}

.react-plp-app-product__prices {
  margin-top: 0.75rem;

  .price-wrapper {
    display: flex;
  }

  .price {
    margin: 0;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
  }

  .price s {
    color: #979797;
    font-size: 0.75rem;
    font-weight: normal;
  }

  .price--sale {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 4px 0;
    color: #DA0C36;
  }

  .price--save {
    font-size: 0.7rem;
    font-weight: 600;
  }

  .price__label {
    display: none;
  }
}

.react-plp-app-product__sale-ribbon {
  background: #EE4433;

  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%) rotate(-45deg);
  transform-origin: center top;
  padding: 1.65rem 0 0.75rem;
  width: 200px;

  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  letter-spacing: 1px;
  color: #F0F0F0;

}

@media (max-width: 959px) {
    .react-plp-app-product__actions {
        display: none;
    }
}
