.promo-code__container {
  position: relative;
  padding: 1rem 0 2rem;

  .react-checkout-loading__block {
    position: absolute;
    width: auto;
    padding: 0;
    background: none;
    top: 0.5rem;
    left: auto;
    right: 0;
  }

  .promo-code__form {
    display: flex;
    align-items: flex-end;
  }

  .promo-code__form--hidden {
    display: none;
  }

  .promo-code__button {
    padding: 10px 15px;
    border-radius: 0 5px 5px 0;
    height: 2.75rem;
    margin-left: -1px;
  }

  .promo-code-input-container input.form-input {
    border-radius: 5px 0 0 5px;
  }

  .input-container.promo-code-input-container {
    flex-grow: 1;
    margin: 0;
  }

  .promo-code__toggle {
    font-weight: bold;

    &:focus,
    &:hover {
      background: none;
      text-decoration: underline;
    }
  }

  .promo-code__message {
    color: green;
  }

  .promo-code__message--error {
    color: red;
  }
}
