$contentBackgroundColour: #F3F5F4;

.two-up-images__widget + .two-up-images__widget {
  padding-top: 15px;

  @include media-query(medium-and-up) {
    padding-left: 15px;
    padding-top: 0;
  }
}
.two-up-images {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @include media-query(medium-and-up) {
    height: 384px;
  }
  li {
    list-style: none;
  }
}
.two-up-images__widget {
  @extend %u-1-of-1;
  @extend %u-1-of-2-medium-and-up;

  position: relative;
  box-sizing: border-box;
  height: 275px;

  @include media-query(medium-and-up) {
    display: inline-block;
    height: 100%;
  }
}
.two-up-images__button-container {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.two-up-images__widget-wrapper {
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.two-up-images__button-container {
  bottom: 20px;
  @include media-query(medium-and-up) {
    bottom: 35px;
  }
}
