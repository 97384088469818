/*------------------------------------*\
    #PAGER
\*------------------------------------*/

/**
 * Pagination for PLP, etc
 **/

/*div*/
.pager { }


    /*span*/
    .pager__title {
        display: inline-block;

        &:after {
            content: ":";
        }
    }


    /*ul*/
    .pager__list {
        @extend %list-inline;

        display: inline-block;

        & > li {
            display: inline-block;
            margin-left: 0.2em;
            vertical-align: middle;
        }
    }


        /*a, span*/
        .pager__item {
            @include aligent-ui-link;
        }

        /*span*/
        .pager__item--current {
            font-weight: bold;
        }

        /*a*/
        .pager__item--previous {
            &:before {
                content: "\2190";
            }
        }

        /*a*/
        .pager__item--next {
            &:before {
                content: "\2192";
            }
        }
