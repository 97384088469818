/**
 * This file contains mixins for styling the info links that are displayed in the top info bar, and on the cart page
 */

@mixin info-list-item {
  a:before {
    @include icon-font;
    vertical-align: bottom;
    display: block;
    text-align: center;

    @media (min-width: 940px) {
      margin:0 6px 0 0;
      display: inline-block;
    }
  }
}
