/*------------------------------------*\
    # COMPONENT OVERRIDES
\*------------------------------------*/

/**
 * Add local component overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

.site-header__mobile-top__inner
  .site-header__mobile-links
  li.mobile-header-search-toggle-container
  .mobile-header-search-toggle:focus {
  background: transparent;
}
.site-header__mobile-top__inner
  .site-header__mobile-links
  li.mobile-header-search-toggle-container
  .mobile-header-search-toggle:focus,
.site-header__mobile-top__inner
  .site-header__mobile-links
  li.mobile-header-search-toggle-container
  .mobile-header-search-toggle:hover {
  background-color: initial;
}

@media (max-width: 960px) {
  .popup_content .popup-close button:before {
    font-size: 16px;
  }
}

/* MMI-1112 BV position fix */
[data-bv-show="rating_summary"] .bv_main_container .bv_button_buttonMinimalist {
  display: none !important;
}

[data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
  margin-right: -1em !important;
}

/* CSS removed from static block */

/* Temp fix for layout caching issue */
.cms-page-view .page-layout--two-col .page-layout__item--primary,
.cms-home .page-layout--two-col .page-layout__item--primary {
  width: 100% !important;
}

/* Temp fix for layout caching issue */

/* Temp Fix for price slider */
.filter .filter__category .filter-price .ui-slider-range {
  bottom: 0;
}
.filter .filter__category .filter-price .ui-slider-handle {
  bottom: 0;
  margin-bottom: -8px;
}

@media (max-width: 640px) {
  .catalog-category-view .main-content .wrapper.wrapper--gutter {
    margin-top: 0 !important;
  }
}

/* Fix for styling on cms pages */
.cms-page-view .site-messages,
.cms-page-view .main-content .wrapper.wrapper--gutter {
  margin-top: 0 !important;
}

.cart-page-header,
.checkout-cart-index .site-messages {
  margin-top: 0px !important;
}

/* Afterpay modal width fix - REMOVE after 1534 deployed */
.modal-popup._show,
.modal-slide._show,
.onestepcheckout-index-index .modal-custom._show {
  max-width: initial;
  top: 50px;
  position: fixed !important;
}

.campaign-site-banner.inc-banner {
  margin-top: 0;
}

.campaign-site-banner p {
  margin: 0;
}
/* Fix for specific page alignment issues */
.cms-fusion-rewards .full-site-banner .wrapper--gutter,
.cms-privacy-policy .wrapper--gutter,
.contact-index-index .wrapper--gutter {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
  max-width: 82.5rem !important;
  padding-top: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
/* End Gutter Fix */
.campaign-site-banner {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 960px) {
  .home-main-panels {
    margin-top: 0px !important;
  }
}

/* Fix for banner sale issue. */
@media (max-width: 960px) {
  .catalog-product-view .product-info .sale-banner-exists {
    position: relative;
    top: 0 !important;
  }
  .catalog-product-view .product-img-inc-banner {
    margin-top: -100px;
  }
}
/* End Fix */

/* Fix for banner sale issue. */
@media (max-width: 960px) {
  .catalog-product-view .product-info .sale-banner-exists {
    position: relative;
    top: 0 !important;
  }
  .catalog-product-view .product-img-inc-banner {
    margin-top: -100px;
  }

  .product-info .badge-container {
    top: 10px;
  }
}
@media (max-width: 640px) {
  .site-header__mobile .mobile-delivery-returns-icon.inc-banner {
    top: -65px;
  }
  .campaign-site-banner.inc-banner {
    margin-top: 130px;
  }
  .main-content.inc-banner {
    padding-top: 0 !important;
  }
  .product-info .badge-container {
    top: 60px;
  }
}

/* Fix for BIS button to hide initially */
.product-notify-me-wrapper .hidden-notify {
  display: none;
}

/* Fix for BV Star */
.product-info__main .product-info__title {
  margin-top: 0px !important;
  padding-top: 12px !important;
}

/* Fix for sale badge */
.wrapper--gutter {
  position: relative;
}
@media (max-width: 750px) {
  .product-info .badge-container {
    margin-left: 0px !important;
  }
}
@media (max-width: 960px) {
  .product-info .badge-container {
    top: 10px !important;
  }
}

.navigation-bar-scrolled .navigation-bar__group--account > li {
  padding-left: 5px !important;
  padding-right: 5px !important;
  text-align: center !important;
}
.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.wishlist {
  padding-left: 10px !important;
  border-left: 1px solid #b6b6b6 !important;
  padding-right: 0 !important;
}
.navigation-bar-scrolled ul.navigation-bar__group li.compare > a:before {
  top: 1px !important;
  width: 28px !important;
  height: 28px !important;
}
.navigation-bar-scrolled .search-suggest-icon-wrapper .search-suggest-icon::before {
  top: 2px !important;
  position: relative !important;
}

html.modalOpen {
  overflow: hidden !important;
  height: 100vh !important;;
}