.checkout-index-index h1 {
  display: none;
}

.checkout__layout-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #EEEEEE;

  .site-header__logo-container {
    width: 150px;

    img {
      height: auto;
    }
  }
}

.checkout__layout-footer {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem 0;
  border-top: 1px solid #EEEEEE;
}

.checkout__layout-links {
  font-size: 0.6rem;
}

.checkout__layout-link {
  color: #777777;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 0.5rem;
  transition: color 0.2s ease;

  &:hover,
  &:focus {
    color: $color-black;
  }

  .u-icomoon::after {
    font-size: 1.3em;
  }

}

.checkout__layout-link-primary {
  color: $color-black;
  font-weight: bold;

  &:hover,
  &:focus {
    color: rgba($color-black, 0.8);
  }
}

.checkout__layout-link-contact {
  display: none;
}

.checkout__layout-footer {
  .footer-payment-methods__list {
    text-align: center;
  }

  .footer-payment-methods__item {
    margin: 3px;
  }
}

.footer-payment-methods__item-text {
  width: auto;
  vertical-align: middle;
  font-size: 0.8rem;
  color: #777777;
}

.footer-payment-methods__item-icon {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid currentColor;
  padding: 3px;
  font-size: 0.8em;

  &::after {
    font-size: 1.6em;
    vertical-align: -4px;
  }

}
