.react-checkout-loading__block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(255, 255,255, 0.5);
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s 0.3s ease;
}

.react-checkout-loading__block--is-active {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s ease;
}

.react-checkout-loading__spinner {

  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;

  &::after,
  &::before {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    content: '';
  }

  &::before {
    border: solid 6px #CCCCCC;
  }

  &::after {
    border: solid 6px transparent;
    border-top-color: #DE0031;
    animation: cp-round-animate 0.7s ease-in-out infinite;
  }
}

@keyframes cp-round-animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// app loader
.checkout-loader--fixed {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.checkout-app > .react-checkout-loading__block {
  background: none;
}

.checkout-loader--small .react-checkout-loading__spinner {
  width: 24px;
  height: 24px;
}

.checkout-loader--small .react-checkout-loading__spinner:before,
.checkout-loader--small .react-checkout-loading__spinner:after {
  width: 24px;
  height: 24px;
  border-width: 2px
}
