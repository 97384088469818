/*------------------------------------*\
    #COLLAPSE
\*------------------------------------*/

/**
 * Use in conjunction with ui-collapse.js to create
 * cross-browser CSS height transitions
 **/

.collapse,
%collapse {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    
    &._open {
        height: auto;
        visibility: visible;
    }

    // Make the list visible as soon as the animation is ready
    &._animated {
        visibility: visible;
    }

    // Browsers treat transitions from `height: auto` inconsistently:
    // Some browsers (such as Google Chrome) simply ignore the transition,
    // while others (such as Safari) treat it the same as `height: 0`.
    // By applying the transition property after the animation has begun
    // (but before the height has changed) we can get consistent behaviour.
    &._animated--active {
        transition: height $transition-time;
    }
}