$add-to-bag-button-height: 40px;

.widget-button {
  @include widget-button;
}

.minisearch__control--submit {
  @include icon-font;
  text-indent: -9999px;
  line-height: 0;

  &:after {
    content: '\e8b6';
    text-indent: 0;
    display: block;
    line-height: inherit; /* IE Fix */
    line-height: initial;
  }
}

.navigation-bar-mobile__toggle {
  outline: none;
  display: inline-block;

  &:before {
    @include icon-font;
    font-size: 30px;
    display: inline-block;
    content: '\e5d2';
    margin-top: -9px;
    vertical-align: bottom;
  }

  //Change to an X icon when the menu is open
  &.open:before {
    content: '\e5cd';
  }

  &:hover {
    background-color: transparent;
  }
}

@mixin add-to-bag-button {
  @include functional-text-white;
  font-size: 19px;
  width: 100%;
  height: $add-to-bag-button-height;
  background-color: $color-shop-accent;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
}

@mixin out-of-stock-button {
  @include functional-text-white;
  font-size: 19px;
  width: 100%;
  height: $add-to-bag-button-height;
  background-color: #999999;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
}

@mixin save-for-later-button {
  @include small-text-black;
  &:before {
    @include icon-font;
    content: '\e87d';
    display: inline-block;
    margin-top: -8px;
    vertical-align: middle;
  }
}
