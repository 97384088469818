.cart-fusion-points-wrapper {
  background: $color-black;
  color: $color-white;
  margin: -21px -21px 20px;
  padding: 20px;

  &:before {
    @include icomoon('rewards');
    font-size: 35px;
    margin-bottom: 5px;
    display: inline-block;
  }

  h4 {
    margin: 0;
  }

  .fusion-point-count {
    font-weight: bold;
  }
}

.up-arrow {
  border-bottom-color: $color-black;
}

.minicart {
  .minicart__summary {
    .minicart__count {
      color: $color-black;
      background-color: $color-white;

      @media screen and (min-width: 960px) {
        color: $color-white;
        background-color: $color-black;
        right: 3px;
        top: 4px;
      }
    }
  }

  .minicart__details {
    .block-content {
      display: flex;
      flex-wrap: wrap;

      .cart.actions {
        margin: 0;
        flex: 1;

        .action {
          padding: 11px 10px;
        }
      }

      .minicart-items-wrapper,
      .items-total,
      .subtotal {
        width: 100%;
      }
    }
  }
  .minicart-actions {
    font-weight: 500;
  }

  .subtotal {
    .price-container {
      font-weight: 500;
    }
  }

  .minicart-items {
    .product-item {
      .product-item-details {
        padding-right: 0;

        .minicart-details-header {
          padding-right: 40px;
        }

        .product.options.list {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.minicart-bag-btn {
  .viewcart {
    display: block;
    color: $color-black;
    background: $view-cart-background;
  }
}
