.modal-content-forgot-password {
  .block-content {
    position: relative;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
    display: none;

    img {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.is-loading {
    .loading-overlay {
      display: block;
    }
  }
}
