/*------------------------------------*\
    #LOGIN
\*------------------------------------*/

.login {
    @extend %layout;

    display: flex;
    flex-wrap: wrap;
}


.login__item {
    @extend %layout__item;
    @extend %u-1-of-2-medium-and-up;
    
    margin-bottom: $spacing-unit-base;
    position: relative;
}


.login__intro {
    font-weight: normal;
    margin-bottom: $spacing-unit-base;
}


.login__list {
    @extend %form__list;
}

.login__list--action {
    @extend %form__list--action;
}


.login__action {
    @extend %form__action;
}

.login__action--list {
    @extend %form__action--list;
}



.login__action--create {
    @include media-query(medium-and-up) {
        bottom: 0;
        position: absolute;
        right: 0;
    }
}

.login__action--remind {
    @extend %form__action--back;

    border: none;
    padding: 0;
}

/* One Page Login Register Styling */
.onepage-login-register {
  width: 80%;
  margin: 0 auto;
}
.onepage-login-register .customer-form-switcher {
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
  border-bottom: 1px solid $color-shop-accent;
}

.onepage-login-register .customer-form-switcher div {
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  padding: 5px 0;
}

.onepage-login-register .customer-form-switcher div.active {
  background-color: $color-shop-accent;
  color: #FFF;
}

// Hide register form, we default to login.
.onepage-login-register .login__item--new {
  display: none;
}

.onepage-login-register .login__item--existing,
.onepage-login-register .login__item--new {
  -webkit-flex: 0 1 80%;
  -ms-flex: 0 80%;
  flex: 0 1 80%;
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.onepage-login-register .register__intro {
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.onepage-login-register .login__item--existing h2,
.onepage-login-register .login__item--new h2 {
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: initial;
}

.onepage-login-register .login__action--login {
  margin: 0;
}

.onepage-login-register .login__action--list,
.onepage-login-register .login__action--remind {
  float: none;
}

.form__field--text .form__label {
  position: initial;
  text-align: left;
  line-height: initial;
  padding: initial;
}

.onepage-login-register .login__list--action {
  margin-top: initial;
  width: 120px;
  margin: 0 auto;
}

.onepage-login-register .login__list--forgot {
  width: 100%;
}

.onepage-login-register .login__action--list {
  padding: 5px 0;
  margin-right: 0;
}

.onepage-login-register .login__action--remind {
  text-transform: capitalize;
  color: #000;
  margin-top: 10px;
}

.onepage-login-register .create-account__item--personal-information {
  width: 100% !important;
}

.onepage-login-register .create-account__item--personal-information .create-account__item--sign-in-information {
  width: 100% !important;
  padding: 0;
}

.onepage-login-register .customer-dob-wrapper label {
  display: block;
  text-align: left;
  text-transform: uppercase;
}

.onepage-login-register .form__field--newsletter {
  width: 100%;
  text-align: left;
}

.onepage-login-register .form__action--back {
  display: none;
}

.onepage-login-register .form__action--submit {
  float: none;
  margin: 0 auto;
  margin-top: 20px;
}

.onepage-login-register .login__item--existing label,
.onepage-login-register .login__item--new label {
  line-height: 24px;
  padding-bottom: 2px;
}

.onepage-login-register .login__list--action .login__list-item,
.onepage-login-register .form__list--action .form__list-item {
  margin: 0;
}

.onepage-login-register .form__list--action .form__list-item {
  margin-top: 15px;
}

@media (min-width: 960px) {
  .customer-dob-wrapper {
    margin-top: -6px;
  }
}

@media (max-width: 640px) {
  .onepage-login-register .login__item--existing,
  .onepage-login-register .login__item--new {
    -webkit-flex: 0 1 100%;
    -ms-flex: 0 100%;
    flex: 0 1 100%;
  }
  .onepage-login-register {
    width: 100%;
  }
  .onepage-login-register .form__field--newsletter {
    text-align: center;
    margin-top: 0;
  }
  .onepage-login-register .create-account__item--action .form__action--submit {
    margin-top: 0;
  }
}
