@use "sass:math";
/*------------------------------------*\
    #GENERAL SETTINGS
\*------------------------------------*/

/* = Colours = */

/**
 * All colours should be defined here; colour values should not be set in
 * partials.
 *
 * All colour variables should be prefixed with `$color-`. For example,
 * `$color-red`. Note the US spelling; this has been chosen to reduce
 * typographic errors in the `color` property.
 *
 * Colour variables can either be descriptive (eg `$color-red`) or
 * functional (eg `$color-text`).
 *
 * Tints or shades should use the parent variables with a `--` suffix. For
 * examples, `$color-red--light`.
 **/

// Colour variables go here.

$color-background: #fcfcfc;
$color-links: inherit;
$color-text: #333;





/* == Utility colours == */

$color-success: shade(green, 20%);
$color-success--light: tint(green, 80%);

$color-warning: shade(orange, 20%);
$color-warning--light: tint(orange, 80%);

$color-danger: shade(red, 20%);
$color-danger--light: tint(red, 80%);








/* = Fonts =*/

/**
 * All fonts should be defined here.
 *
 * All font-family variables should be prefixed with `$font-family-`.
 **/

$font-size-base: 16px;  // Must be px
$line-height-base: 1.5;  // Must be unitless ratio





/* = Layout = */

$spacing-unit-base: $line-height-base * 1rem; // Must be rems
$spacing-unit-base--small: math.div($spacing-unit-base, 2); // Used for compatibility with inuitcss

$spacing-unit-column-padding-vertical: math.div($spacing-unit-base, 2);  // Padding at the viewport boundary


$max-width: rems(1200px);
$min-width: rems(320px);


$layout-gutter: $spacing-unit-base;





/* = Animation = */

$transition-time: 300ms;





/* = Media queries = */

/**
 * This notation has been chosen as it is compatible with Inuit CSS
 * `media-query()` mixin found in `inui-responsive-tools/_tools.responsive`.
 *
 * Note that the largest media query should be smaller than $max-width,
 * while the smallest media query should be  larger than $min-width.
 **/

$media-queries: (
    "small-and-up"    "screen and (min-width: #{rems(480px)})",
    "medium-and-up"    "screen and (min-width: #{rems(720px)})",
    "large-and-up"  "screen and (min-width: #{rems(1024px)})"
);
