.promotion-code-container {}

#discount-form .action-apply {
  &:hover,
  &:focus {
    color: $color-white;
  }
}

#discount-form .label {
  cursor: default;
}
