/*
  Over-write the default outline hover effect for the swatches
*/
// Magento sets the selected swatch outline to orange, which causes lots of issues on mobile due to hover styles,
// so the outline has been disabled, and a custom implementation of highlighting the selected swatch is implemented
// in the _components.swatches.scss file
.swatch-option {
  outline: none !important;

  &.disabled:after {
    content: none !important;
  }
}

.out-of-stock {
  text-decoration: line-through;
  border-color: #AAA !important;
  color: #AAA !important;

  &.swatch-option {
    cursor: default;
  }
}