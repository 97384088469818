
.find-in-store-modal {
    width: 90%;
    height: 80%;
}

    .find-in-store-modal__contents {
        display: flex;
        flex-direction: row;

        border-radius: 3px;
        box-shadow: 0 7px 10px 0 rgba(black, 0.25);
    }

    .find-in-store-modal__main {
        width: 50%;
        height: 97%;
    }
    .find-in-store-modal__map {
        width: 50%;
        height: 97%;

        border-bottom: 0;
    }

    .find-in-store__body {
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
    }

        .find-in-store__postcode-search {
            width: 25%;
        }
        .find-in-store__stores {
            padding: 0 0 0 1em;
            width: 75%;
        }
