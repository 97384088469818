.react-plp-app {
  .react-plp-app-product-list-loader {}

  .react-plp-app-product-list-loader__trigger {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding: 1rem 0;
  }

  .react-plp-app-product-list-loader__trigger-button {

  }

  .react-plp-app-product-list-loader__trigger-button--disabled {
    background-color: #999999;
  }
}