/***************************
* Recommended Products PDP *
***************************/
.js-aligent_fredhopper-campaign-item-container {
  display: inline-block;
  width: 15%;
  margin-right: 5px;
  vertical-align: top;
}

.js-aligent_fredhopper-campaign-item-container img {
  width: 100%;
}

.js-aligent_fredhopper-campaign-item-container a {
  text-decoration: none;
}
.js-aligent_fredhopper-campaign-item-container a:hover {
  text-decoration: underline;
}

.js-aligent_fredhopper-campaign-item-container * {
  font-size: 14px;
  text-transform: capitalize;
}

.js-aligent_fredhopper-campaign-item-container p {
  // Reset margins for p
  margin: 0;
}

.js-aligent_fredhopper-campaign-item-container .recommended-product-original-price {
  text-decoration: line-through;
}

.js-aligent_fredhopper-campaign-item-container .recommended-product-price-container {
  margin-top: 10px;
}

.recommended-product-prod-brand {
  text-transform: uppercase;
}

/****************
* Content Tabs  *
****************/
.campaign-tab-heading-wrapper {
  width: 100%;
  display: block !important;
  border-bottom: 0px solid #ccc;
  text-align: center;
  margin-bottom: 31px;
}

.campaign-tab-heading {
  padding: 11px;
  display: inline-block;
  border-left: 0px solid #cccccc;
  border-top: 0px solid #cccccc;
  border-right: 0px solid #cccccc;
  text-decoration: none;
  color: #000 !important;
  font-family: $heading-font-family !important;
  text-transform: uppercase !important;
  margin-bottom: 0;
  position: relative;
  top: -13px;
  margin-left: auto;
  margin-right: auto;
  font-size: 26px !important;
  font-weight: 500;
  background: white;
}

#campaigns-tab-headings .active {
  background-color: #fff;
}

.campaign-tab-heading a {
  text-decoration: none;
}

.fh-campaign-tabs > div {
  display: none;
}
.fh-campaign-tabs .tab_contents_active {
  display: block;
  padding-bottom: 30px;
}

.campaign-tab-wrapper-bg {
  padding: 0 10px;
  border-top: 2px solid #94bde5;
  height: 1px;
  line-height: 0.1em;
}
/*************************
* Recently Viewed PDP    *
*************************/
.recently-viewed-pdp-container {
  background-color: #fff;
}

.recently-viewed-pdp-container * {
  font-size: 14px;
  text-transform: capitalize;
  font-family: $font-family-base;
}

.recently-viewed-pdp-container .block-title {
  display: none;
}

.recently-viewed-pdp-container li.product-item {
  margin-right: 5px;
  vertical-align: top;
}

.recently-viewed-pdp-container img.product-image-photo {
  width: 100%;
  height: auto;
}

.recently-viewed-pdp-container .price-final_price .price {
  font-family: $font-family-base;
  font-size: 14px;
}

.recently-viewed-pdp-container .product-item-link {
  text-decoration: none;
}
.recently-viewed-pdp-container .product-item-link:hover,
.recently-viewed-pdp-container .product-item-link:hover .recently-viewed-pdp-container .price-final_price .old-price {
  text-decoration: underline;
}

/* Hide on Mobile */
@media (max-width: 960px) {
  .fh-campaign-tabs {
    display: none;
  }
}
.recently-viewed-pdp-container > .block-viewed-products-list {
  height: 350px;
  padding-top: 0px;
}

#recently-viewed-pdp-container > .block-viewed-products-list .product-item-details {
  justify-content: flex-start;
  align-items: flex-start;
}

.recently-viewed-pdp-container > .block-viewed-products-list .product-item-info {
  text-align: left;
}
.recently-viewed-pdp-container .recently-viewed-prod-name {
  margin-bottom: 10px;
}
