/*------------------------------------*\
  #Iconmoon
\*------------------------------------*/
@import "./_fonts.icomoon.settings";

@font-face {
  font-family: 'icomoon';
  src: url('#{$path-fonts}/icomoon/fonts/icomoon.ttf?7ts2i2') format('truetype'),
    url('#{$path-fonts}/icomoon/fonts/icomoon.woff?7ts2i2') format('woff'),
    url('#{$path-fonts}/icomoon/fonts/icomoon.svg?7ts2i2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icomoon_base () {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  // Better Font Rendering ===========
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function icomoon ($icon) {
  @return map-get($icomoon-icons, $icon);
}
@mixin icomoon ($icon) {
  content: icomoon($icon);
  @include icomoon_base;
}

.u-icomoon:after {
  @include icomoon_base;
}


@each $name, $char in $icomoon-icons {
  .u-icomoon--#{$name}:after {
    content: $char;
  }
}
