.mobile-navigation-container {
    .header-strip-item--account {
        padding: 0;

        .show-user-navigation{
            &:after {
                @include icon-font;
                font-size: 22px;
                content: '\e145';
                position: absolute;
                right: 26px;
                top: 15px;
            }

            &.active {
                &:after {
                    content: '\e15b';
                }
            }
        }
    }
}
