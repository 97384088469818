$header-strip-spacing: 15px;

.site-header__strip {
  background-color: $color-header-background;

  .header-strip__inner {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .header-strip__list {
    list-style: none;
    padding: 0;
    margin: 0 -#{$header-strip-spacing};
    display: flex;

    .header-strip-item {
      padding-left: $header-strip-spacing;
      padding-right: $header-strip-spacing;
      padding-bottom: 0;

      a {
        text-decoration: none;
        color: $color-top-info-bar-text;
        text-transform: uppercase;
        font-weight: bold;
        font-size: .7rem;
        display: flex;
        align-items: center;
        padding: 10px 0;
        line-height: 1.2;
        letter-spacing: $heading-letter-spacing;

        &:before {
          font-size: 28px;
          margin-right: 4px;
        }

        &:hover {
          color: $color-grey;
        }
      }
    }
  }

  // Create alignment gap
  .header-strip-item--storefinder {
    flex: 1 1 auto;
  }

  .header-strip-item--storefinder a:before {
    @include icomoon('find-store');
  }
  .header-strip-item--contact a:before {
    @include icomoon('call');
  }
  .header-strip-item--account > a:first-child:before {
    @include icomoon('user');
  }

  .user-nav {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0 20px 10px;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 3px 3px rgba(0,0,0,0.15);
    background: #000;
    z-index: 1;

    .user-nav__link {
      white-space: nowrap;
    }
  }

  .header-strip-item--account {
    position: relative;

    &.active {
      .user-nav {
        display: block;
      }
    }
  }
}
