/***************************
* Recommended Products PDP *
***************************/
.js-aligent_fredhopper-campaign-item-container {
    display: inline-block;
    width: 15%;
    margin-right: 5px;
    vertical-align: top;
}

.js-aligent_fredhopper-campaign-item-container img {
    width: 100%;
}

.js-aligent_fredhopper-campaign-item-container a {
    text-decoration: none;
}
.js-aligent_fredhopper-campaign-item-container a:hover {
    text-decoration: underline;
}

.js-aligent_fredhopper-campaign-item-container * {
    font-size: 14px;
    text-transform: capitalize;
}

.js-aligent_fredhopper-campaign-item-container p {
    // Reset margins for p
    margin: 0;
}

.recently-viewed-pdp-container:not(.tab_contents_active) { display: none;}
.recently-viewed-pdp-container.tab_contents_active .block-viewed-products-list .block-title{display:none;}

.js-aligent_fredhopper-campaign-item-container .recommended-product-original-price {
    text-decoration: line-through;
}

.js-aligent_fredhopper-campaign-item-container .recommended-product-price-container {
    margin-top: 10px;
}

.recommended-product-prod-brand {
    text-transform: uppercase;
}

/****************
* Content Tabs  *
****************/
.campaign-tab-heading-wrapper {
    width: 100%;
    display: block !important;
    border-bottom: 0px solid #ccc;
    text-align: center;
    margin-bottom: 31px;
}

.campaign-tab-heading {
    padding: 11px;
    display: inline-block;
    border-left: 0px solid #cccccc;
    border-top: 0px solid #cccccc;
    border-right: 0px solid #cccccc;
    text-decoration: none;
    color: #000 !important;
    //font-family: $heading-font-family !important;
    text-transform: uppercase !important;
    margin-bottom: 0;
    position: relative;
    top: -13px;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px !important;
    font-weight: 500;
    background: white;
}

#campaigns-tab-headings .active {
    background-color: #fff;
}

.campaign-tab-heading a {
    text-decoration: none;
}

.fh-campaign-tabs .tab_contents_active {
    display: block;
    padding-bottom: 30px;
}

.campaign-tab-wrapper-bg {
    padding: 0 10px;
    border-top: 2px solid $color-shop-accent;
    height: 1px;
    line-height: 0.1em;
}

/* Hide on Mobile */
@media (max-width: 960px) {
    .fh-campaign-tabs {
        display: none;
    }
}

@media (min-width: 960px) {
    .product-info {
        margin-bottom: 30px;
    }
}

.recently-viewed-pdp-container:not(.tab_contents_active) { display: none;}