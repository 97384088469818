/* Shipping Form START */
.address-validation-enabled #co-shipping-form {
    fieldset.street, div[name="shippingAddress.postcode"], div[name="shippingAddress.city"], div[name="shippingAddress.region_id"] {
        display: none;
    }
}

#co-shipping-form {
    #show-full-address-line {
        display: none;
        color: $color-shop-accent;
        text-decoration: underline;
        cursor: pointer;
    }

    #address-finder-text {
        display: block;
        text-align: right;
    }

    .full-line-address {
        #address_suggestion {
            position: relative;
            left: 0;
            right: 0;
            background-color: #FFF;
            z-index: 1;
            margin: 2px 0 0 0;
            list-style: none;
            border: 2px solid #000;
            margin-bottom: -30px;

            li {
                padding: 7px 15px;
                width: 100%;

                &:hover {
                    background-color: $address-validation-hover;
                    cursor: pointer;
                }
            }
        }

        .address-suggest-manual-option {
            text-transform: initial;
            border-top: 2px solid #000;
            font-weight: 500;

            &:hover {
                background-color: $address-validation-hover;
                cursor: pointer;
            }
        }
    }
}
/* Shipping Form END */

/* Billing Form START */
.address-validation-enabled #co-payment-form {
    fieldset.street, div[name="billingAddressbraintree.postcode"], div[name="billingAddressbraintree.city"], div[name="billingAddressbraintree.region_id"] {
        display: none;
    } 
}

#co-payment-form {
    #show-full-address-line {
        display: none;
        color: $color-shop-accent;
        text-decoration: underline;
        cursor: pointer;
    }

    #address-finder-text {
        display: block;
        text-align: right;
    }

    .full-line-address {
        #address_suggestion_billing {
            position: relative;
            left: 0;
            right: 0;
            background-color: #FFF;
            z-index: 1;
            margin: 2px 0 0 0;
            list-style: none;
            border: 2px solid #000;
            margin-bottom: -10px;

            li {
                padding: 7px 15px;
                width: 100%;

                &:hover {
                    background-color: $address-validation-hover;
                    cursor: pointer;
                }
            }
        }

        .address-suggest-manual-option {
            text-transform: initial;
            border-top: 2px solid #000;
            font-weight: 500;

            &:hover {
                background-color: $address-validation-hover;
                cursor: pointer;
            }
        }
    }
}
/* Billing Form END */

/* One Step Checkout START */
.address-validation-enabled.onestepcheckout-index-index {
    .full-line-address {
        padding: 0 10px;

        input[name='custom_attributes[full_line_address]'] {
            border: 1px solid #ccc;
            height: 2.2rem;
            font-size: 16px;
        }
    }

    #co-shipping-form .full-line-address #address_suggestion {
        margin: 2px 0 0 0;
    }
}
/* One Step Checkout END */

/* General Styling Start */
.showField {
    display: block !important;
}

.address-validation-enabled fieldset.street > .form__label {
    display: none !important;
}

// Revert styling which hides the field label
.page-layout-checkout div[name='shippingAddress.country_id'] .form__label {
    height: initial !important;
    width: initial !important;
    position: initial !important;
    margin: 0 !important;
}

// Fix for border radius
.page-layout-checkout .form__input:active,
.page-layout-checkout .form__input:focus,
.page-layout-checkout .form__input:focus-visible {
    outline: 0;
    border: 2px solid #000;
}

.clearable .form__input{
    padding: 0 25px 0 1rem;
}

.clearable .form__input::-ms-clear {display: none; width:0; height:0;} /* Remove IE default X */

.page-layout-checkout .clear-icon {
    text-transform: lowercase;
    font-size: 24px;
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 30px;
}

// Hide NZ state/province as not needed
.address-validation-enabled #co-shipping-form div[name="shippingAddress.region"],
.address-validation-enabled #co-payment-form div[name="billingAddressbraintree.region"] {
    display: none !important;
}

// Background colour for disabled inputs
.address-validation-enabled {
    #co-shipping-form {
        div[name="shippingAddress.city"] .form__input, div[name="shippingAddress.region"] .form__input, div[name="shippingAddress.region_id"] .form__input {
            background-color: #F2F2F2;
        }
    }

    #co-payment-form {
        div[name="billingAddressbraintree.city"] .form__input, div[name="billingAddressbraintree.region"] .form__input, div[name="billingAddressbraintree.region_id"] .form__input {
            background-color: #F2F2F2;
        }
    }
}

// Disable pointer change on readonly items
.address-validation-enabled {
    #co-shipping-form {
        div[name="shippingAddress.city"] .form__input,
        div[name="shippingAddress.region_id"] .checkout-state-field,
        {
            pointer-events: none;
        }
    }

    #co-payment-form {
        div[name="billingAddressbraintree.city"] .billing-city-field, 
        div[name="billingAddressbraintree.region_id"] .billing-state-field 
        {
            pointer-events: none;
        }
    }    
}
/* General Styling End */