.navigation-bar .primary-navigation > li {
  font-size: 20px;
}

.primary-navigation ul.level0 {
  transition: opacity 300ms ease;
}

.primary-navigation li.level1 {
  transition: transform 300ms ease;
  font-size: 16px;
}

.primary-navigation li.level0:hover,
.primary-navigation li.level0 > a:focus +,
.primary-navigation li.level0:focus-within {

  ul.level0 {
    pointer-events: all;
    opacity: 1;

     li.level1 {
      transform: translateY(0);
    }
  }
}


.navigation-bar-scrolled {
  &.navigation-bar--primary {
    position: fixed;
  }

  .navigation-bar__wrapper {
    display: flex;
  }

  .site-header__logo-container {
    padding: 12px 10px 0 10px;
  }

  #primary-navigation {
    flex-grow: 1;
  }

  .account-navigation {
    margin: 0;
  }

  .header-icons-wrapper {
    margin: 0;
    float: none;
  }

  .fh-search-suggest-wrapper {
    padding: 0;
    top: 50px;
    margin-top: 0;
  }
}

.minisearch__control--submit,
.search-suggest-icon {
  color: #444;

  &:hover {
    background: none;
    color: #000;
  }
}

.header-strip-item {
  padding: 0 10px 10px;
  list-style: none;
}

@media (min-width: 960px) {
  .navigation-bar-scrolled .minicart__summary .minicart__count {
    right: initial;
    left: 6px;
  }
}

.navigation-bar-scrolled .navigation-bar__group--account li a.minicart__summary .minicart__label {
  display: none;
}

.navigation-bar-scrolled .account-navigation__item > .showcart:before {
  font-size: $font-size-icon-navigation-bar;
}

@media (min-width: 960px) {
  .navigation-bar-scrolled .minicart__summary .minicart__count {
    right: initial !important;
    top: 4px;
    left: 25px !important;
  }
}

@media screen and (min-width: 960px) {
  .minicart__summary .minicart__count {
    right: 33px;
  }
}
