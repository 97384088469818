.siteHeaderBannerContent {
  padding: 20px $spacing-unit-base--small;
  line-height: 1em;

  @media (min-width: $breakpoint2) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @media (min-width: $breakpoint1) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.siteHeaderBannerInnerContent {
  display: flex;
  align-items: center;
}

.site-header__banner .site-header__logo-container {
  display: block;
  flex: 0 0 240px;

  img {
    width: 100%;
    display: block;
  }

  @media (min-width: $breakpoint2) {
    flex-basis: 300px;
  }

  @media (min-width: $breakpoint1) {
    flex-basis: 353px;
  }
}

.account-navigation {
  margin: -19px 0 -12px;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.navigation-bar__group--search {
  text-align: center;
  display: inline-block;
  width: 300px;
  margin: 0 auto;
  padding: 0 0 0 30px;
  position: relative;
  vertical-align: middle;

  @media (min-width: 980px) {
    width: 515px;
  }

  .minisearch__control--text {
    height: 43px;
    /*Width set in media query below*/
    border: 1px solid #000;
    padding: 0 47px 0 20px;
    width: 100%;
  }

  .minisearch__control--submit {
    position: absolute;
    right: 2px;
    top: 9px;
  }
}

.site-header__mobile {
  padding-top: 50px;

  @media (min-width: $breakpoint2) {
    display: none;
  }
}

.site-header__mobile-top {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #c9c9c9;

  @media print {
    display: none;
  }
  @media (min-width: $breakpoint2) {
    display: none;
  }

  .minisearch {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $color-white;
    margin: 0;
    padding: 2px 15px 10px;
    border-bottom: 1px solid $color-border-outline;
    overflow-y: hidden;
    transition: all 300ms ease;

    &.hidden {
      transform: translateY(-100%);
      opacity: 0;
      pointer-events: none;
    }

    .minisearch__control--text {
      width: 100%;
      padding: 5px 30px 5px 10px;
      height: auto;
      border: 1px solid $color-border-outline;
      background-color: $color-background;

      &:focus {
        outline: none;
      }
    }

    .minisearch__control--submit {
      display: none;
    }

    .search-close {
      position: absolute;
      top: 7px;
      right: 22px;
    }

    .search-close-button {
      display: none;
      height: 26px;
      color: $color-border-outline;
      cursor: pointer;

      &:before {
        font-family: "Material Icons";
        font-size: 26px;
        line-height: 1em;
        content: "\E14C";
      }
    }

    .showThis {
      display: block;
    }
  }
}

.site-header__mobile-top__inner {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  height: 50px;
  background-color: $color-white;
  z-index: 1;
  position: relative;

  .mobile-button-container {
    display: block;
    padding-right: 15px;
  }

  .site-header__logo-container {
    flex: 1 1 auto;

    img {
      max-width: 120px;
    }
  }

  .site-header__mobile-links {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0 -5px;
    padding: 0;

    li {
      a,
      button {
        line-height: 1;
      }

      a {
        text-decoration: none;
        display: block;

        &:before {
          line-height: 1;
          display: block;
          font-size: $font-size-icon-navigation-bar-mobile;
          color: $color-shop-accent;
          margin-top: -2px;
          margin-bottom: -2px;
        }
      }

      &.wishlist > a:before {
        @include icomoon('wishlist');
      }

      &.minicart > a:before {
        @include icomoon('cart');
      }

      &.mobile-header-search-toggle-container .mobile-header-search-toggle {
        cursor: pointer;

        &:focus {
          outline: none;
        }

        &:before {
          @include icomoon('search');
          font-size: 29px;
        }
      }
    }

    & > li {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.site-header__strip,
.site-header__banner {
  display: none;

  @media (min-width: $breakpoint2) {
    display: block;
  }

}

.minisearch__control--text {
  @include placeholder {
    @include functional-text-black;
  }
}

.navigation-bar__group--account {
  margin-left: -10px;
  margin-right: -10px;

  & > li {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;

    & > a {
      font-size: 0.875rem;
      text-transform: uppercase;
      color: $color-black;
      text-decoration: none;
      display: block;
      line-height: 1;
      letter-spacing: $subtitle-letter-spacing;

      &:before {
        font-size: $font-size-icon-navigation-bar;
        display: block;
        text-align: center;
        color: $color-shop-accent;
        font-weight: normal;
      }

      &:hover {
        color: $color-text;
        text-decoration: none;

        &:before {
          color: $color-shop-accent-hover;
        }
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  .wishlist > a:before {
    @include icomoon('wishlist');
  }

  .minicart > a:before {
    @include icomoon('cart');
  }
}
