/*------------------------------------*\
    #Serendipity
\*------------------------------------*/

@font-face {
    font-family: "serendipity";
    src: url("#{$path-fonts}/Serendipity.otf"),
    url("#{$path-fonts}/Serendipity.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}

/*------------------------------------*\
    #Proxima Semibold
\*------------------------------------*/

@font-face {
    font-family: "ProximaNovaSemiBold";
    src: url("#{$path-fonts}/ProximaSemibold.otf"),
    url("#{$path-fonts}/ProximaSemibold.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}

/*------------------------------------*\
    #SangBleu
\*------------------------------------*/

@font-face {
    font-family: "SangBleuLight";
    src: url("#{$path-fonts}/SangBleuBPSans-Light.otf"),
    url("#{$path-fonts}/SangBleuBPSans-Light.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}

/*------------------------------------*\
    #Great Studio - Mollieglaston
\*------------------------------------*/

@font-face {
    font-family: "Mollieglaston";
    src: url("#{$path-fonts}/GreatStudio-Mollieglaston.otf"),
    url("#{$path-fonts}/GreatStudio-Mollieglaston.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}