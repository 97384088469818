$color-top-border-three-up: #000000;
$color-top-background-three-up: #000000;

.three-up {
  max-width: 1262px;
  margin: 0 auto;
  /*Needs !important to override the .homepage-widget + .homepage-widget selector in _components.homepage.scss*/
  padding-top: 75px !important;
}

.three-up__header {
  @extend %u-1-of-1;
  @include large-text;
  text-align: center;

  h2 {
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

.three-up__widget + .three-up__widget {
  @include media-query(medium-and-up) {
    padding-left: 15px;
  }
}
.three-up__widgets { /* ul */
  margin: 50px 0 0 0;
  list-style: none;
  padding: 0;
}
.three-up__widget { /* li */
  @extend %u-1-of-1;
  @extend %u-1-of-3-medium-and-up;

  position: relative;
  text-align: center;
  vertical-align: top;
  height: 610px;
  margin-bottom:50px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-query(medium-and-up) {
    display: inline-block;
    height: 623px;
    margin-bottom: 0;
  }
}
.three-up__widget-wrapper {
  @extend %u-1-of-1;
  border-top: 1px solid $color-top-border-three-up;
  height: 100%;
  padding: 30px 5px 0 5px;
  background-color: $widget-background-color;

  @include media-query(large-and-up) {
    padding: 40px 25px 0 25px;
  }

  &:hover {
    background-color: $widget-hover-color;
  }
}
.three-up__tag-container {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: -12px;
}
/*The second and third children have padding of 15px on the left acting as the 'gutter', so this padding is required to center the text correctly*/
.three-up__widget:not(:first-child) .three-up__tag-container {
  @include media-query(large-and-up) {
    padding-left: 15px;
  }
}
.three-up__tag {
  @include functional-text-white;
  display: inline-block;
  background-color: $color-top-background-three-up;
  min-width: 115px;
  font-weight: 400;
  line-height: 1em;
  padding:2px 0;
}

.three-up__image {
  @extend %u-1-of-1;

  background-size: cover;
  background-position: center center;
  height: 335px;
  text-indent: -9999px;
  display: block;
}

.three-up__title-container {
  margin-top: 15px;
  font-size: 20px;

  h3 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
    font-family: $font-family-headings;

    @include widgetColours;
  }
  @include media-query(medium-and-up) {
    margin-top: 40px;
  }
}

.three-up__description {
  @include paragraph-text;
  padding: 0 40px;
  line-height: 1.2em;
  @include widgetColours;

  @include media-query(small-and-up) {
    padding: 0 20px;
  }

  @include media-query(large-and-up) {
    margin-top: -25px;
    padding: 0 20px;
  }

  /* This is so the admin will be able to see they've entered too much data, without completely breaking the layout */
  max-height: 100px;
  overflow-y: auto;
}

.three-up__button-container {
  text-align: center;
  margin:20px 0;

  @include media-query(medium-and-up) {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 40px;
  }
}
