/* This level of nesting is required in order to win specificity against the default plugin styling */
.owl-theme {
  .owl-controls {
    .owl-page {
      span {
        background-color: #fff;
        border: 1px solid grey;
        height: 7px;
        width: 7px;
      }

      &.active {
        span {
          background-color: #ff5501;
          border-color: transparent;
        }
      }
    }
  }
}

.owl-theme {
  .owl-controls {
    margin-top: 0;
  }
}
