
.current-billing-address__checkbox {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.current-billing-address__checkbox-input {
  display: inline-block;
}

.current-billing-address__checkbox-label {
  display: inline-block;
  margin: 0.5rem;
}

.current-billing-address {
  display: flex;
  flex-wrap: wrap;
}

.current-billing-address__title {
  width: 100%;
}

.current-billing-address__edit,
.current-billing-address__item {
  width: 50%;
}
