/* Mini Cart Styling FWU-127 */
.minicart-wrapper .primary {
  width: 100%;
}

.minicart__summary .minicart__count {
  right: 1px;
  top: 3px;
}

@media screen and (min-width: 960px) {
  .minicart__summary .minicart__count {
    right: 5px;
    top: 5px;
  }
}

.navigation-bar__group--account .wishlist .counter, .site-header__mobile-links .wishlist .counter {
  width: 16px;
  height: 16px;
  right: 0px;
  top: 1px;
}

@media screen and (min-width: 960px) {
  .navigation-bar__group--account .wishlist .counter, .site-header__mobile-links .wishlist .counter {
    width: 21px;
    height: 21px;
    top: 4px;
    right: 15px;
  }
}

/* FWU-127 - Revert to dual button cart */
.minicart-bag-btn .viewcart {
  color: #FFF;
  text-transform: uppercase;
  background-color: #727272;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  padding: 11px;
  margin: 0 auto;
}

.minicart-actions {
  display: inline-block !important;
}

.navigation-bar-scrolled .minicart .minicart__details .block-content .cart.actions .action {
  padding: 12px !important;
}

.minicart-items-wrapper {
  max-height: 300px;
  overflow-y: auto;
  min-height: 135px;
}

/* Minicart slider  MMI-1540 */
@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes centerme {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}

@-webkit-keyframes centerme {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}

html.modalOpen {
  overflow: hidden; /* Hide scrollbars */
  #noty_top_layout_container {
    display: none !important;
  }
}

#minicart-visible-overlay, #minicartMobile-visible-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
}

#minicart-visible-overlay .close, #minicartMobile-visible-overlay .close {
  display: none !important;
}

.minicart:hover .minislider.hideCart {
  display: none !important;
  visibility: hidden !important;
}

.minicart__details.minislider.updatedCart {
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  display: block !important;
  visibility: visible !important;
  z-index: 11;

  .minicart-close {
    position: absolute;
    top: 40px;
    right: 26px;
    text-decoration: none;
    background-color: #000000;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: 1px solid #000000;
    cursor: pointer;
  }

  .minicart-product-added {
    text-align: left;
    color: green;
    padding: 10px 10px 10px 0;
    font-weight: 500;
    font-size: 14px;

    span {
      text-transform: capitalize;
      font-weight: 600;
    }
  }

  .minicart-product-added:before {
    content: "\f00c";
    font-size: 10px;
    font-family: "FontAwesome";
  }

  .cart-fusion-points-wrapper {
    font-weight: 500;
    font-size: 14px;
    text-align: left;
  }

  .fusion-point-count {
    font-weight: 600 !important;
  }

  .minicart-items-wrapper .product-item {
    border-bottom: 1px solid #e8e8e8;
    padding: 10px 10px 10px 0 !important;

    .product-item-photo {
      width: 30%;

      .product-image-wrapper {
        padding-bottom: 0% !important;
      }

      .product-image-photo {
        width: 100% !important;
      }
    }

    .product-item-details {
      width: 70%;

      .product-item-name a {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
      }

      .product.options {
        .values, input {
          text-align: left;
          margin-left: 0 !important;
          font-size: 12px;
        }
      }

      .details-qty.qty {
        .values, input {
          text-align: left;
          margin-left: 4px !important;
          font-size: 12px;
        }
      }

      .product.options .label, .details-qty.qty .label {
        text-transform: capitalize;
      }

      .product-item-pricing {
        color: #000000;
        letter-spacing: 0rem !important;
      }
    }

    .product.actions .action.delete:after {
      font-family: 'FontAwesome' !important;
      content: "\f068" !important;
      font-size: 10px;
    }
  }

  .subtotal {
    justify-content: space-between !important;
    border-top: 2px solid #e8e8e8 !important;
    border-bottom: 2px solid #e8e8e8;
    padding-bottom: 15px !important;
    margin: 0px !important;;

    .label, .price-container {
      font-size: 0.8rem !important;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .cart.actions {
    display: block !important;
    margin: 0px !important;

    .primary, .tertiary {
      padding: 0 !important;
      width: 100%;
    }

    .action {
      text-transform: capitalize !important;
      padding: 8px 16px !important;
      font-size: 14px !important;
    }

    #top-continue-btn-checkout {
      background-color: #FFFFFF !important;
      color: #000000 !important;
      border: 1px solid #DE0031 !important;
      margin-bottom: 10px;
      height: 32px;
    }
  }

  .items-shipping {
    justify-content: space-between !important;
    border-top: 2px solid #e8e8e8 !important;
    padding: 15px 0 !important;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: left;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 2;
    }

    .u-icomoon--delivery:after, .u-icomoon--cart:after {
      font-size: 25px;
      margin-right: 5px;
      line-height: 0;
    }

    .u-icomoon--return:after {
      font-size: 22px;
      margin-right: 5px;
      line-height: 0;
    }

    .nz-exclusion {
      font-size: 8px;
      text-align: right;
    }
  }
}

.navigation-bar-scrolled .minislider {
  .minicart-heading {
    padding: 10px 0 40px 0;
  }

  .cart.actions button {
    height: 36px !important;
    line-height: 10px !important;
  }
}

/* Desktop minicart */
.desktopSlider.updatedCart {
  width: 400px !important;
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;

  .minicart-items-wrapper {
    margin-bottom: 20px;
    max-height: 50vh !important;
  }

  .minicart-heading {
    text-align: left;
    padding: 20px 0;

    span {
      font-weight: 500;
      font-size: 30px;
      text-transform: capitalize;
      letter-spacing: 0.05rem;
    }
  }
}

/* Mobile minicart */
.mobileSlider.hideCart {
  width: 0% !important;
  height: 0% !important;
  display: none !important;
}

.mobileSlider.updatedCart {
  width: 100% !important;
  height: 80vh;
  animation: centerme 0.5s;
  -webkit-animation: centerme 0.5s;
  position: absolute;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  display: block !important;

  .minicart-heading {
    text-align: left;
    padding: 10px 0;

    span {
      font-weight: 500;
      font-size: 20px;
      text-transform: capitalize;
      letter-spacing: 0.05rem;
      line-height: 1;
    }
  }

  .minicart-close {
    top: 33px !important;
  }

  .minicart-items-wrapper {
    max-height: 32vh !important;
  }
}

.modal-popup.confirm .modal-content {
  padding-right: 25px;
}

/* Ends MMI-1540 */