/*
  These styles are for the credit card information fields on the checkout page.
  Due to the credit card payments being handled by Braintree, all of the fields are included via iframes,
  which means the actual <input> fields aren't accesible, so the styling will instead be directly on the <iframe>
  element, giving it the styling of the <input>'s in the rest of the site, essentially 'faking' an inline <input>

  The <iframes> have inline styles, so all styles here need to have !important
*/

#co-transparent-form-braintree {
  padding-top: 20px;

  /* iframe's */
  #braintree-hosted-field-number,
  #braintree-hosted-field-expirationMonth,
  #braintree-hosted-field-expirationYear,
  #braintree-hosted-field-expirationDate,
  #braintree-hosted-field-cvv {
    border: 1px solid #000 !important;
    height: 40px !important;
    padding-left: 10px !important;
    margin: 5px 0;
  }

  .hosted-date-wrap {
    font-size: 0;

    #braintree_expirationMonth {
      width: 50%;
      font-size: inherit;
      display: inline-block;

      #braintree-hosted-field-expirationMonth { /* iframe */
        width: 99% !important; /* Adds a small amount of whitespace between the two date fields */
      }
    }

    #braintree_expirationYear {
      width: 50%;
      font-size: inherit;
      display: inline-block;

      #braintree-hosted-field-expirationYear {
        width: 99% !important; /* Adds a small amount of whitespace between the two date fields */
        float: right !important;
      }
    }
  }
}

#co-transparent-form-braintree .hosted-error {
  display: none !important;
  color: #cc0000;
}

#co-transparent-form-braintree .braintree-hosted-fields-invalid + .hosted-error {
  display: block !important;
}

/* WDP-686 */
.payment-method-braintree .ccard.braintree .braintree-card-control > img {
    display: none;
}
