.quickview-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .quickview {
    padding: 2rem;

    position: relative;
    display: flex;
    flex-direction: row;
    width: 75vw;
    max-width: $max-width;
  }

  .quickview__images {
    position: static;
    width: 50%;
    display: flex;
  }

  .quickview__main-image {
    position: relative;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

  }

  .quickview__main-image:before {
    position: absolute;
  }

  .quickview__thumbnails {
    position: static;
  }

  .quickview__details {
    border-left: 1px solid #ddd;
    width: 50%;
  }

  .quickview__price {
    margin-top: 0.75rem;
  }

  .quickview__price .price-final_price .price {
    font-size: 18px;
  }

  .quickview__title {
    text-transform: uppercase;
    line-height: 1.2;
    margin-top: 0.5rem;
  }

    .quickview__brand {
      margin-top: 0;
      font-size: 14px;
      color: #797979;
      line-height: 20px;
    }
    .quickview__close-button:hover {
      background-color: transparent;
    }

  /**
   * Buttons
   */

   /**
    * Button: Add to Cart
    */
  .quickview__add-to-bag-container {

    .quickview__add-to-bag-button {
      font-size: 14px;
      padding-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .quickview__add-to-bag-button:before {
      @include icomoon(cart);
      font-size: 36px;
      margin-right: 1rem;
    }

  }

   /**
    * Button: Save to wishlist
    */
  .quickview__save-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  .quickview__save-container:before {
    content: inherit;
  }
  .quickview__save-button:hover,
  .quickview__save-button:focus {
    background: transparent;
  }
  .quickview__save-button:focus{
    background: transparent;
  }
  .quickview__save-button:before {
    @include icomoon(wishlist);
    font-size: 36px;
    color: $color-shop-accent;

    margin-top: 0;
    margin-right: 1rem;
  }
  .quickview__save-button{
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-top: 0;
    margin-top: 8px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

   /**
    * Button: PDP Link
    */
  .quickview__view-full-details-button {
    background-color: transparent;
    color: $color-black;
    border: 0;
  }

}

.product-tile__badge {
  width: 3rem;
  height: 3rem;
  display: flex;
  font-size: $product-badge__font-size;
  justify-content: center;
  align-items: center;
  text-transform: $product-badge__text-transform;
}

.product-tile__badge--circle {
  border-radius: 1000px;
}

.product-tile__badge--is-new {
  background: $product-badge__background--new;
  border: $product-badge__border--new;
  color: $product-badge__color--new;
}

.product-tile__badge--on-sale {
  background: $product-badge__background--sale;
  color: $product-badge__color--sale;
}

.quickview__thumbnail .quickview__thumbnail-image, .quickview__thumbnail {
  outline: 0;
}
