@import "./components.plp__app--small";
@import "./components.plp__sorting--small";
@import "./components.plp__product-list--small";
@import "./components.plp__product-filters--small";
@import "./components.plp__product-pagination--small";
@import "./components.plp__filter-control--small";
@import "./components.plp__filter-control-swatch--small";
@import "./components.plp__filter-control-active-filters--small";
@import "./components.plp__product-item--small";

@media (min-width: 960px) {
  @import "./components.plp__app--medium";
  @import "./components.plp__sorting--medium";
  @import "./components.plp__product-list--medium";
  @import "./components.plp__product-item--medium";
}
