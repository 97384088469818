.social__link-item {
  display: inline-block;
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
  border-radius: 50%;
  background-color: $color-shop-accent;
  color: #FFFFFF;
  font-size: 1.2rem;
  text-align: center;

  &:hover,
  &:focus {
    background-color: $color-shop-accent-hover;
  }

  .social__link {
    font-size: 1em;
  }
}
