.account-nav-content ul li.item-delimiter {
  display: none;
}

.account-nav-content ul li {
  border-color: #ccc;
}

.wishlist-index-index .product-list--wishlist .product-tile__action--add-to-cart {
  padding: 0.5625rem 1.5rem;

  &:focus,
  &:hover {
    color: $color-white !important;
    background-color: $color-shop-accent-hover;
  }
}

.wishlist-index-index .product-list--wishlist .my-account__action--back,
.wishlist-index-index .product-list--wishlist .product-tile__action--delete,
.wishlist-index-index .product-list--wishlist .product-tile__action--edit {
  font-size: 0.875rem;
  padding: 0.25rem 0.875rem;
  color: $color-black;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  background: none;

  &:focus,
  &:hover {
    background: none;
    color: $color-shop-accent;
    border-bottom: 0;
  }
}

.my-account .form__field .form__input, .my-account .form__field .form__input[type="text"],
.my-account .form__field select, .my-account #loyalty_card_number {
  outline: 0;
  border-radius: 10px;
  border-color: #ccc;
}

.my-account .my-account__action--back {
  background-color: #FFF;
  color: #DE0031;
  border-color: #DE0031;
}

.my-account .form__field label,
.my-account .form__field select label,
.my-account #loyalty_card_number label {
  height: initial !important;
  width: initial !important;
  clip: initial !important;
  margin: initial !important;
  position: relative !important;
  font-weight: bold;
  opacity: 1 !important;
  z-index: initial !important;
  padding: 0;
}

.form--create-account .form__field input, .form--create-account .form__field select,
.form--create-account .form__action {
  border-radius: 10px;
  outline: 0;
}

.form--create-account .form__field input, .form--create-account .form__field select {
  border-color: #ccc;
}

.form--create-account .form__field--text .form__label {
  position: relative;
  line-height: initial;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 0;
}

.order-table__action {
  border-radius: 10px;
}

$breakpoint: medium-and-up;
.my-account__section--fusion-rewards {
  @extend %u-1-of-2-#{$breakpoint};
}

.my-account__list--fusion-rewards {
  margin-bottom: $spacing-unit-base;
}

.my-account__action--update {
  @extend %button--small;
  @extend %form__action--left;
}

.my-account__action--manager-fusion {
  @extend %form__action--left;
  margin: 0.2em 0 0 1em;
  text-decoration: none;

  &:before {
    @include icon-font;
    font-size: 18px;
    content: '\e895';
    vertical-align: sub;
  }
}

.my-account__heading--section,
.my-account__group--dashboard-address .my-account__heading--header {
  text-transform: uppercase;
  font-size: 19px;
  margin-bottom: 1em;
}

.my-account__list-callout {
  padding: $spacing-unit-base;
  background-color: $color-grey;
  color: $color-darker-grey;
  border-radius: 10px;
}

.my-account__list-heading {
  font-size: 1.2rem;
  margin-bottom: 0;
  line-height: 1;
  color: $color-black;
}

.my-account__list-heading-icon {
  font-size: 1.7em;
  vertical-align: middle;
}

.my-account__action.my-account__action--link-account {
  background-color: $color-darker-grey;

  &:focus,
  &:hover {
    background-color: $color-shop-accent-hover;
  }
}

.my-account__action--link-icon {
  vertical-align: middle;
}

.customer-dob-wrapper .customer-dob input {
  border: 1px solid #CCC;
  outline: 0;
  border-radius: 10px;
}

.customer-dob-wrapper .ui-datepicker-trigger, .customer-dob-wrapper label {
  font-weight: bold;
}

@media (min-width: 960px) {
  .customer-dob-wrapper {
    margin-top: 0;
  }
}

.customer-address-form .clear-icon {
    top: 22px;
}

.customer-address-form #show-full-address-line {
    margin-top: 0;
}
