.site-footer__site-map {
  .site-map__links-column {
    li, a {
      color: $color-white;
    }

    a:hover,
    a:focus {
      color: $color-light-grey;
    }
  }
}

.site-footer__contact {
  br {
    display: none;
  }
}

.site-footer__site-map--fusion-retail-brand {
  flex-wrap: wrap;
}

@include media-query(medium-and-up) {
  .footer-sitemap-contact {
    .footer-sitemap-contact__inner {
      .site-footer__site-map {
        width: 75% !important; // overriding important set in base theme
      }

      .site-footer__contact {
        width: 25% !important; // overriding important set in base theme
      }
    }
  }

  .site-footer__contact {
    br {
      display: block;
    }
  }
}


