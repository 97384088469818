/*------------------------------------*\
    #SITE-FOOTER
\*------------------------------------*/

/*footer*/
.site-footer {
    @extend %box;
    @extend %box--small;
}


/*div*/
.site-footer__wrapper { }


/*ul*/
.site-footer__links {
    @extend %list-inline;

    text-align: center;
}


/*a*/
.site-footer__link {
    display: inline-block;
    padding: 0.5em;
}


/*small*/
.site-footer__copyright {
    display: inline-block;
    text-align: center;
    width: 100%;
}