.accordion {
  margin-top: 30px;
}
  
  .accordion__panel {
    padding: 0 10px;
  }
    .accordion--enabled .accordion__panel {
      border-top: 1px solid #000;
    }
    .accordion--enabled .accordion__panel:last-child {
      border-bottom: 1px solid #000;
    }

      .accordion__header {
        @include functional-text-black;
        cursor: disabled;
        color: #ccc;
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        align-items: center;
      }
    
      .accordion--enabled .accordion__header {
        cursor: pointer;
        color: black;
      }
        .accordion__heading {
          padding: 8px 0 8px 0px;
          line-height: 1.2;
          margin: 0;
        }
        .accordion--enabled .accordion__header:after {
          @include icon-font;
          font-size: 18px;
          content: '\e145';

          display: flex;
          margin-left: auto;
        }

          .accordion--enabled .accordion__panel--open .accordion__header:after {
            content: '\e15b';
          }

    .accordion__content {
      padding: 0;
      margin-bottom: $layout-gutter;
    }