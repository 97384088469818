.category-header {
  @include media-query(small-and-up) {
    padding: 0 0 15px 0;
  }
  font-size: 0;
  &.min-height-required {
    min-height: 385px;
  }

  & > div {
    font-size: medium; /* IE Fix */
    font-size: initial;
  }

  .category-header__title {
    @include heading1;
    padding: 0;
    text-transform: capitalize;
  }

  .category-description {
    text-align: center;
    p {
      @include paragraph-text;
      display: inline-block;
      max-width: 380px;
      padding: 0 10px;
    }
  }

  .category-header__text-container {
    display: inline-block;
    height: 100%;
    text-align: center;

    & > div {
      display: table;
      width: 100%;
      height: 100%;

      & > div {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        height: 100%;
      }
    }
  }

  .category-image {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    width: 50%;
  }

  .half-width {
    @extend %u-1-of-2-medium-and-up;
    width: 100%;

    & + .category-image {
      @extend %u-1-of-2-medium-and-up;
    }
  }

  .full-width {
    width: 100%;
  }

  .category-desc-only p {
    max-width: none;
  }
}
