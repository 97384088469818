
$icomoon-icons__arrow-down: '\e900';
$icomoon-icons__arrow-left: '\e901';
$icomoon-icons__arrow-right: '\e902';
$icomoon-icons__arrow-up: '\e903';
$icomoon-icons__call: '\e904';
$icomoon-icons__cart: '\e905';
$icomoon-icons__delivery: '\e906';
$icomoon-icons__find-store: '\e907';
$icomoon-icons__return: '\e908';
$icomoon-icons__rewards: '\e909';
$icomoon-icons__search: '\e90a';
$icomoon-icons__user: '\e90b';
$icomoon-icons__wishlist: '\e90c';
$icomoon-icons__credit-card: '\e910';
$icomoon-icons__trash: '\e90d';
$icomoon-icons__dollars: '\e90e';
$icomoon-icons__padlock: '\e90f';
$icomoon-icons__details: '\e911';
$icomoon-icons__info: '\e910a';

$icomoon-icons: (
    arrow-down: $icomoon-icons__arrow-down,
    arrow-left: $icomoon-icons__arrow-left,
    arrow-right: $icomoon-icons__arrow-right,
    arrow-up: $icomoon-icons__arrow-up,
    call: $icomoon-icons__call,
    cart: $icomoon-icons__cart,
    delivery: $icomoon-icons__delivery,
    find-store: $icomoon-icons__find-store,
    return: $icomoon-icons__return,
    rewards: $icomoon-icons__rewards,
    search: $icomoon-icons__search,
    user: $icomoon-icons__user,
    wishlist: $icomoon-icons__wishlist,
    credit-card: $icomoon-icons__credit-card,
    trash: $icomoon-icons__trash,
    dollars: $icomoon-icons__dollars,
    padlock: $icomoon-icons__padlock,
    details: $icomoon-icons__details,
    info: $icomoon-icons__info
);

:export {
    arrow-down: $icomoon-icons__arrow-down;
    arrow-left: $icomoon-icons__arrow-left;
    arrow-right: $icomoon-icons__arrow-right;
    arrow-up: $icomoon-icons__arrow-up;
    call: $icomoon-icons__call;
    cart: $icomoon-icons__cart;
    delivery: $icomoon-icons__delivery;
    find-store: $icomoon-icons__find-store;
    return: $icomoon-icons__return;
    rewards: $icomoon-icons__rewards;
    search: $icomoon-icons__search;
    user: $icomoon-icons__user;
    wishlist: $icomoon-icons__wishlist;
    credit-card: $icomoon-icons__credit-card;
    trash: $icomoon-icons__trash;
    dollars: $icomoon-icons__dollars;
    padlock: $icomoon-icons__padlock;
    details: $icomoon-icons__details;
    info: $icomoon-icons__info;
}