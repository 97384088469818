/* Styling for the PLP instance of the recently viewed widget */
.page-layout__item--tertiary > .block-viewed-products-list {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;

  .block-title {
    margin-bottom: 30px;
    font-size: 22px;
    strong {
      font-weight: 400;
    }
  }

  //Reset the ol list
  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    .product-item + .product-item {
      margin-top: 50px;
    }
  }

  .product-image-photo {
    width: 195px;
    height: 200px;
  }

  .product-item-details {
    margin: 0 auto;
    @include media-query(medium-and-up) {
      margin: 16px 0 0 0;
    }

    .product-item-name {
      display: block;
      text-align: left;

      a {
        @include small-text-black;
        text-decoration: none;
      }
    }

    .price-box {
      display: inline-block;
      text-align: left;

      .price {
        @include small-text-black;
      }

      & + .product-item-actions {
        display: block;
      }
    }
  }

  /* Hide the Add to Cart button */
  button.action.tocart.primary {
    display: none;
  }
}

/* Styling for the PDP instance of the recently viewed widget */
.recently-viewed-pdp-container {
  background-color: #F7F7F7;

  & > .block-viewed-products-list {
    @extend %wrapper;
    @include clear('both'); /*Need to clear the floats applied to .product-info__main and .product-info__media*/
    height: 405px;
    margin-top: 27px;

    .block-title {
      @include heading1;
      text-align: center;
      padding-top: 36px;
      padding-bottom: 17px;
    }

    .product-items {
      margin-left: 0;
      display: flex;
      justify-content: center;
      list-style: none;
    }

    .product-item {
      width: calc(100% / 6);
    }

    .product-item-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
    }

    .product-item-name {
      @include small-text-black;
      a {
        text-decoration: none;
      }
    }

    .product-item-actions,
    .product-item-description {
      display: none;
    }

    .product-item-info {
      text-align: center;
    }
  }
}

@media (max-width: 960px){
  .page-layout--two-col .block-viewed-products-list {
    display: none !important;
  }
}
