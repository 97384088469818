/**
 * Header Tabs
 */
.tab-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin: 0;
  padding: 0;
}
