@mixin widget-button {
  @include button-text;
  display: inline-block;
  background-color: $color-shop-accent;
  padding: 6px;
  min-width: 156px;
  text-align: center;
  text-decoration: none;
  margin-bottom: 0; /* Needed to overwrite a base style that's applied site-wide */
  border: $widget-button-border;
  @include widgetColours; /* Apply any user-specified colours */
  color: $color-black;
}
