.paypal-info__wrap {
  text-align: center;
}

.paypal-info__header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.paypal-info__logo img {
  width: 210px;
  height: auto;
}

.checkout-app-tab-section.payment .paypal-info__wrap .button--link {
  text-transform: uppercase;
}