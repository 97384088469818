/*------------------------------------*\
    #FONTAWESOME
\*------------------------------------*/

@font-face {
    font-family: "FontAwesome";
    src: url("#{$path-fonts}/fontawesome-webfont.eot");
    src: url("#{$path-fonts}/fontawesome-webfont.eot?#iefix") format("embedded-opentype"),
    url("#{$path-fonts}/fontawesome-webfont.woff2") format("woff2"),
    url("#{$path-fonts}/fontawesome-webfont.woff") format("woff"),
    url("#{$path-fonts}/fontawesome-webfont.ttf") format("truetype"),
    url("#{$path-fonts}/fontawesome-webfont.svg#fontawesomeregular") format("svg");
    font-weight: normal;
    font-style: normal;
}
