/*------------------------------------*\
    #DEFINITION-LIST
\*------------------------------------*/

dl {
    @extend %clearfix;
}


dt, dd {
    float: left;
}


dt {
    clear: both;
}


dd {
    margin-left: 0.2em;
}