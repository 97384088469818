/*------------------------------------*\
    #MAGENTO
\*------------------------------------*/

/**
 * Ensure compatibility with Magento default styles
 **/

.swatch-option {
    box-sizing: content-box;
}