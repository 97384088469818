@media (min-width: 960px) and (max-width: 1300px) {
  .notify-me-popup .popup_content {
    width: 60% !important;
  }
}

.product-notify-me-block-wrapper,
.product-notify-me-thanks-wrapper {
  padding: 30px;
}

.product-notify-me-wrapper .product-notify-me-btn {
  padding: 10px;
  font-size: 14px;
  text-align: center;
  border: 1px solid $color-shop-accent;
  margin-top: 15px;
  background-color: #F6F6F6;
  cursor: pointer;
}

.product-notify-me-block-wrapper .product-notify-me-heading,
.product-notify-me-thanks-wrapper .product-notify-me-heading {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  margin-bottom: 0;
}

.product-notify-me-block-wrapper .product-notify-me-form {
  margin-top: 1.5rem;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-submit,
.product-notify-me-thanks-wrapper .product-notify-me-continue-button {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #FFF;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.product-notify-me-block-wrapper .product-notify-me-item-instock,
.product-notify-me-thanks-wrapper {
  display: none;
}

.product-notify-me-block-wrapper .swatch-attribute-options {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.product-notify-me-block-wrapper .product-notify-me-size-note {
  text-align: center;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
  margin-bottom: 20px;
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email::placeholder {
  color: #333;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-note {
  margin-bottom: 0;
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
}

.product-notify-me-block-wrapper .product-notify-me-errors {
  color: red;
  margin-top: 15px;
  display: none;
  text-align: center;
  cursor: pointer;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-info {
  font-weight: bold;
  text-align: center;
  margin: 20px;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-button {
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 22px;
  background-color: $color-shop-accent;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.product-notify-me-thanks-wrapper .product-notify-me-size-note {
  text-align: center;
}

.product-notify-me-block-wrapper .swatch-attribute.filter-size .swatch-option.text:last-child {
  margin-right: 0;
}

.product-notify-me-block-wrapper .swatch-attribute-options:after {
  display: none;
}

@media (max-width: 400px) {
  .product-notify-me-block-wrapper,
  .product-notify-me-thanks-wrapper {
    padding: 30px 0;
  }
  .product-notify-me-block-wrapper .product-notify-me-heading,
  .product-notify-me-thanks-wrapper .product-notify-me-heading {
    font-size: 22px;
  }
}