.react-checkout-app__messages {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.react-checkout-app__message {
    position: relative;
    padding: 1rem;
    color: #383d41;
    background-color: #e2e3e5;
    border: 1px solid #d6d8db;
    margin-bottom: 0.5rem;
}

.react-checkout-app__message--error {
    color: #DE0031;
    font-weight: bold;
}

.react-checkout-app__messages--button {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: rgba(10,10,10,.2);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
    outline: 0;
    position: relative;
    vertical-align: top;
    width: 20px;
    position: absolute;
    right: 0.5rem;
    top: 1.1rem;

    &::before,
    &::after {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        transform-origin: center center;
        background-color: #fff;
        content: "";
    }

    &::before {
        height: 2px;
        width: 50%;
    }

    &::after {
        height: 50%;
        width: 2px;
    }
}
