#js-aligent_fredhopper-autocomplete-wrapper {
  width: auto;
  max-width: 622px;
  padding: 0 40px;
  display: block;
  flex: 1 1 auto;

  form {
    position: relative;
  }

  #js-aligent_fredhopper-autocomplete-search {
    border: 1px solid $color-border-outline;
    padding: 14px 120px 14px 20px;
    height: auto;
    background-color: $color-background;
    font-size: 14px;
    font-weight: 300;

    @include placeholder {
      text-transform: uppercase;
      color: $color-input-placeholder;
      font-size: inherit;
    }

    &:focus {
      outline: none;
      border-color: #C2C2C2;
    }
  }

  form {
    display: flex;
  }

  .minisearch__control--submit {
    background-color: $color-shop-accent;
    color: $color-white;
    text-indent: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 5px 35px;
    font-size: 30px;
    cursor: pointer;

    span {
      @extend %u-visually-hidden;
    }

    &:after {
      @include icomoon('search');
    }

    &:hover {
      background-color: $color-shop-accent-hover;
    }
  }

}

/* MMI-371 */
@media (max-width: 960px) {
  .site-header__banner .siteHeaderBannerInnerContent > * {
    display: none;
  }
  #js-aligent_fredhopper-autocomplete-results {
    top: -10px;
  }
}