$header-sub-strip-spacing: 15px;
$header-sub-strip-spacing-mobile: 8px;

.header-sub-strip {
  background-color: $color-grey;

  @media (max-width: $breakpoint2 - 1px) {
    display: none;
  }

}

.header-sub-strip__inner {
  padding-left: .75rem;
  padding-right: .75rem;
}

.header-sub-strip__list{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  @media (min-width: $breakpoint4) {
    margin-left: -#{$header-sub-strip-spacing};
    margin-right: -#{$header-sub-strip-spacing};
  }

  .header-sub-strip-item,
  li { // using element selector since this is coming from cms block
    padding-left: $header-sub-strip-spacing-mobile;
    padding-right: $header-sub-strip-spacing-mobile;
    flex: 1 1 calc(100% / 3);
    position: relative;

    @media (min-width: $breakpoint4) {
      padding-left: $header-sub-strip-spacing;
      padding-right: $header-sub-strip-spacing;
    }

    a {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.563rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 0;
      line-height: 0.9;
      text-align: center;

      &:before,
      i {
        font-size: 19px;
        margin-right: 4px;
        color: $color-shop-accent;

        @media (max-width: $breakpoint5 -1px) {
          margin-top: -2px;
        }

        @media (min-width: $breakpoint5) {
          font-size: 26px;
          margin-right: 7px;
          line-height: 1.2;
        }

        @media (min-width: $breakpoint4) {
          font-size: 30px;
          margin-right: 10px;
          line-height: 1.2;
        }

        @media (min-width: $breakpoint2) {
          font-size: 44px;
          margin-right: 15px;
        }
      }

      &:hover {
        color: $color-black;
      }

      @media (min-width: $breakpoint5) {
        font-size: 0.688rem;
        font-weight: 700;
      }

      @media (min-width: $breakpoint4) {
        font-size: 0.75rem;
      }

      @media (min-width: $breakpoint2) {
        letter-spacing: $heading-letter-spacing;
        font-size: 0.875rem;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      span {
        display: block;
      }

      small {
        display: block;
        font-size: 12px;
        margin-top: 2px;

        @media (max-width: 960px) {
          font-size: 10px;
        }

        @media (max-width: 450px) {
          font-size: 9px;
        }
      }
    }

    &:after {
      content: " ";
      width: 1px;
      position: absolute;
      top: 10px;
      bottom: 10px;
      right: 0;
      background-color: #FFF;
    }
    &:last-child:after {
      content: none;
    }
  }
}
