@use "sass:math";

.one-page-checkout__steps,
.one-page-checkout__summary {
  width: 100% !important;

  /* This media query is essentially the trump part, over-writing what the original rule applies */
  @include media-query(small-and-up) {
    float: none;
    padding-left: math.div($spacing-unit-base, 2);
  }

  /* This is the replacements for the above over-write */
  @include media-query(medium-and-up) {
    float: right;
    padding-left: math.div($spacing-unit-base, 2);
  }

  @include media-query(large-and-up) {
    width: 50% !important;
  }
}
