.react-plp-app {

  .react-plp-app-product-list__list {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
  }

  .react-plp-app-product-list__list-item {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

}
