.account-section {
  position: relative;
  margin-bottom: 1rem;

  .react-checkout-loading__block {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .account__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 1rem 0;

    @media (min-width: $breakpoint5) {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }

  .checkout-account-info {
    font-size: 0.9rem;
    margin: 0.5rem;
    color: $color-darker-grey;
  }

  .account__actions__action {
    border-radius: 5px;
    margin: 0.5rem;

    @media (min-width: $breakpoint5) {
      margin: 0.5rem 0;
    }
  }

}

.account--logged-in {
  position: relative;
  text-align: center;

  .react-checkout-loading__block {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .account__welcome-back {
    margin: 0;
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
