.checkout-app-tab-section {
  display: none;
  flex-direction: column;
  
  &--is-active {
    display: flex;
  }
}

.checkout-app-tab-section__content {

  flex-direction: column;
  flex-wrap: wrap;
  padding: 0.5rem;
  background-color: white;

  &--is-active {
    display: flex;
  }
}

.checkout-app-tab-section__footer {
  display: flex;
  padding: 1rem;
}

.checkout-app-tab-section__action-continue {
  padding: 1rem;
  background: silver;
}

/**
 * Specific Tab Sections
 */

.checkout-app__shoppingbag-section .checkout-app-tab-section__content {
  flex-direction: column;
}
