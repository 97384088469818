.filter__title {
  @include large-text;
  text-transform: capitalize;
}
h1.filter__title {
  display: none;
  @include media-query(medium-and-up) {
    display: block;
  }
}

/* Style the applied filters section */
.filter__current {

  .filter__current-title {
    @include functional-text-black;
    position: relative;
    padding: 5px;
    margin: 0;

    &:after {
      @include icon-font;
      display: inline-block;
      position: absolute;
      right: 5px;
      top: -9px;
      content: "-";
    }
  }

  ol {
    word-spacing: $filterGutterSize;
    margin-top: -$filterGutterSize;
    display: inline-block;

    li {
      word-spacing: inherit; /* IE Fix */
      word-spacing: initial;
      @include functional-text-grey;
      display: inline-block;
      padding-left: 8px;
      margin-top: $filterGutterSize;
      border: 1px solid #999999;

      @include media-query(medium-and-up) {
        @include functional-text-black;
        border: none;
        background-color: $color-header-background;
        color: $color-top-info-bar-text;
      }
    }
  }

  .filter__clear {
    @include functional-text-grey;
    display: inline-block;
    margin-top: $filterGutterSize;
    text-decoration: none;
    position: relative;
    padding-left: 8px;
    border: 1px solid #999999;
    padding-right: 23px;

    @include media-query(medium-and-up) {
      @include functional-text-black;
      border: none;
    }

    &:after {
      position: absolute;
      display: inline-block;
      text-transform: lowercase;
      content: "\00d7";
      margin-left: 8px;
      top: 0;
    }
  }
}

.filter {
  @extend %collapse;
  @include media-query(medium-and-up) {
    height: inherit; /* IE Fix */
    height: initial;
    overflow: inherit; /* IE Fix */
    overflow: initial;
    visibility: inherit; /* IE Fix */
    visibility: initial;
  }

  .applied-filters-title {
    display: none;

    @include media-query(medium-and-up) {
      display: block;
    }
  }

  .filter__category { /* dl */
    border-bottom: 1px solid #CFCFCF;
    /* Hide the individual clear buttons for applied filters */
    /* Classes begin with 'filter', end with 'clear' and have the property name in between. E.g. .filter-gender-clear */
    span[class^='filter-'][class$='-clear'] {
      display: none;
    }

    label {
      display: inline-block;
    }

    .filter__title { /* dt */
      @include functional-text-black;
      cursor: pointer;
      position: relative;
      padding: 5px 20px;
      border-top: 1px solid #CFCFCF;

      @include media-query(medium-and-up) {
        border-top: 1px solid #000000;
        padding: 5px;
      }

      &:after {
        @include icon-font;
        display: block;
        position: absolute;
        right: 5px;
        top: -3px;
        content: "+";
      }

      &._open:after {
        content: "-";
      }
    }

    dd {
      @extend %collapse;
      /*This height should 'cut-off' the bottom half of a row of filters, indicating to the user that they can scroll down*/
      max-height: 110px;
      overflow-y: auto;
      @include media-query(medium-and-up) {
        max-height: 134px;
      }

      &._open {
        padding: 0 20px 15px 20px;
        @include media-query(medium-and-up) {
          padding: 0 5px 15px 5px;
        }
      }

      .item {
        display: inline-block;
        width: 50%;
        @include media-query(medium-and-up) {
          display: block;
          width: 100%;
        }
      }
    }

    /* Start of styling for the swatches */
    .swatch-attribute-options {
      word-spacing: 8px;
    }

    input[type='checkbox'][name='size']:checked + label {
      .swatch-option {
        border-width: 2px;
      }
    }

    .swatch-option-link-layered {
      display: inline-block;
    }

    .swatch-option {
      font-weight: 100;
      box-sizing: border-box;
      width: $swatch-size;
      height: $swatch-size;
      background-color: rgb(255, 255, 255);
      border: none;
      margin: 0;
      padding: 0;

      &.text {
        @include small-text-black;
        border: 1px solid #000000;
        font-size: 17px;
        line-height: $swatch-size - 5px;
      }
    }

    .swatch-attribute.size .swatch-option{
      background-color:#fff;
    }

    /* End of styling for the swatches */

    /* Start of styling for the checkboxes */
    input[type='checkbox'] {
      @extend %u-visually-hidden;
    }

    ol input + label {
      @include small-text-grey;
      @include media-query(medium-and-up) {
        @include small-text-black;
      }
      line-height: 16px;

      .count {
        &:before {
          content: '(';
        }
        &:after {
          content: ')';
        }
      }

      &:before {
        font-weight: 100;
        display: inline-block;
        width: 12px;
        height: 12px;
        text-align: center;
        line-height: 9px;
        font-size: 20px;
        vertical-align: top;
        content: '';
        margin-right: 5px;
        margin-top: 2px;
        border: 1px solid #666666;
        @include media-query(medium-and-up) {
          border: 1px solid #000000;
          width: 16px;
          height: 16px;
          margin-top: 0;
          font-size: 26px;
          line-height: 15px;
        }
      }

      .count {
        display: none;
        @include media-query(medium-and-up) {
          display: inline;
        }
      }
    }

    input[type='checkbox']:not([name='size']):not([name="primary_colour"]):checked + label {
      &:before {
        content: "\00d7";
      }
    }
    /* End of styling for the checkboxes */

    /* Start of styling for the price slider */
    .filter-price {
      margin-top: 8px;

      .filter-slider {
        height: 20px;
        border: 1px solid #000000;
        border-radius: 0;
        background-color: #FFFFFF;
      }

      .ui-slider-range {
        position: absolute;
        height: 100%;
        background-color: $color-header-background;
        color: $color-top-info-bar-text;
      }

      .ui-slider-handle {
        height: 35px;
        margin-top: -8px;
        border-radius: 0;
        background-color: $color-shop-accent;
      }
    }
    /* End of styling for the price slider */
  }
}
.mobile-refine-controls {
  background-color: #FFFFFF;
  width: 100%;
  font-size: 0;
  padding: 10px 20px;
  @include media-query(medium-and-up) {
    display: none;
  }

  &.fixed {
    position: fixed;
    top: 60px;
    z-index: 1000;
  }
}
.refine-filters__apply-container,
.refine-filters__cancel-container {
  display: inline-block;
  height: 27px;
}
.refine-filters__apply-container {
  width: 60%;
}
.refine-filters__cancel-container {
  width: 40%;
  padding-left: 20px;
}
.refine-filters-button {
  @include black-white-button;
  width: 100%;
  height: 100%;
  font-size: medium; /* IE Fix */
  font-size: initial;
  position: relative;
  padding-right: 14px;
  outline: none;
  background-color: #FFFFFF;

  &:after {
    @include icon-font;
    font-size: 20px;
    position: absolute;
  }
}
.refine-filters__apply:after {
  content: '\e5ca';
  margin-left: 10px;
}
.refine-filters__cancel:after {
  content: "\e14c";
  margin-left: 1px;
}

// Overwrite the padding space being added to element
aside.filter {
  padding-top: 0 !important;
}


/* Sort By Options Fixes */
@media (max-width: 959px) {
  .catalog-category-view .toolbar {
    padding: 0 10px;
  }
  .toolbar__sorter {
    width: 65%;
  }
  .toggle-refine-filters-container {
    width: 35%;
  }
  .toggle-refine-filters-container .toggle-refine-filters, .toolbar__sorter .sorter-container select {
    font-size: 14px;
  }
  .toolbar__sorter .sorter-container select {
    padding: 0 27px 0 13px;
  }
}

/* 1586 - UI Slider Move to Theme */
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider-horizontal .ui-slider-handle {
  margin-left: -5px;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  cursor: default;
}
.ui-slider-horizontal {
  height: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  background: #dadada;
}
.ui-slider-handle {
  height: 10px;
  width: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: $color-shop-accent;
  display: block;
  position: absolute;
}

/* MMI-987 */
.filter .filter__category label {
  text-transform: capitalize;
}

.filter .filter__category .filter__title, .filter .filter__category dd._open{
  width: 100%;
}