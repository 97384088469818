/*
  Styling for the site notifications created by the Noty javascript plugin
*/
#noty_top_layout_container {
  padding-top: 10px !important; // !important required to override inline style

  li {
    border: none !important; // !important required to override inline style
    border-radius: 3px !important; // !important required to override inline style
    margin-bottom: 5px;
    background: none !important; // !important required to override inline style
  }

  .noty_bar {
    .noty_message {
      .noty_text {
        font-size: 19px;
      }
    }
  }

  .noty_type_success {
    background-color: #A5E4A9;
    color: #fff;
  }

  .noty_type_error {
    background-color: #E65454;
    color: #fff;

    .noty_text {
      font-weight: normal;
    }
  }

  .noty_type_alert {
    background-color: #fff;
    border: 1px solid #000;
  }
}
