/*------------------------------------*\
    # BLANK FONTS
\*------------------------------------*/

/**
 * Use mixins so fonts are consistently defined across the site
 **/
$font-weight: 400;
$tag-font-size: 0.9em;
$description-font-size: 0.8em;
$button-text: #fff;
$tag-text-colour: #fff;

@mixin font-base {
  font-family: $font-family-base;
}

/* Widget title text */
@mixin font-widget-title {
  text-transform: uppercase;
}

/* Slightly larger than small text. E.g. For tags*/
@mixin font-tag {
  text-transform: uppercase;
  color: $tag-text-colour;
  font-size: $tag-font-size;
}

/* Normal sized font. Used for descriptions and general body text */
@mixin font-regular {
  font-size: $description-font-size;
}

/* Text used on input buttons */
@mixin button-text {
  @include functional-text-white;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}

@mixin button-text-black {
  @include button-text;
  @include functional-text-black;
}

@mixin uppercase {
  text-transform: uppercase;
}

@mixin font-awesome($content: false, $position: 'before') {
  display: inline-block;
  font-family: FontAwesome;
  font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align: middle;

  @if $content {
    &:#{$position} {
      content: $content;
    }
  }
}

@mixin functional-font-base {
  font-family: $font-family-base-functional;
}

@mixin font-small {
  font-size: rems(14px);
}

@mixin paragraph-text {
  @include font-base;
  color: #666666;
  font-size: 16px;
}

@mixin large-text {
  @include functional-font-base;
  color: #000000;
  font-size: 24px;
  font-weight: 400;
}

@mixin functional-text($color) {
  @include functional-font-base;
  color: $color;
  font-size: 16px;
  text-transform: uppercase;
}

@mixin functional-text-black {
  @include functional-font-base;
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
}

@mixin functional-text-small {
  font-weight: 400;
}

@mixin functional-text-white {
  @include functional-font-base;
  color: #FFFFFF;
  font-size: 16px;
  text-transform: uppercase;
}

@mixin functional-text-small-white {
  @include functional-font-base;
  color: #ffffff;
  font-size: 16px;
}

@mixin functional-text-small-grey {
  @include functional-font-base;
  color: #666666;
  font-size: 16px;
}

@mixin small-text($color) {
  @include font-small;
  @include functional-font-base;
  color: $color;
  text-transform: uppercase;
}

@mixin small-text-black {
  @include font-small;
  @include functional-font-base;
  color: #000000;
  text-transform: uppercase;
}

@mixin small-text-grey {
  @include font-small;
  @include functional-font-base;
  color: #666666;
  text-transform: uppercase;
}

@mixin small-text-white {
  @include functional-font-base;
  color: #FFFFFF;
  font-size: 14px;
  text-transform: uppercase;
}

@mixin functional-text-grey {
  @include functional-font-base;
  color: #666666;
  font-size: 16px;
  text-transform: uppercase;
}

@mixin icons {
  color: #231F20;
  font-size: 26px;
}

@mixin heading1 {
  @include functional-font-base;
  font-size: 36px;
  color: #000000;
  font-weight: 400;
}

/* icon-font will now accept a variable to override the font used.
The default is $font-family-icons which is typically "Material Icons" */
@mixin icon-font( $font-family: $font-family-icons ) {
  font-family: $font-family;
  font-size: 26px;
  line-height: 1em;
}

@mixin font-action($color: null) {
  @include font-small;
  @include functional-font-base;
  @if ($color != null) {
    color: $color;
  }
  text-decoration: underline;
  text-transform: uppercase;
}

/**
 * The product title as displayed on the PDP and in quickview
 */
@mixin product-title {
  @include functional-font-base;
  font-size: 26px;
  font-weight: 500;
}
