.mobile-navigation-container {
  .header-strip-item--account {
    padding: 0;
        .show-user-navigation.is_active {
            &:after {
                @include icon-font;
                font-size: 22px;
                content: '\e145';
                position: absolute;
                right: 26px;
                top:15px;
            }

            &.active {
                &:after {
                    content: '\e15b';
                }
            }
        }
    .show-user-navigation,
    .user-nav__link {
      position: relative;
      line-height: 52px;
      height: 52px;
      text-decoration: none;
      width: 100%;
      display: block;
      padding-left: 20px;
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
    }

    .user-nav {
      list-style: none;
      margin: 0;

      .user-nav__link {
        display: block;
        padding-left: 40px;
        font-weight: 400;
        color: #666;
        border-top: 1px solid $color-menu-items-border;
        background: $color-background;
      }
    }

      a:first-child:before {
          content: "\f007" !important;
      }
      a:before {
          content: "" !important;
      }
  }
}
