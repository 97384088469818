.react-plp-product-filter {
  display: flex;
  flex-direction: column;
}

.react-plp-product-filter__aspect {
  width: 100%;
}

.react-plp-product-filter__button {
  border-bottom: 1px solid transparent;
}

.react-plp-product-filter__button:hover,
.react-plp-product-filter__button:focus {
  background: transparent;
  border-bottom-color: #000000;
}
