
.cms-index-index .main-content {
  p {
    margin:0; // remove margins on the p tags added in the wysiwyg in magento
  }

  .wrapper--gutter {
    max-width: none; // homepage is full screen
    padding:15px 0 0;
  }

}

.homepage-widget {
  padding: 0 15px;
  overflow: hidden;
}

.homepage-widget + .homepage-widget {
  padding-top: 15px;
}
