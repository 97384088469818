@mixin address-form {
  .form__hint:not(.form__hint--optional) {
    margin-top: -5px;
    margin-bottom: 10px;
  }
  .form__label {
    position: inherit;
    display: inline-block;
    padding: 0;

    .form__hint {
      display: inline-block;
      &:before {
        content: '(';
        display: inline-block;
      }
      &:after {
        content: ')';
        display: inline-block;
      }
    }
  }

  .form__error,
  .form__warning {
    display: block;
    margin-top: -8px;
  }

  .form__hint,
  .form__warning {
    @include small-text-black;
  }

  .postcode-autosuggest {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #FFF;
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 2px 0 0 0;
    border: 2px solid #000;

    .postcode-suggestion {
        padding: 7px 15px;
        width: 100%;

      &:hover {
        background-color: $address-validation-hover;
        color: #000;
        cursor: pointer;
      }
    }
  }
}

/* MMI-1720 - Postcode Suggest Dropdown START */
#co-shipping-form .postcode-autosuggest {
    max-height: 195px;
}
.onestepcheckout-index-index #co-shipping-form .postcode-autosuggest {
    max-height: 180px;
}

/* MMI-1729 - Postcode Billing Suggest Dropdown START */

#co-payment-form .billing-postcode-autosuggest,
#co-billing-form .billing-postcode-autosuggest {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 2px 0 0;
    border: 2px solid #000;
    overflow-y: scroll;
    max-height: 195px;
}

.onestepcheckout-index-index #co-payment-form .billing-postcode-autosuggest,
.onestepcheckout-index-index #co-billing-form .billing-postcode-autosuggest {
  max-height: 180px;
}

#co-payment-form .billing-postcode-autosuggest .billing-postcode-suggestion,
#co-billing-form .billing-postcode-autosuggest .billing-postcode-suggestion {
    cursor: pointer;
    padding: 7px 15px;
    width: 100%;
}

#co-payment-form .billing-postcode-autosuggest .billing-postcode-suggestion:hover,
#co-billing-form .billing-postcode-autosuggest .billing-postcode-suggestion:hover{
    background-color: $address-validation-hover;
    color: #000;
    cursor: pointer;
}

#co-billing-form .billing-postcode-autosuggest {
  overflow-y: scroll;
  width: 95%;
  margin-left: 10px !important;
}