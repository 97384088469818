.fotorama__arr__arr { /* Hide the prev/next arrows */
  display: none;
}
.product-info__main {
  position: relative;

  .product-title__brand {
    @include product-title;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .badge-container {
    position: absolute;
    right: 0;
    top: 10px;
    .badge {
      @include badge;
      @include sale-badge;
    }
  }

  /* Allow the message to be displaying between the sizes and buttons without moving any elements */
  .product__stock-message {
    min-height: 46px;
    margin-top: -26px;
  }

  .related-products {
    padding-top: 20px;
  }

  .product-info__add-to-cart {
    padding-top: 20px;

    .swatch-attribute-options .swatch-option {
      background-color: #fff;
    }
  }

  .swatch-opt .size-switcher {
    vertical-align: middle
  }
  .product__sizing-size-guide--link {
    display: inline-block;
    cursor: pointer;
  }

  .afterpay-installments-amount {
    padding-bottom: 20px;
  }
}

.product-info__details {
  .product.data.items {
    .title {
      display: none;
    }
  }
}

.additional-attributes-wrapper {
  padding-top: 20px;

  .fit-notes {
    font-weight: 600;
    margin-top: 15px;
  }
}

#product-attribute-specs-table {
  width: 100%;

  tbody {
    tr {
      th {
        text-align: left;
        white-space: nowrap; //Ensure the width of the column means no text in the first column wraps onto next line
        padding-right: 10px;
        @include media-query(medium-and-up) {
          width: 35%;
        }
      }
    }
  }

  caption {
    display: none;
  }
}

/* Also Available In Colours Section */
.related-products__list .owl-wrapper-outer {
  padding:5px 0;
}
.related-products__list .owl-item {
  width: 55px !important;
  margin: 0 34px;
}
.related-products__list .owl-item .product-image-container {
  //width: 120px !important;
  -webkit-transition: box-shadow 0.5s ease-in-out;
  -moz-transition: box-shadow 0.5s ease-in-out;
  -o-transition: box-shadow 0.5s ease-in-out;
}
.related-products__list .owl-item .product-image-container:hover {
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

/* Quick View Overlay Fix */
.quickview-modal {
    position: relative;
    display: flex;
    width: 100%;
}

.quickview-container {
  background-color: rgba(0, 0, 0, 0.75);
}

.product-info__title {
  text-transform: capitalize;
}

.product-info__details .product-detail-sku span {
  color: #666;
  font-size: 12px;
}

.product-info__details .product-detail-sku {
  text-align: right;
}

.product-info .product-title__brand {
  text-decoration: none;
}

.product-info .product-title__brand a {
  text-decoration: none;
}

/* 1442 - White space fixes */
.site-messages {
  display: none;
}

.product-info__main {
  padding-top: 0 !important;
}
.product-info {
  padding-top: 10px !important;
}

/* Hide Skip Actions Area from 2.2.3 */
.product-info__media #gallery-prev-area, .product-info__media .action-skip-wrapper {
  display: none !important;
}

/* 1411 Product Label Fix */
.product-info__main .product-info__attributes {
  position: relative !important;
  top: 0 !important;
}

/* Sale Badge PDP */
.product-info .badge-container {
  position: absolute;
  height: 100px;
  width: 100px;
  overflow: hidden;
  @media (max-width: 960px){
    top: 100px;
  }
  @media (max-width: 640px){
    top: 110px;
  }
  @media (max-width: 380px){
    top: 120px;
  }
  @media (max-width: 355px){
    top: 135px;
  }
}
.product-info .badge-container .badge:before, .product-info .badge-container .badge:after {
  content: "";
  border-top: 5px solid $color-shop-accent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  bottom: -5px;
}
.product-info .badge-container .badge:before {
  left: 0;
}
.product-info .badge-container .badge:after {
  right: 0;
}
.product-info .badge-container .badge {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate( -45deg);
  position: absolute;
  font-family: $font-family-base;
  color: #FFFFFF;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  top: 25px;
  left: -27px;
  width: 130px;
  text-align: center;
  vertical-align: middle;
  background-color: $color-shop-accent;
  z-index: 1;
}

.fotorama__caption[aria-hidden='true'] {
  display: none !important;
}

.fotorama__thumb {
  background-color: initial !important;
}

/* Update styling after bv js file change */
.bv_main_container .bv_main_container_row_flex:last-child {
  display: none !important;
}

[data-bv-show=rating_summary] .bv_main_container {
  float: right !important;
  margin-top: -25px !important;
}

[data-bv-show=rating_summary] .bv_main_container .bv_main_container_row_flex {
  padding-right: 0 !important;
}

div.bv_modal_component_container[data-bv-modal] .bv_modal_outer_content {
  display: none !important;
}

/* MMI-920 */
.fotorama__thumb-border {
  border: 2px solid #000000 !important;
}

.product-compare-wrapper{
  text-align: center;
  font-weight: 600;
}

.recently-viewed-pdp-container img.product-image-photo {
  width: 100%;
  height: auto;
}

.fh-campaign-tabs #aligent_fredhopper-campaign-we_recommend{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* MMI-1759 PDP Hero image */
.fotorama__wrap .fotorama__stage__frame .fotorama__img {
    max-height: 94.20%;
    max-width: 79.5%;
}

#afterpay-modal-learn-more {
  text-decoration: none !important;
}

/*** GIFTCARD PRODUCT ***/
/* Reset input field height as component library allows us to control height and we have some default styles for input fields */
.react-pdp-giftcard input[type="text"],
.react-pdp-giftcard input[type="password"],
.react-pdp-giftcard input[type="number"],
.react-pdp-edit-giftcard input[type="text"],
.react-pdp-edit-giftcard input[type="password"],
.react-pdp-edit-giftcard input[type="number"],
.react-pdp-extra input[type="number"],
.react-pdp-extra input[type="text"],
.react-pdp-extra input[type="password"] {
  height: initial;
  color: initial;
}

.react-giftcard-pdp input[type="number"] {
	-moz-appearance: textfield;
}

.react-giftcard-pdp input[type="number"]::-webkit-inner-spin-button,
.react-giftcard-pdp input[type="number"]::-webkit-outer-spin-button
{
	-webkit-appearance: none;
	margin: 0;
}

.giftcard-font-size-big{
  font-size: 20px;
  font-weight: 700;
}

.giftcard-font-size-small{
  font-size:14px;
}

.giftcard-calendar-wrapper{
  padding-top: 8px;
  background: #FFFFFF;
}

.giftcard-calendar-wrapper .react-calendar abbr[title] {
 cursor: auto !important;
 border-bottom: none !important;

}
.giftcard-display{
  display: flex;
  justify-content: space-between;
}

.giftcard-flex{
  flex: 0 0 calc(50% - 12px);

}

.giftcard-padding-top-16{
  padding-top: 16px;
}

.giftcard-padding-top-8{
  padding-top: 8px;
}

.giftcard-padding-top-24{
  padding-top: 24px;
}

.giftcard-padding-top-32{
  padding-top: 32px;
}

.react-giftcard-pdp .product-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.react-giftcard-pdp .product-info__media{
  flex: 0 0 60%;
  float:none;
}

.react-giftcard-pdp .product-info-main{
  flex: 0 0 40%;
  float: none;
}

@media (max-width: 960px) {
  .react-giftcard-pdp .product-info__media{
    flex: 0 0 100%;
    float:none;
  }

  .react-giftcard-pdp .product-info-main{
    flex: 0 0 100%;
    float: none;
  }

  .react-giftcard-pdp .fotorama__wrap .fotorama__stage__frame .fotorama__img{
    max-height: 100%;
    max-width: 100%;
  }
}

  .react-giftcard-pdp .react-pdp-extra-wrapper{
    flex : 0 0 100%;
  }

.react-giftcard-pdp .product-info__main{
  display:flex;
  flex-direction: column;
}

.react-giftcard-pdp .giftcard-heading-wrapper {
	flex: 0 0 100%;
}

.giftcard-textField div[class^="TextField-module_textFieldContainer"] {
	height: 38px;
}

.giftcard-textField input{
  text-align: center;
}

.giftcard-textFieldMultiline textarea{
  resize: none;
}

.giftcard-accordion-wrapper{
  margin-bottom: 0;
}

.giftcard-button-position button{
  position: static !important;
}

.adding-loader {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  &.hidden {
    display: none !important;
  }
}
.adding-loader img {
  height: 45px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 45px;
}

html.modalOpen{
  overflow-y:hidden !important;
  height: 100vh;
}

.gc-Minicart-message {
  display: block !important;
  font-weight: bold !important;
}

.minicart-product-added .gc-minicart-message-text {
  font-weight: 400 !important;
}

.giftcard-calendar-wrapper  .react-calendar__navigation__label__labelText{
  font-weight: bold !important;
}

.giftcard-calendar-wrapper{
  transition: 0.3s all ease;
}

.react-giftcard-pdp .grecaptcha-badge {
	margin-top: 20px;
}

.react-pdp-giftcard .other-amount-field div[class^="TextField-module_textFieldContainer"] {
  background-color: #333333 !important;
  color: #fff !important;
}
.react-pdp-edit-giftcard .other-amount-field div[class^="TextField-module_textFieldContainer"] {
  background-color: #333333 !important;
  color: #fff !important;
}

.react-pdp-edit-giftcard .other-amount-field input[type=text] {
  background-color: #333333 !important;
  color: #fff !important;
}

.react-pdp-giftcard .other-amount-field input[type=text] {
  color: #fff !important;
}
.react-giftcard-pdp .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
  max-width: 100% !important;
  max-height: 100% !important;
}

.react-giftcard-pdp .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  left: 19% !important;
}

.react-pdp-edit-giftcard .giftcard-calendar-wrapper div[class^="Calendar-module_calendarWrapper"],
.react-pdp-giftcard .giftcard-calendar-wrapper div[class^="Calendar-module_calendarWrapper"] {
    z-index: 1;
}

.react-pdp-extra .giftcard-accordion-heading {
    display: flex;
}

.react-pdp-extra .giftcard-accordion-heading div[class^="AccordionTab-module_accordianRightContent"] {
    margin-left: auto;
}

.react-pdp-extra div[class^="AccordionTab-module_headingWrapper"] {
    padding: 10px;
}

/*** GIFTCARD PRODUCT END ***/

/* MCM-20 Adjust wrapper height Fastly Image Optimisation */
.fotorama__wrap.fotorama__wrap--slide {
  .fotorama__stage, .fotorama__nav--thumbs {
    max-height: 600px;
  }
}

@media (max-width: 400px) {
  .fotorama__wrap.fotorama__wrap--slide {
    .fotorama__stage, .fotorama__nav--thumbs {
      max-height: 280px;
    }
  }
}
/*** END ***/
