.checkout-app {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  flex-grow: 1;
}

.checkout-app__sections {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}

.checkout-app__tab-section {
  padding: 0 16px;
}

.proceed-to-payment__button {
  @include checkout-button;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  i {
    margin: 0 4px;
  }
}

.react-checkout-final__button {
  @include checkout-button;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
  border-radius: 5px;
  z-index: 999;

  i {
    margin: 0 4px;
  }

  &[disabled] {
    background-color: #999999;
  }
}

.react-checkout-final__button--success {
  background-color: $color-success;
}

.react-checkout-blocker {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.account__continue-button,
.items-summary__continue-button {
  @include checkout-button;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  i {
    margin: 0 4px;
  }
}

.delivery-submit__button {
  @include checkout-button;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  i {
    margin: 0 4px;
  }
}

.payment .tabbed-ui__action-button {
  @include checkout-button;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  i {
    margin: 0 4px;
    vertical-align: bottom;
  }
}


.checkout-app__subtitle {
  margin: 0 0 1rem;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.account-message {
  margin: 0;
  font-size: 0.8rem;
  color: $color-input-placeholder;
}

/* MMI-883 */
#co-shipping-form .postcode-autosuggest .postcode-suggestion,
.my-account__group--address-book-address .postcode-autosuggest .postcode-suggestion,
.react-autosuggest__suggestion {
  cursor: pointer;
}

.postcode-autosuggest {
  max-height: 215px;
  overflow-y: scroll;
}

.checkout-app-form #address_suggestion {
  margin: 2px 0 0;
  list-style: none;
  border: 1px solid #000;
  border-radius: 5px;
}

.checkout-app-form #address_suggestion li {
  padding: 7px 15px;
  width: 100%;
}

.checkout-app-form #address_suggestion li:hover, .checkout-app-form .auto-address-container .noData-manual-option:hover {
  background-color: $address-validation-hover;
  cursor: pointer;
}

.checkout-app-form #address_suggestion .address-suggest-manual-option {
  border-top: 1px solid #000;
}

.checkout-app-form .address-auto-div {
  position: relative;
}

.checkout-app-form .address-auto-div .clear-data {
  position: absolute;
  right: 5px;
  top: 14px;
  display: none;
}

.checkout-app-form .address-auto-div .clear-data:after {
  font-family: "Material Icons";
  font-size: 20px;
  line-height: 1em;
  content: "\E14C";
  height: 17px;
  line-height: 17px;
}

.checkout-app-form .address-auto-div .focused-clear {
  display: block !important;
}

.checkout-app-form .address-auto-div .clear-data:hover {
  background-color: white;
}

.checkout-app-form .auto-address-container .noData-manual-option {
  padding: 7px 15px;
  width: 100%;
  border: 1px solid #000;
  margin: 2px 0 0;
  border-radius: 5px;
}
.checkout-app-form #auto-address-container{
  margin: 24px 0 12px 0;
}
.checkout-app-form .address-auto-div #address-auto::placeholder {
  font-size: 14px;
}