/*
  Styling for site messages.

  That display messages like, for example, "Item added to cart"
*/

.site-messages {
  padding: 10px 0;
  div.message {
    & > div:before {
      @include icon-font;
      margin-right: 5px;
    }
  }
  div[data-ui-id='message-success'] {
    & > div:before {
      content: '\E5CA';
    }
  }
  div[data-ui-id='message-error'] {
    & > div:before {
      content: '\E002';
      vertical-align: top;
    }
  }
}
