

/**
 * Login Form
 */
.checkout-app-authentication-card-user-login__intro-header {
    font-size: 22px;
    font-family: $font-family-base;
    text-align: center;
    margin-top: 1rem;
}
.checkout-app-authentication-card-user-login__intro-text {
    font-family: $font-family-base;
}

.checkout-app-authentication-card-user-login__container {
    padding: 9px 0;
}

.checkout-app-authentication-card-user-login__fields {
    display: flex;
    flex-direction: column;
}

