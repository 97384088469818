.shipping-methods__heading {
  margin: 0;
}

.shipping-methods {
  list-style: none;
  margin: 0;
  padding: 0;
}

.shipping-method {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #CCCCCC;
  font-size: 1rem;

  @include styled-checkbox($label-class: '.shipping-method__label', $input-class: '.radio');

  &:last-child {
    border: none;
  }

}

.shipping-method__label {
  position: relative;
  display: flex;
  width: 100%;
  cursor: pointer;

  .shipping-label {
    font-weight: bold;
    text-transform: uppercase;
  }

  .shipping-description {
    display: block;
    margin: 0 1rem;
  }

  .shipping-price {
    flex-grow: 1;
    text-align: right;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.checkout-app-form__delivery {
  margin-bottom: 1rem;

  .shipping-price {
    padding-left: 5px;
  }

}

.address {
  .show-company {
    color: $color-input-placeholder;
    text-decoration: underline;
    font-weight: bold;

    &:hover,
    &:focus {
      background: none;
      color: $color-black;
    }
  }
}

.afterpay-info__actions,
.stored-addresses__new-address,
.new-address__actions {
  display: flex;
  margin: 1rem 0 2rem;
  justify-content: space-between;
}

.stored-addresses__new-address__button {
  margin-left: 2rem;
}

.stored-address__actions {
  margin-bottom: 1rem;

  .stored-address__edit {
    padding-left: 0;
    padding-right: 0;
    border: 0;
    min-width: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      background: none;
      color: $color-shop-accent-hover;
    }
  }
}

.checkout-app .radio label,
.checkout-app .checkbox label {
  display: inline-block;
}

.checkout-app .radio input,
.checkout-app .checkbox input {
  display: inline-block;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  vertical-align: top;
}

.checkout-app-form .stored-addresses .radio {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.checkout-app-form {
  .address {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    .address-phone-info,
    .button-container,
    .select-container,
    .input-container {
      width: 100%;
      padding: 0 1rem;
    }

    .billing-address-first-name-container,
    .billing-address-last-name-container,
    .account-first-name-container,
    .account-last-name-container,
    .delivery-address-last-name-container,
    .delivery-address-first-name-container {
      width: 50%;
      margin-top: 0;
    }

    .input-container:last-child {
      // margin-bottom: 0;
    }

    .address-phone-info {
      margin: 0;
      color: $color-input-placeholder;
      font-size: 0.9rem;
      margin-bottom: 12px;
    }

  }

  .stored-addresses {
    .input-label {
      font-weight: normal;
      color: inherit;
      font-size: 1rem;
      text-transform: initial;
    }
  }

  .validation-message {
    color: red;
    font-size: 0.9rem;
  }
}

.deliver-message {
  color: $color-delivery-message-font-color;
  background: $color-delivery-message-font-background;
  padding: 8px;
  text-align: justify;
  font-size: 0.75rem;
}
