/* Font Awesome Star Rating Percentage */
/* PLP */
.product-tile__review-stars {
  padding: 0.75em 0 0 0;
}

.product-tile__review-stars .plp-review-count {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 15px;
}

.product-tile__review-stars .plp-rating-box {
  position: relative;
  vertical-align: middle;
  font-size: 15px;
  font-family: FontAwesome;
  display: inline-block;
  color: #000;
}

.product-tile__review-stars .plp-rating-box:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f005 \f005 \f005 \f005 \f005";
}

.product-tile__review-stars .plp-rating-box .rating {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #000;
}

.product-tile__review-stars .plp-rating-box .rating:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f005 \f005 \f005 \f005 \f005";
}

/* Quick View */
.product__review .qv-review-count {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 15px;
}

.product__review .qv-rating-box {
  position: relative;
  vertical-align: middle;
  font-size: 15px;
  font-family: FontAwesome;
  display: inline-block;
  color: #000;
}

.product__review .qv-rating-box:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f005 \f005 \f005 \f005 \f005";
}

.product__review .qv-rating-box .rating {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #000;
}

.product__review .qv-rating-box .rating:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f005 \f005 \f005 \f005 \f005";
}

.product-tile__review-stars.zero-reviews {
  display: none !important;
}

.product-tile__review-stars.zero-reviews .plp-rating-box {
  color: #f5f5f5;
}
.product-tile__review-stars.zero-reviews .plp-rating-box .rating {
  width: 100% !important;
  color: #F5F5F5;
}

/* BV stars on PLP colour change*/
.product-tile__review-stars .plp-rating-box,
.product-tile__review-stars .plp-rating-box .rating,
.product__review .qv-rating-box .rating,
.product__review .qv-rating-box {
  color: #ffd700 !important;
}