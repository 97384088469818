/**
 * Styling for swatches
 *
 * This was created in order to fix the way magento highlights selected swatches.
 * For an explanation of that, see _trumps.swatches.scss
 */

.swatch-option {
  &.selected {
    outline: 2px solid $color-swatch-border !important;
  }

  &:focus:not(.selected),
  &:hover:not(.selected) {
    border: 1px solid #dadada !important;
  }

  // template swatch should never be shown
  &.template {
    display: none;
  }
}
