.modal-login-register {
  @include loginRegisterModal;
  height: 100%;

  .modal-content-container {
    opacity: 0;
    transition: visbility 0s linear, opacity 200ms linear;
    visibility: hidden;

    &.active {
      opacity: 1;
      transition: visbility 0s linear, opacity 200ms linear;
      visibility: visible;
    }
  }

  .hr-text {
    @include hr-text(#000000, #FFFFFF, 1px);

    &::after {
      font-style: italic;
    }

    margin: 20px 0 0;
  }

  .switch-to-container {
    padding-top: 10px;

    p {
      margin: 0;
    }
  }

  .modal-content-login-form {
    position: relative;
  }

  .loading-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-message {
    font-weight: 600;
    padding-top: 15px;
  }

  .error {
    color: red;
  }

  .successful {
    color: green;
  }
}
