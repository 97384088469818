

.react-plp-app-product-filters__header {
  padding: 0.25rem 0.5rem;
}

.react-plp-app-product-filters__heading {
  font-family: $heading-font-family;
  font-size: 20px;
  color: #555555;
}

.react-plp-app-product-filters__accordion {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.react-plp-product-filter__button-active {
  font-weight: bold;
}

/**
  * 1. disable normal button hover style.
*/
.react-plp-app-product-filters__accordion-panel__header:focus,
.react-plp-app-product-filters__accordion-panel__header:hover {
  background-color: transparent; // 1
}

.react-plp-app-product-filters__accordion-panel__heading {
  width: 100%;
  text-align: left;
}

.react-plp-app-product-filters__accordion-panel {
  padding: 0 0.45rem;
}
/**
  * 1. initially hides the panel content
  */
.react-plp-app-product-filters__accordion-panel__content {
  display: none; // 1
  padding: 0.25rem 0.5rem;
}

.react-plp-app-product-filters__accordion-panel__header {
  position: relative;
  width: 100%;
  text-align: left;
  padding: 0.25rem 0.5rem;
  border-top: 1px solid #000000;

  &::after {
    font-family: 'Material Icons';
    font-size: 26px;
    line-height: 1em;
    display: block;
    position: absolute;
    right: 5px;
    top: -3px;
    content: '+';
  }
}

.react-plp-app-product-filters__accordion__item--active .react-plp-app-product-filters__accordion-panel__header::after {
  content: '-';
}


.react-plp-app-product-filters__accordion-panel__heading {
  font-family: $heading-font-family;
  font-size: 16px;
  text-transform: uppercase;
  color: black;
}

.react-plp-app-product-filters__accordion__item--active {
  .react-plp-app-product-filters__accordion-panel__content {
    display: block;
  }
}
