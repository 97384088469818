.find-in-store {
  display: flex;
  flex: 0 0 auto;

  pointer-events: none;
  opacity: 0.25;
  cursor: not-allowed;
}
.find-in-store--enabled {
  pointer-events: all;
  opacity: 1;
  cursor: auto;
}
.find-in-store--busy {
  cursor: wait;
}

.find-in-store__container {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  position: relative;
}

.find-in-store__header {
  padding-bottom: 0.5rem;
}

.find-in-store__body {
  width: 100%;
}

.find-in-store__postcode-search {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-start;
}
.find-in-store__postcode-heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 9px;
}

.find-in-store__postcode-input {
  border: 1px solid;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
.find-in-store__postcode-submit-button {
  padding: 0.5rem;
  width: 100%;
}
.find-in-store__postcode-submit-button:disabled {
  cursor: not-allowed;
}

.find-in-store__stores {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  width: 80%;
  padding-top: 1em;
}
.find-in-store__stores-heading {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 0 0.5rem;
  margin-bottom: 9px;
}

.find-in-store__stores-list {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  width: 100%;
}
.find-in-store__store-item {
  cursor: pointer;
  width: 100%;
  padding: 0.25rem ;
  border: 0;
  text-align: left;

  &:hover {
    background: none;
    text-decoration: underline;
  }
}

.find-in-store__busy-indicator {
  display: none;
  flex: 0 0 auto;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  height: 64px;
  width: 64px;

  animation: find-in-store__rotate 2s linear infinite;
}
.find-in-store__busy-indicator-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: rgba(black, 0.5);
  animation: find-in-store__dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

.find-in-store--busy .find-in-store__busy-indicator {
  display: flex;
}

@keyframes find-in-store__rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes find-in-store__dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}


/**
 * Find in store modal
 */
.find-in-store-modal {
  display: flex;
  flex: 1 0 auto;

  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  background: white;
}
.find-in-store-modal__close-button {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1000;
  width: 2rem;
  height: 2rem;

  border-radius: 1000px;

  cursor: pointer;
}

.find-in-store-modal__contents {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column-reverse;
  justify-content: center;
  flex-wrap: wrap;

  overflow: hidden;

  width: 100%;
  height: 100%;

}

.find-in-store-modal__map {
  position: relative;

  height: 0;
  width: 0;

}
.find-in-store-modal__map .find-in-store__busy-indicator {
  display: block;
}

.find-in-store-modal__main {
  overflow-y: scroll;

  height: 65%;
  width: 97%;

  border: 0;
}
.find-in-store-modal__header {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.find-in-store-modal__store-name {
  text-transform: uppercase;
}

.find-in-store-modal__extra-info-label {
  display: none;
}

.find-in-store-modal__extra-info-label--visible {
  display: inherit;
}

.find-in-store-modal__divider {
  border-top: 0;
  border-bottom: 2px solid;
  width: calc(100% - 2rem);
  margin: 0 auto;
  display: block;
}
.find-in-store-modal__section {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-start;

  padding: 1rem;
  padding-bottom: 0;
}

.find-in-store-modal__section-heading {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.find-in-store-modal__more-info {
  display: none;
}

.find-in-store-modal__more-info--visible {
  display: inherit;
}

.find-in-store-modal__address {
  margin-bottom: 0;
}
.find-in-store-modal__opening-hours {
  margin-bottom: 0.25rem;
}
.find-in-store-modal__opening-hours-day {
  min-width: 10rem;
  margin-right: 0.5rem;
}
.find-in-store-modal__reserve {
  margin-top: 1rem;
  background: $color-background;
}
.find-in-store-modal__phone {
  font-weight: bold;
  text-decoration: none;
}

.find-in-store-modal__details {
  padding-top: 0;
}

.find-in-store-modal__stock {
  display: table;
}
.find-in-store-modal__stock .find-in-store__busy-indicator {
  display: flex;
  position: static;
  margin: 0;
  height: 30px;
  width: 30px;
}

.find-in-store-modal__stock-item {
  display: table-row;

  margin-bottom: 0.25rem;
  width: 100%;

  font-size: 14px;
}
.find-in-store-modal__stock-name {
  display: table-cell;
  padding-right: 1rem;
  padding-bottom: 0.25rem;
  text-align: left;
}
.find-in-store-modal__stock-quantity {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  border-radius: 1000px;
  padding: 0 0.5rem;
}

.find-in-store-modal__contact {
  display: flex;
}
.find-in-store-modal__contact-form .form__label {
  display: none;
}

.find-in-store-modal__contact-form .form__field--is-empty .form__label {
  display: flex;
}

.find-in-store-modal__contact-form--wireframe {
  width: 100%;
}

.find-in-store-modal__contact-form .find-in-store__input-message {
  height: 8.25rem;
  padding: .5rem 1rem;
}


.find-in-store-modal__contact { display: none; }
.find-in-store-modal__details { display: flex; }
.find-in-store-modal__stock { display: flex; }
.find-in-store-modal__email-button-label--closed { display: flex; }
.find-in-store-modal__email-button-label--open { display: none; }

.find-in-store-modal__store--contact-visible .find-in-store-modal__contact { display: flex; }
.find-in-store-modal__store--contact-visible .find-in-store-modal__details { display: none; }
.find-in-store-modal__store--contact-visible .find-in-store-modal__stock { display: none; }
.find-in-store-modal__store--contact-visible .find-in-store-modal__product { display: none; }
.find-in-store-modal__store--contact-visible .find-in-store-modal__reserve { display: none; }
.find-in-store-modal__store--contact-visible .find-in-store-modal__email-button { min-width: auto; }
.find-in-store-modal__store--contact-visible .find-in-store-modal__email-button-label--closed { display: none; }
.find-in-store-modal__store--contact-visible .find-in-store-modal__email-button-label--open { display: flex; }

.find-in-store-modal__map .gm-fullscreen-control {
  top: 50px !important;
}


.fis-enabled .find-in-store__postcode-submit-button {
  background-color: $color-shop-accent;
  color: white;
}
.fis-enabled .find-in-store__postcode-submit-button:disabled {
  background: silver;
  border-color: silver;
}
.fis-enabled .find-in-store__store-item {
  background-color: transparent;
  color: black;
}

.fis-enabled .find-in-store-modal__close-button {
  background: rgba(white, 0.25);
}
.fis-enabled .find-in-store-modal__close-button:hover {
  background: white;
}

.fis-enabled .find-in-store-modal__email-button {
  background: black;
  color: white;
}

.fis-enabled .find-in-store-modal__main {
  background-color: white;
  padding: 1rem;
}

.fis-enabled .find-in-store-modal__map {
  background-color: cornflowerblue;
}

.fis-enabled .find-in-store-modal__divider {
  border-bottom-color: #ccc;
}

.fis-enabled .find-in-store-modal__section-header {
  color: black;
}

.fis-enabled .find-in-store-modal__stock-quantity {
  color: #333;
}

.fis-enabled .find-in-store-modal__stock-quantity--out-of-stock {
  color: red;
}

.fis-enabled .find-in-store-modal__contact-form .find-in-store__input--message {
  height: 8.25rem;
  padding: .5rem 1rem;
}

/* MMI-437 - Find in store improvements */
.fis-enabled .popup_content.find-in-store-modal {
  overflow-y: hidden !important;
}
.fis-enabled .find-in-store-modal__section {
  padding: 0;
}
.fis-enabled .find-in-store-modal__main {
  overflow-y: auto;
}
.fis-enabled .product-info__main .product-info__social {
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
}
.fis-enabled .product-info__main .product-info__social .find-in-store,
.fis-enabled .product-info__main .product-info__social .product-addto-links {
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  border: 1px solid $color-shop-accent;
  padding: 0;
}
.fis-enabled .product-info__main .product-info__social .product-addto-links {
  margin: 15px 7px 10px 0;
  padding: 5px;
}
.fis-enabled .product-info__main .product-info__social .find-in-store {
  margin: 15px 0 10px 7px;
}
.fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store {
  background-color: initial;
  color: #000;
  width: 100%;
  padding: 0;
  font-size: 14px;
}
.fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store:before {
  font-family: Material Icons;
  font-size: 26px;
  line-height: 1em;
  content: "\e55f";
  margin-left: -10px;
  vertical-align: bottom;
  color: $color-shop-accent;
  margin-right: 2px;
}
.fis-enabled .product-info__main .product-addto-links .towishlist:before {
  margin-top: 0px;
  vertical-align: bottom;
  color: $color-shop-accent;
}

.fis-enabled .find-in-store-modal__header h1 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.fis-enabled .find-in-store-modal__postcode-search {

}
.fis-enabled .find-in-store-modal__postcode-search .find-in-store__postcode-label {
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.fis-enabled .find-in-store__postcode-label .find-in-store__postcode-input {
  -webkit-flex: 1 1 70%;
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
  margin-bottom: 0;
}
.fis-enabled .find-in-store__postcode-label .find-in-store__postcode-submit-button {
  -webkit-flex: 1 1 30%;
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  text-transform: uppercase;
  padding: 0;
  font-size: 20px;
  padding-top: 3px;
}
.fis-enabled .find-in-store-modal__stock-toggle {
  padding-bottom: 8px;
}
.fis-enabled .find-in-store-modal__stock-toggle .find-in-store__stock-toggle-label {
  display: inline-block;
  width: 140px;
  text-align: right;
}

.fis-enabled .find-in-store-modal__main .find-in-store-modal__product {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
}
.fis-enabled .find-in-store-modal__main .find-in-store-modal__product .find-in-store-modal__product_image {
  display:inline-block;
  max-width: 38%;
  margin-right: 10px;
}
.fis-enabled .find-in-store-modal__main .find-in-store-modal__product .find-in-store-modal__product_details {
  display:inline-block;
  max-width: 58%;
}
.fis-enabled .find-in-store-modal__main .find-in-store__stores {
  overflow-y: auto;
  width: 100%;
  max-height: 300px;
  border: 1px solid #DDDDDD;
}
.fis-enabled .find-in-store-modal__main .find-in-store__stores .find-in-store-modal__store {
  margin-right: 10px;
  border-bottom: 1px solid #DDDDDD;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__header {
  flex-wrap: nowrap;
  text-transform: uppercase;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__store-name {
  -webkit-flex: 1 1 70%;
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
  text-transform: capitalize;
  margin-bottom: 10px;
  margin-top: 10px;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__store-name .find-in-store__the-store-name,
.fis-enabled  .find-in-store-modal__store .find-in-store__stock-status {
  font-weight: bold;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__extra-info-container {
  text-align: left;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__extra-info-container .find-in-store-modal__extra-info:hover,
.fis-enabled .find-in-store-modal__store .find-in-store-modal__extra-info-container .find-in-store-modal__extra-info:active,
.fis-enabled .find-in-store-modal__store .find-in-store-modal__extra-info-container .find-in-store-modal__extra-info:focus{
  background-color: initial;
  outline: 0;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__store-details {
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__store-details .find-in-store-modal__phone {
  font-weight: 400;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__store-details .find-in-store-modal__phone a {
  text-decoration: none;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__store-details .find-in-store-modal__phone a:before {
  font-family: Material Icons;
  content: "\e551";
  vertical-align: top;
  margin-right: 10px;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__store-details .find-in-store-modal__distance:before {
  font-family: Material Icons;
  content: "\e55f";
  vertical-align: top;
  margin-right: 7px;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__more-info--visible .find-in-store-modal__address a {
  text-decoration: none;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__more-info--visible .find-in-store-modal__address a:before {
  font-family: Material Icons;
  content: "near_me";
  vertical-align: top;
  margin-right: 7px;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__open-closed--open {
  color: limegreen;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__open-closed--closed {
  color: gray;
}
.fis-enabled .find-in-store-modal__store .find-in-store-modal__open-hours ul {
  margin-bottom: 10px;
  margin-left: 0;
  list-style: none;
}

.fis-enabled .find-in-store-modal__store .find-in-store__stock-status.In.Stock {
  color: limegreen;
}
.fis-enabled .find-in-store-modal__store .find-in-store__stock-status.Out.of.Stock {
  color: gray;
}
.fis-enabled .find-in-store-modal__store .find-in-store__stock-status.Limited.Stock {
  color: darkorange;
}

.fis-enabled .find-in-store-modal__product_details .swatch-options-find-in-store .swatch-option.text {
  border: 1px solid #666;
  color: #666;
  background-color: #FFF;
}

.fis-enabled .find-in-store-modal__main .find-in-store-modal__footer {
  color: #666;
  font-size: 14px;
  padding-top: 10px;
}

.fis-enabled .find-in-store-modal__product_details .find-in-store-product-brand {
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
}
.fis-enabled .find-in-store-modal__product_details .find-in-store-product-name {
  font-size: 20px;
  text-transform: capitalize;
}
.find-in-store-modal__product_details .find-in-store-product-price {
  font-weight: bold;
}

.fis-enabled .find-in-store-modal__product_details .find-in-store-product-price.is-special-price .find-in-store-product-rrp {
  text-decoration: line-through;
}
.fis-enabled .find-in-store-modal__product_details .find-in-store-product-price .find-in-store-product-final-price {
  color: #000;
}
.fis-enabled .find-in-store-modal__product_details .find-in-store-product-price.is-special-price .find-in-store-product-final-price {
  color: $color-sale;
}
.fis-enabled .find-in-store-modal__main .find-in-store__stores .find-in-store-no-size-selected {
  color: #666;
  text-align: center;
  padding: 10px;
}

.fis-enabled .find-in-store-modal__main .find-in-store__stores .find-in-store-no-size-selected-error {
  text-align: center;
  padding: 10px;
}

.fis-enabled .find-in-store-modal__header h1 {
  display: none;
}

.fis-enabled .product-info-main .overview.product-attribute {
  display: none;
}

.fis-enabled .find-in-store-modal__store .find-in-store-modal__click-collect {
  -webkit-flex: 1 1 30%;
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  text-align: center;
  margin-top: 10px;
  padding: 5px 10px;
  border: 1px solid $color-shop-accent;
  color: $color-shop-accent;
  cursor: pointer;
}

.fis-enabled .find-in-store-modal__store .find-in-store-modal__click-collect.no-click-collect {
  border: 1px solid #666;
  color: #666;
  cursor: default;
}

// Disable text highlight
.fis-enabled .find-in-store-modal__store .find-in-store-modal__click-collect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Mobile Specific MMI-437 */
@media (max-width: 719px) {
  .fis-enabled .find-in-store-modal__contents {
    display: block;
  }
  .fis-enabled .find-in-store-modal__main {
    height: 100%;
  }
  .fis-enabled .find-in-store-modal__main .find-in-store__stores {
    padding: 10px;
  }
  .fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store {
    padding-top: 4px;
  }
}
@media (max-width: 960px) {
  .fis-enabled .find-in-store-modal__main .find-in-store-modal__product .find-in-store-modal__product_image {
    max-width: 60%;
  }
  .fis-enabled .find-in-store-modal__main .find-in-store-modal__product .find-in-store-modal__product_details {
    max-width: 100%;
  }
}