/*------------------------------------*\
    ##MINICART
\*------------------------------------*/

.up-arrow {
  width: 0;
  height: 0;
  border-left: $arrowWidth solid transparent;
  border-right: $arrowWidth solid transparent;
  border-bottom: $arrowWidth solid $color-border-outline-light;
  position: absolute;
  top: -5px;
  right: 46px;
}

.minicart {
  position: relative;

  & > a::before {
    content: $minicart-icon;
  }

  .minicart__details {
    display: none;
    cursor: default;
    z-index: 5;
    border: 1px solid $color-border-outline-light;
    background-color: $color-white;
    padding: 20px;
    position: absolute;
    min-width: 350px;
    right: 12px;
    top: calc(100% + 15px);

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 15px;
    }

    &._open {
      display: block;
    }

    .block-title {
      display: none;
    }

    .block-content {
      position: relative;

      .action.close {
        position: absolute;
        top: -7px;
        right: -7px;
      }

      .subtotal {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 20px;
        border-top: 1px solid $color-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -6px;
        margin-right: -6px;

        .label,
        .price-container {
          padding-left: 6px;
          padding-right: 6px;
        }

        .label {
          vertical-align: middle;
          font-size: 1rem;
        }

        .price-container {
          font-size: 1.375rem;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .items-total {
        display: none;
      }

      .cart.actions {
        display: flex;
        margin-left: -10px;
        margin-right: -10px;

        .primary,
        .secondary {
          padding: 0 16px;
        }

        .action {
          @include widget-button;
          display: block;
          padding: 12px 16px;
        }

        .primary .action {
          &:hover {
            background-color: $color-shop-accent-hover;
          }
        }
        .secondary .action {
          background-color: $color-dark-grey;

          &:hover {
            background-color: $color-dark-grey-accent;
          }
        }
      }
    }

    #btn-minicart-close {
      display: none;
    }
  }

  &:hover {
    .minicart__details {
      display: block;
    }
  }

  // Prevent the minicart from showing on the checkout page
  // N.B. the `&:hover` part cannot be nested
  .checkout-cart-index &:hover {
    .minicart__details {
      display: none;
    }
  }

  .out-of-stock {
    color: red !important; // Overwriting another style that uses !important
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.9em;
  }
}

.minicart-items-wrapper {
  margin-bottom: 15px;
}
.minicart-items { /* ol */
  height: inherit !important; /* Needs to be !important to overcome inline style applied in Magento knockout template */
  margin: 0;
  padding: 0;

  .product-item { /* li */
    display: block;
    padding: 10px;
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border-color: $color-grey;
    }

    & > .product {
      display: flex;
    }

    .product-item-photo,
    .product-image-wrapper img { /* Minicart on the checkout doesn't put the .product-item-photo class on the img */
      flex: 0 0 auto;
    }

    .product-item-details {
      flex: 1 1 auto;
      position: relative;
      padding-left: 10px;
      padding-right: 40px; /*Get the content clear of the delete button icon*/

      .product-item-name {
        white-space: normal;
        margin: 0 0 6px;
        letter-spacing: $subtitle-letter-spacing;
        font-weight: bold;

        a {
          text-decoration: none;
        }
      }

      .product-item-pricing {
        color: $color-dark-grey;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: $subtitle-letter-spacing;
      }

      .product.actions {
        .action.edit {
          display: none;
        }
        .action.delete {
          position: absolute;
          top: -5px;
          right: -5px;
          text-decoration: none;
          background-color: $color-black;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            @include icon-font;
            content: '\e5cd';
            font-size: 14px;
            color: $color-white;
          }

          span {
            display: none;
          }

          &:hover {
            background-color: $color-black-accent;
          }
        }
      }
    }
  }
}

/* This is outside the nesting above so that it will apply the same styling to both dynamic and static cart links */
.minicart__summary {
  position: relative;

  .minicart__count {
    @include small-text-white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
    position: absolute;
    background-color: $color-background-counter-number;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    text-align: center;
    top: 12px;
    right: -1px;

    @include media-query(medium-and-up) {
      right: 30px;
      top: 20px;
      width: 20px;
      height: 20px;
      font-size: 12px;
    }

    &._empty {
      display: none;
    }
  }
}

.minicart-details-header {
  text-align: left;
}

.product-item-details {
  & > .product.options {
    padding-top: 10px;
  }

  .product.options,
  .details-qty.qty {
    display: flex;

    &.list {
      width: 100%;
      margin: 0;
      display: flex;
      @include small-text-black;
    }
    .label {
      padding-right: 15px;
      flex: 0 0 auto;
      text-align: left;
      font-size: 0.75rem;
    }
    .values,
    input {
      flex: 1 1 auto;
      text-align: right;
      color: $color-text;
    }
    input {
      display: block;
      height: auto;
    }
  }
}
