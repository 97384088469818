.react-plp-app-product {
  &:focus,
  &:hover {
    border: 1px solid #C8C8C8;

    .react-plp-app-product__actions--offcanvas {
      opacity: 1;
      visibility: visible;
      transition: opacity 200ms ease 0s,
      visibility 0ms ease 0s,
    }
  }

  &:focus-within {
    border: 1px solid #C8C8C8;

    .react-plp-app-product__actions--offcanvas {
      opacity: 1;
      visibility: visible;
      transition: opacity 200ms ease 0s,
      visibility 0ms ease 0s,
    }
  }
}
