
.social {
  @extend %list-inline;
}


.social__text {
  @extend %u-visually-hidden;
}
.site-footer__social-links-container {
  width: 100%;
  border-bottom: 1px solid #000;
  padding: 7px 0 8px;


  ul {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    max-width:480px;
  }

  li {
    flex-grow: 1;
    font-size:0;
    text-align: center;
    vertical-align: bottom;
  }
}

.social__link {
  @include font-awesome;

  width: $social-links-width;
  font-size: $social-links-width;
  margin: 0;
  padding: 0;
  text-decoration: none;
  line-height: 1em;
}

.social__link--facebook {
  &:before {
    content: '\f230';
  }
}
.social__link--twitter {
  &:before {
    content: '\f099';
  }
}
.social__link--youtube {
  &:before {
    content: '\f167';
  }
}
.social__link--instagram {
  &:before {
    content: '\f16d';
  }
}
.social__link--pinterest {
  &:before {
    content: '\f0d2';
  }
}
.social__link--email {
  &:before {
    content: '\f0e0';
  }
}
