.one-up__widget {
  height: 624px;

  .one-up__widget-image-container {
    display: table;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    text-align: center;
    text-decoration: none;

    & > div {
      display: table-cell;
      vertical-align: middle;

      .one-up__widget-heading {
        @include heading1;
        font-size: 45px;
        line-height: 1em;
        @media (min-width: 385px) {
          font-size: 69px;
        }
        @media (min-width: 561px) {
          font-size: 100px;
        }
        color: #FFF; /* Default colour for the element */
        display: block;
        @include widgetColours; /* Apply any user-specified colours */
      }
      .one-up__widget-button-container {
        display: block;

        .widget-button {
          width: 206px;
          font-size: 20px;
        }
      }
    }
  }
}
