@use "sass:math";
/*------------------------------------*\ #PAGE
\*------------------------------------*/

/**
 * High-, page-level styling.
 *
 * Replaces Inuit CSS's 'inuit-page/_base.page.scss';
 **/

/**
  * High-, page-level styling.
  *
  * 1. Set base font-size and line-height so everything else can be adjusted
  *    proportionally.
  * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
  *    navigating between pages that do/do not have enough content to produce
  *    scrollbars naturally.
  * 3. Ensure the page always fills at least the entire height of the viewport.
  * 4. Prevent certain mobile browsers from automatically zooming fonts.
  * 5. Fonts on OSX will look more consistent with other systems that do not
  *    render text using sub-pixel anti-aliasing.
  **/

html {
    @include font-base;

    background-color: #ffffff;
    color: $color-text;
    font-size: (math.div($font-size-base, 16px)) * 1em; /* [1] */
    line-height: $line-height-base; /* [1] */
    overflow-y: scroll; /* [2] */
    min-height: 100%; /* [3] */
    text-size-adjust: 100%; /* [4] */
    -moz-osx-font-smoothing: grayscale; /* [5] */
    -webkit-font-smoothing: antialiased; /* [5] */
 }


body {
    position: relative; // Make sure child elements can be positioned relative to the body's margins.
}

a {
    color: $color-links;
}

strong {
    font-weight: inherit;
}

#page_wrapper {
    background-color: white; /* Required so that the slideout menu will appear to be 'covered' during the sliding animation */
}

.page-layout__item--primary {
    position: relative;
}


/**
 * Where `margin-bottom` is concerned,this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
hr,
table,figure,
pre,
%margin-bottom {
    margin-bottom: $spacing-unit-base;
}





/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once,here.
 */
ul, ol,
%margin-left {
    margin-left: 2 * $spacing-unit-base;
}

/** 
Breadcrumbs css PLP, PDP pages.
*/
.breadcrumbs_container ul.breadcrumbs{
    margin: 10px 8px;
    padding: 0px;
}
.breadcrumbs_container ul.breadcrumbs li{
    text-transform: uppercase;
}
.breadcrumbs_container ul.breadcrumbs li+li::before{
    content: "/";
}
.breadcrumbs_container ul.breadcrumbs li a{
    text-underline-position: under;
}
.breadcrumbs_container ul.breadcrumbs li a:hover{
    text-decoration: none;
    color: #666;
}