/*------------------------------------*\
    #ORDER TABLE
\*------------------------------------*/

@include private {
    $cell-padding: 0.5em;
    $action-spacing: 0.3em;

    .order-table {
        width: 100%;

        caption {
            display: none;
        }
    }

    .order-table__header-group {
        @extend %responsive-table__header-group;

        border-bottom: 1px solid currentColor;
    }

    .order-table__footer-group {
        border-top: 1px solid currentColor;
    }


    .order-table__row {
        @extend %responsive-table__row;
        vertical-align: top;

        @include media-query($responsive-table-breakpoint) {
            padding: $cell-padding 0;
        }

        * + & {
            border-top: 1px solid currentColor;

            .order-table--history & {

            }
        }
    }

    .order-table__row--shipping,
    .order-table__row--subtotal {
        border-top: none;
    }

    .order-table__row--grand_total {
        font-weight: bold;
    }


    %order-table__cell {
        @include media-query($responsive-table-breakpoint) {
            padding: $cell-padding;
            text-align: center;
        }
    }

    %order-table__cell--right {
        @include media-query($responsive-table-breakpoint) {
            text-align: right;
        }
    }

    %order-table__cell--left {
        @include media-query($responsive-table-breakpoint) {
            text-align: left;
        }
    }
    

    .order-table__header {
        @extend %order-table__cell;

        display: none;
        visibility: hidden;

        @include media-query($responsive-table-breakpoint) {
            display: table-cell;
            visibility: visible;
        }
    }

    .order-table__header--mark {
        @extend %order-table__cell--right;

        font-weight: inherit;
    }


    .order-table__data {
        @extend %order-table__cell;
        @extend %responsive-table__data;
    }

    .order-table__options {
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 0;
        vertical-align: top;
    }


    .order-table__data--actions {
        text-align: center;

        @include media-query($responsive-table-breakpoint) {
            padding-bottom: $cell-padding - $action-spacing;
            padding-right: 0;
        }
    }

    .order-table__data--name {
        text-align: center;
    }

    .order-table__data--amount {
        @extend %order-table__cell--right;
    }

    .order-table__data--price {
        tbody & {
            text-align: center;
        }

        tfoot & {
            @extend %order-table__cell--right;
        }
    }

    .order-table__data--qty {
        tbody & {
            text-align: center;
        }

        tfoot & {
            @extend %order-table__cell--right;
        }
    }

    .order-table__data--subtotal {
        tbody & {
            text-align: center;
        }

        tfoot & {
            @extend %order-table__cell--right;
        }
    }

    .order-table__data--total {
        @extend %order-table__cell--right;
    }


    .order-table__action {
        @extend %button;
        @extend %button--small;

        margin-bottom: $action-spacing;
    }


    .order-table__quantities {
        display: inline-block;
        margin: 0;
    }


    .order-table__quantity-label {
        &:after {
            content: ":";
        }
    }
}

.my-account__group--order-history .order-table__header--total {
    text-align: right;
}