/**
 * This will style the related products (called 'Also available in:' on the site) component
 */

/* Ensure we are only styling the related products component as it appears on the PDP */
.product-info__main > .related-products{
   .related-products__title {
     //display: none;
     @include media-query(medium-and-up) {
       display: block;
     }
     @include small-text-black;
   }

  .related-products__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .related-products__item {
    display: inline-block;
    width: 100%;
  }

  .product-tile {
    text-align: center;
  }

  .product-tile__link,
  .product-image-wrapper{
    display: block;
  }

  .product-image-container {
    width: 75px;
    display: inline-block;
  }

  .product-image-photo {
    width: 100%;
    height: inherit;
  }

  .product-tile__title,
  .price-box,
  .product-tile__actions {
    display: none;
  }
}
