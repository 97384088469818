.site-footer__site-map {
  @extend %list-inline;
  @extend %u-1-of-1;
  @extend %u-2-of-3-medium-and-up;
  display: block;
  vertical-align: top;
  text-align: left;
  padding: 10px;
  font-size: 0; //Hack to ensure elements with % width adding up to 100% will fit without wrapping

  @include media-query(medium-and-up) {
    float: left;
    display: inline-block;
    padding: 0; /* Padding of element .footer-sitemap-contact will be used instead */
    font-size: 0;
  }

  ul {
    @extend %list-bare;
  }

  .widget {
    display: inline;
  }

  .site-map__links-column {
    @extend %u-1-of-2;
    @extend %u-1-of-4-medium-and-up;
    @include small-text-grey;

    display: inline-block;
    vertical-align: top;

    /* The 3rd and 4th columns will be displayed below the 1st and 2nd, so some spacing will be required when on mobile */
    &:nth-child(3), &:nth-child(4) {
      margin-top: $layout-gutter;
      @include media-query(medium-and-up) {
        margin-top: inherit; /* IE Fix */
        margin-top: initial;
      }
    }

    li, a {
      @include functional-text-small-grey;
      font-size: 14px;
      line-height: 1.1em;
      margin:9px 0 0;
      text-decoration: none;

      &:first-child {
        margin:0;
      }
    }

    a:hover,
    a:focus {
      color: #000;
    }
  }
}


.footer-sitemap-contact__inner {
  @extend %wrapper;
}
