
.tab-button {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    text-align: center;
}