@mixin popup_default_btn {
  @include functional-text-grey;
  background-color: $color-popup-default;
  padding: 6px 10px;
}

@mixin popup_submit_btn {
  @include widget-button;
  width: auto;
  margin-left: 5px;
  padding: 6px 10px;
}

@mixin popup_overlay {
  background-color: $color-popup-overlay;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  cursor: pointer;
}

/*
  The intention of this class is for it to be added to the <body> element when a popup is displayed.
  Doing so will stop the background from scrolling while the popup is visible
*/
.noScroll {
  overflow: hidden;
}
