/**
 * This stylesheet is for the TwoToOne and OneToTwo widgets.
 * They're essentially the exact same widget, just with the order of the elements swapped.
 */
.two-to-one,
.one-to-two {
  max-width: 1262px;
  margin: 0 auto;
  height: 655px;
  padding: 0 15px;

  @include media-query(medium-and-up) {
    height: 320px;
  }
}
.two-to-one__widget,
.one-to-two__widget {
  display: block;
  position: relative;
  height: 320px;

  @include media-query(medium-and-up) {
    display: inline-block;
    height: 100%;
  }

  /*The widths of the banners when the widget is flipped seems to be off by a pixel either way, so this is needed to fix that up*/
  &:first-child.width-one-third .one-to-two__banner-text-container {
    width: calc(100% + 1px);
  }
  &:last-child.width-two-thirds .one-to-two__banner-text-container {
    @include media-query(medium-and-up) {
      width: calc(100% - 15px);
    }
  }
}
.two-to-one__widget,
.one-to-two__widget {
  &.width-two-thirds {
    @extend %u-1-of-1;
    @extend %u-2-of-3-medium-and-up;

    .two-to-one__banner-text-container,
    .one-to-two__banner-text-container {
      width: calc(100% - 1px);
    }
  }

  &.width-one-third {
    @extend %u-1-of-1;
    @extend %u-1-of-3-medium-and-up;
  }
}
.two-to-one__widget,
.one-to-two__widget {
  &:first-child {
    .two-to-one__banner-text-container,
    .one-to-two__banner-text-container {
      width: 100%;
    }
  }
}
.two-to-one__widget,
.one-to-two__widget {
  &:last-child {
    width: 100%;
    padding: 15px 0 0 0;

    @include media-query(medium-and-up) {
      padding: 0 0 0 15px;
    }

    .two-to-one__banner-text-container,
    .one-to-two__banner-text-container {
      left: 0;
      width: 100%;

      @include media-query(medium-and-up) {
        left: 15px;
        width: calc(100% - 14px);
      }
    }
  }
}
.two-to-one__link,
.one-to-two__link {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.two-to-one__banner-text-container,
.one-to-two__banner-text-container {
  display: table;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  min-height: 40px;
  font-size: 1.3em;
  color: white;
  text-transform: uppercase;

  @include widgetColours;

  span {
    display: table-cell;
    vertical-align: middle;;
  }
}
