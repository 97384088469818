.react-plp-app {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

.react-plp-app__results {
  flex-grow: 1;
}

.react-plp-app__results-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-start;
  padding: 0.5rem;
}

.react-plp-app__filters {
  position: relative;
  width: 100%;
  flex-shrink: 0;
}

.react-plp-app__result-count {
  position: absolute;
  top: 3.5rem;
  right: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.accordion-key__content {
  overflow: hidden;
}

.react-plp-app-product-filters {
  background-color: $color-white;
  position: relative;
  z-index: 1;
}

.react-plp-app-product-filters-accordion {
  .accordion {
    margin-top: 0;
  }

  .accordion-key__header {
    padding: 0.5rem;
  }

  .accordion-key__trigger {
    position: relative;
    border: 1px solid currentColor;
    font-size: inherit;
    font-family: inherit;
    height: 2.74rem;
    margin: 0;
    padding: 0 3rem 0 1rem;
    text-transform: uppercase;

    &:focus,
    &:hover {
      color: $color-white;
    }

    &::after {
      font-family: Material Icons;
      font-size: 26px;
      line-height: 1em;
      display: block;
      position: absolute;
      right: 10px;
      top: 2px;
      content: '+';
    }
  }

  .accordion-key--open .accordion-key__trigger::after {
    content: '-';
  }
}
