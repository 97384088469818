/*------------------------------------*\
    #NAVIGATION BAR
\*------------------------------------*/

.navigation-bar--primary {
    border-top: 0;
    border-bottom: 0;
    background-color: $color-lighter-gray;
    position: relative;
    display: none;

    @include media-query(medium-and-up) {
        display: block;
    }
}

.navigation-bar__wrapper {
    @extend %wrapper;
}
