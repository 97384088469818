
.checkout__layout-header {
  .site-header__logo-container {
    width: 200px;
  }
}

.checkout__layout-links {
  font-size: 0.9rem;
}

.checkout__layout-footer {
  justify-content: space-between;

  .footer-payment-methods__list {
    text-align: left;
  }

  .footer-payment-methods__item {
    margin: 0 3px;
  }
}

.checkout__layout-link-contact {
  display: inline-block;
}
