/*------------------------------------*\
    #NAVIGATION BAR
\*------------------------------------*/

.navigation-bar--primary {
    border-top: 1px solid $color-navigation-border;
    border-bottom: 1px solid $color-navigation-border;
    background-color: $color-navigation-background;
    position: relative;
    display: none;

    @include media-query(medium-and-up) {
        display: block;
    }
}

.navigation-bar__wrapper {
    @extend %wrapper;
}
