/*------------------------------------*\
    #MAIN CONTENT
\*------------------------------------*/

/**
 * Generic container between header and footer
 **/

.main-content {
    margin-bottom: rems(45px);
}

/* Instagram Plugin Widget */
#instagram-gallery .gallery,
#instagram-gallery .gallery-nav {
    display: none;
}

#instagram-gallery .widget-wrapper .widget-timeline .widget-text-container {
    display: none;
}

/* End Instagram Plugin Widget */
