@media (max-width: 960px) {
  .product-list__item .product-tile .badge-container {
    left: 4px !important;
  }
}

.product-list__item .product-tile .badge-container {
  left: 20px;
  top: 20px;
  right: auto !important;
}

.product-list__item .product-tile .badge-container .badge {
    width: 38px;
    height: 38px;
    font-size: $product-badge__font-size;
}
