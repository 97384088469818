.compare-save {
        border-color: black;
        border-style: solid;
        border-width: 1px;
        color: black;
        display: inline-block;
        min-width: 7.5rem;
        text-align: center;
        text-decoration: none;
        margin-bottom: 10px;
        font-size: 0.875rem;
        min-width: 6.25rem;
        padding: 0.25rem 0.875rem;
}
.block-sidebar .qty {
        display: none;
}
.block-content-sidebar {
        padding-top: 10px;

        #compare-items {
                list-style-type: none;
                margin-left: 0;
                margin-bottom: 0;
        }
        .actions-toolbar {
                display: flex;
                justify-content: space-between;
                padding-top: 15px;
                border-top: 0px solid black;
                a {
                        color: black;
                        text-align: center;
                        font-size: 11px;
                        letter-spacing: 1px;
                        padding: 5px 10px;
                        border: 2px solid black;
                        text-transform: uppercase;
                        text-decoration: none;
                }
        }
}

#compare-items {
        .product-item-sidebar {
                border-top: 0px solid black;
                padding: 5px 0;
                font-size: 12px;
                line-height: 1;
                display: flex;
                justify-content: space-between;
        }
}

.customer-customer-index .block-sidebar,
.customer-account-index .block-sidebar {
        display: none;
}

@media (max-width: 1250px) {
        .block-content-sidebar {
                .actions-toolbar a {
                        padding: 5px;
                        letter-spacing: 0;
                        font-size: 10px;
                }
                #compare-clear-all :before,
                #compare-all :before {
                        width: 12px !important;
                        height: 12px !important;
                }
        }
}

.navigation-bar__group .compare {
        a {
                position: relative;
        }
        .compare-counter {
                position: absolute !important;
               // border: 2px solid #000 !important;
                width: 21px !important;
                height: 21px !important;
                right: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 12px;
                //color: black !important;
                top: 0px;
              //  background-color: white;
                border-radius: 50%;
                text-align: center;
        }
}

.navigation-bar-scrolled .compare {
        .compare-counter {
                right: -10px;
        }
}
@media (max-width: 960px) {
        .block-sidebar {
                display: none;
        }

        .site-header__mobile-links {
                .compare > a {
                        position: relative;
                }
                .compare > a:before {
                        width: 26px ;
                        height: 26px ;
                        top: 0px !important;
                }
                .compare .counter {
                        position: absolute !important;
                      //  border: 2px solid #000 !important;
                        width: 16px !important;
                        height: 16px !important;
                        right: -8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 600;
                        font-size: 12px;
                     //   color: black !important;
                        top: 0px;
                      //@extend  background-color: white;
                        border-radius: 50%;
                        text-align: center;
                }
        }
}
