.modal-custom,
.modal-popup,
.modal-slide {
    opacity: 0;
    padding: $spacing-unit-base--small;
    position: absolute;
    right: 100%;
    top: 0;
    transition: opacity $transition-time, right 0ms $transition-time, visibility 0ms $transition-time;
    visibility: hidden;
    width: 100%;
    z-index: 1000;

    @include media-query(medium-and-up) {
        padding: $spacing-unit-base;
    }

    @include media-query(large-and-up) {
        padding: 2 * $spacing-unit-base;
    }

    &._show {
        opacity: 1;
        right: 0;
        transition: opacity $transition-time, right 0ms, visibility 0ms;
        visibility: visible;
    }

    .modal-inner-wrap {
        background-color: $color-background;
        border-radius: rems(2px);
        box-shadow: 0.5rem 1rem 3rem 1rem rgba(0, 0, 0, 0.2);
        margin: 0 auto;
        max-width: 640px;
        padding: $spacing-unit-base--small;

        @include media-query(medium-and-up) {
            padding: $spacing-unit-base;
        }
    }
}


.modal-popup {
    .modal-title {
        margin-bottom: 0;
        min-height: 1em;
        word-wrap: break-word;
    }

    .action-close {
        padding: $spacing-unit-base--small;
    }
}


.modal-slide {
    .action-close {
        padding: $spacing-unit-base--small;
    }
}


.modals-overlay {
    background-color: $color-background;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity $transition-time;

    ._show + & {
        opacity: 0.6;   // Increase the opacity when the modal is shown
    }
}