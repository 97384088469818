$searchInputHeight: 43px;
$twoColumnsDisplayBreakpoint: 630px;
$threeColumnsDisplayBreakpoint: 1000px;
$fourColumnsDisplayBreakpoint: 1229px;

.stockists-search {
    text-align: center;
    margin-bottom: 50px;
    position: relative;

    .stockists-search__heading {
        @include heading1;
        margin-bottom: 50px;
    }

    .stockists-search__text-input {
        @include functional-text-black;
        width: 100%;
        max-width: 408px;
        height: $searchInputHeight;
        border: 1px solid #000000;
        padding: 0 20px;
        margin-right: 14px;
        background-color: #FFFFFF;
        margin-bottom: 10px;

        @media (min-width: $twoColumnsDisplayBreakpoint) {
            margin-bottom: 0;
        }
    }

    .stockists-search__button {
        @include functional-text-white;
        background-color: $color-shop-accent;
        width: 195px;
        height: $searchInputHeight;
    }

    .stockists-search__loader {
        display: table;
        position: absolute;
        bottom: -435px;
        z-index: 1000;
        width: 100%;
        height: 385px;
        background-color: rgba(255, 255, 255, 0.9);

        .loading {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .stockists-wait-for-page__loader {
        display: table;
        position: absolute;
        bottom: -80px;
        z-index: 1000;
        width: 100%;
        height: 124px;
        background-color: rgba(255, 255, 255, 0.9);

        @media (max-width: $twoColumnsDisplayBreakpoint) {
            height: 180px;
        }

        .loading {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.stockists__map {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    height: 385px !important; /* !important because the height of the map is hard coded in vendor/aligent/stockists/view/frontend/templates/index/map.phtml */
}

.stockists-directory-toolbar {
    display: none; /* Unable to use XML to hide this element, as there is JS that is relying on it being there */
}
.stockists-directory-list { /* ul */
    font-size: 0; /* Hack to remove any browser added white space between the sibling elements */
    list-style: none;
    margin: 0 auto;
    padding: 50px 15px 15px;
    max-width: 1286px;
    background-color: $color-light-grey;

    opacity: 0;
    transition: opacity 250ms ease-in;

    & > div {
        font-size: medium; /* IE Fix */
        font-size: initial; /* Reset font size back to normal after the font-size: 0 hack in the parent container */
        text-align: center;
        min-width: 300px;
        vertical-align: top;
        display: inline-block;

        .directory-item__distance {
            @include functional-text-white;

            background-color: #000000;
            width: 100px;
            text-align: center;
            margin: -32px auto 20px;
        }

        .directory-item__heading {
            @include large-text;
        }

        .stockists-directory__item { /* li */
            display: inline-block;
            background-color: #FFFFFF;
            width: 100%;
            height: 100%;
            text-align: left;

            .directory-item {
                padding: 20px;

                .directory-item__phone {
                    padding-left: 32px;
                    padding-bottom: 20px;
                    line-height: 26px; /* This is to line up the actual text of the number with the icon */
                    text-align: left;
                    text-decoration: none;

                    display: flex;
                    flex: 0 0 auto;
                    flex-direction: row;
                    align-items: flex-start;

                    &:before {
                        @include icon-font;
                        content: '\e551';
                        margin-left: -32px;
                        margin-right: 4px;
                        vertical-align: top;
                    }
                }

                address {
                    font-style: normal;
                    padding-left: 32px;
                    vertical-align: top;
                    line-height: 20px; /* This is to reduce the gap that will appear between the top line and following lines due to the icon in :before */
                    text-align: left;

                    &:before {
                        @include icon-font;
                        content: '\e55f';
                        margin-left: -32px;
                        vertical-align: top;
                    }

                }

                .show-on-map {
                    @include small-text-black;
                    padding-left: 32px;
                    text-decoration: none;
                    display: block;

                    .show-on-map-text {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .directory-item__opening-times-container {
        @include small-text-black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        .directory-item__opening-times-trigger {
            cursor: pointer;
            position: relative;
            text-align: left;
            padding: 5px 8px;

            &:after {
                position: absolute;
                right: 8px;
            }
            &.closed:after {
                content: "+";
            }
            &.open:after {
                content: "-";
            }
        }

        .directory-item__opening-times {
            padding: 3px 12px;
            text-align: left;
            list-style: none;
            margin: 0;
        }
    }

    /**
     * Set up the layout of the store location search results for the 1, 2, 3 and 4 column options
     */

    /* 1 column layout */
    & > div {
        width: 100%;
        padding-left: 15px;
    }

    & > div + div {
        margin-top: 25px;
    }

    /* 2 column layout */
    @media (min-width: $twoColumnsDisplayBreakpoint) {

        & > div {
            width: 50%;
        }
        /* This display will show 2 columns, so the first 2 results will be at the top, and therefore don't need the extra spacing added */
        & > div:first-child, & > div:nth-child(2) {
            margin-top: 5px;
        }

        /* Even items will be those in the right column */
        & > div:nth-child(even) {
            padding-left: 15px;
        }
    }

    /* 3 column layout */
    @media (min-width: $threeColumnsDisplayBreakpoint) {

        & > div {
            width: 33.33%;
            padding-left: 15px;
        }
        /* This display will show 2 columns, so the first 3 results will be at the top, and therefore don't need the extra spacing added */
        & > div:first-child, & > div:nth-child(2), & > div:nth-child(3) {
            margin-top: 5px;
        }

        /* Every 3rd item will be the outside right column, so '+ 1' will be the first column */
        & > div:nth-child(3n + 1) {
            padding-left: 0;
        }
    }

    /* 4 column layout */
    @media (min-width: $fourColumnsDisplayBreakpoint) {

        & > div {
            width: 25%;
            padding-left: 15px;
        }
        /* This display will show 2 columns, so the first 4 results will be at the top, and therefore don't need the extra spacing added */
        & > div:first-child, & > div:nth-child(2), & > div:nth-child(3), & > div:nth-child(4) {
            margin-top: 5px;
        }

        /* Every 4th item will be the outside right column, so '+ 1' will be the first column */
        & > div:nth-child(3n + 1) { //Need to reset the padding from the 3 column layout
            padding-left: 15px;
        }
        & > div:nth-child(4n + 1) {
            padding-left: 0;
        }
    }

    /**
     * End of column layout setup
     */
}

.stockists-directory-list--ready {
    opacity: 1;
}

/* Styling for the location bubble */
.stockists__map {
    .location-info-window {
        .get-directions {
            margin-top: 10px;
            display: block;
            padding-left: 0;
        }
    }
}
.BTSDiv {
  line-height: 1.5rem;
  margin: 5px 0;
  display: none !important;
}

.simply-BTS {
  font-family: inherit;
  color: #000000;
  font-size: 0.875rem;
  width: auto;
  margin-left: 30px;
  margin-top: 3px;
  text-align: left;
  text-decoration: underline;
}

.BTSDiv:before {
  background-size: contain;
  width: 25px;
  height: 24px;
  content: "";
  background-repeat: no-repeat;
  margin: auto;
  display: block;
  top: 0;
  left: 0px;
  position: absolute;
}
