/*------------------------------------*\
    #NAVIGATION BAR
\*------------------------------------*/

/**
 * Generic navigation bar
 **/

/*nav*/
.navigation-bar {
    border-top: 1px solid $color-navigation-border;
    border-bottom: 1px solid $color-navigation-border;
    color: grey;
    background-color: $color-navigation-background;

    .primary-navigation {
        li {
            @include functional-text($color-navigation-text);

            &:hover > a {
                color: #000000;
            }

            a {
                text-decoration: none;
            }
        }
    }
}

.level0:not(.standard-menu__extended) > .submenu,
.standard-menu__extended .level1 {
    a:hover {
        color: $color-navigation-text-hover;
    }
}

.navigation-bar--primary {
    display: none;
    padding-right: $spacing-unit-base--small;

    @include media-query(medium-and-up) {
        display: block;
    }
}


// An (optional) wrapper to constrain the width of the navigation bar items
/*div*/
.navigation-bar__wrapper {
    @extend %wrapper;
}


/*button*/
.navigation-bar__toggle {
    @extend %u-display-inline-block;
    @extend %u-hidden-medium-and-up;

    margin: 0;
}

/*div, form, ul*/
.navigation-bar__group {}

.navigation-bar__group--secondary {}


/* Sticky Nav */
.navigation-bar-scrolled {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
    background-color: #FFF;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}
.navigation-bar-scrolled .site-header__logo-container {
    max-width: 220px !important;
    display: inline-block;
    height: 30px;
    padding: 10px 0 0 10px;
}
.navigation-bar-scrolled .site-header__logo-container img {
    max-height: 30px;
    width: auto;
}
.navigation-bar-scrolled .navigation-bar__wrapper {
    display: block;
    margin: 0;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.navigation-bar-scrolled #primary-navigation {
    width: auto !important;
    margin-right: -5px;
    background-color: #FFF !important;
}
.navigation-bar-scrolled .primary-navigation li {
    color: #000;
}

.navigation-bar-scrolled #primary-navigation li.level0:last-child > a span {
    padding-right: 0;
}

.navigation-bar-scrolled .header-icons-wrapper {
    display: inline-block !important;
    float: right;
    color: #000000;
}

.navigation-bar-scrolled .account-navigation {
    max-width: none;
    width: auto;
    top: 0;
}
.navigation-bar-scrolled .account-navigation__item > .showcart:before {
    font-size: 32px;
}

.navigation-bar-scrolled .wishlist a {

}

.navigation-bar-scrolled .wishlist a:before {
    margin: 1px 0 0 0;
}

.navigation-bar-scrolled .search-suggest-icon-wrapper {
    font-family: "Material Icons";
}
.navigation-bar-scrolled .search-suggest-icon-wrapper .search-suggest-icon:before {
    font-weight: bold;
    font-size: 30px;
    content: '\e8b6';
}

// Hide search bar until icon is clicked.
.navigation-bar-scrolled .fh-search-suggest-wrapper {
    position: absolute;
    margin-top: 49px;
    background-color: initial;
    padding: 0px 30px 10px 10px;
    margin-left: -29%;
    width: 32%;
    display: none;
}

.navigation-bar-scrolled .fh-search-suggest-wrapper .minisearch__control--submit {
    position: absolute;
    right: 15px;
    top: 10px;
}

.navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-search {
    background: white;
}
.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group > li {
    vertical-align: middle;
    width: auto !important;
    padding-top: 0;
    margin: 0 5px;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.wishlist {
    padding-left: 0;
}
.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.minicart {
    border-left: 1px solid #b6b6b6;
    margin-left: 5px;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.wishlist a {
    line-height: 2em;
}

.navigation-bar-scrolled .header-icons-wrapper ul.navigation-bar__group li.minicart a:before {
    display: inline-block;
    margin: 0;
}

.navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-results {
    right: 0 !important;
}

.navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-results-3products .suggest_products .suggest_product {
    width: 32%;
}
.navigation-bar-scrolled #js-aligent_fredhopper-autocomplete-results h3 {
    margin-bottom: 0.5rem;
}
.navigation-bar-scrolled .stockistsMenuItem {
    display: none !important;
}

.navigation-bar-scrolled .navigation-bar__group--account li a.minicart__summary .minicart__label {
    vertical-align: top;
    display: inline-block;
    padding-top: 10px;
}

.navigation-bar-scrolled .minicart__summary .minicart__count {
    right: 52px;
    top: 5px;
}
.navigation-bar-scrolled .navigation-bar__group--account .wishlist .counter {
    right: 5px;
    top: 4px;
}
/* END Sticky Nav */
