@mixin form-input {
  border: 1px solid currentColor; // Use pixels to make sure border always displays
  height: rems(44px);
  padding: 0 rems(16px);
}

@mixin contact-form-placeholder {
  text-transform: uppercase;
  color: #000;
}
