#js-aligent_fredhopper-autocomplete-results {
  right: initial !important;
  border: 1px solid #CCC;
  padding: 20px !important;
}

#js-aligent_fredhopper-autocomplete-results * {
  font-family: $font-family-base;
}

#js-aligent_fredhopper-autocomplete-wrapper {
  display: inline-block;
  width: 300px;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 0 30px;
  position: relative;
  vertical-align: middle;
  @media (min-width: 980px){
    width: 515px;
  } 
}

#js-aligent_fredhopper-autocomplete-search {
  border: 1px solid #000;
  padding: 0 0 0 20px;
}

#js-aligent_fredhopper-autocomplete-wrapper .minisearch__control--submit {
  position: absolute;
  right: 0px;
  top: 9px;
}

#js-aligent_fredhopper-autocomplete-results-1keywords .suggest_keywords,
#js-aligent_fredhopper-autocomplete-results-2categories .suggest-categories {
  list-style: none;
  text-align: left;
  margin-left: 0;
}

#js-aligent_fredhopper-autocomplete-results-1keywords .suggest_keywords .suggest_keyword,
#js-aligent_fredhopper-autocomplete-results-2categories .suggest-categories .suggest-category {
  padding: 10px 10px;
  border-bottom: 1px solid #CCC;
  text-transform: capitalize;
}

#js-aligent_fredhopper-autocomplete-results-1keywords .suggest_keywords .suggest_keyword a
#js-aligent_fredhopper-autocomplete-results-2categories .suggest-categories .suggest-category a {
  text-decoration: none;
}

#js-aligent_fredhopper-autocomplete-results-3products .suggest_products {
  margin-left: 0;
  margin-bottom: 0;
}
#js-aligent_fredhopper-autocomplete-results-3products .suggest_products .suggest_product {
  display: inline-block;
  width: 30%;
  vertical-align: top;
}

#js-aligent_fredhopper-autocomplete-results-3products .suggest_products .suggest_product .suggest_product_price {
  font-weight: bold;
  display: inline-block;
}

#js-aligent_fredhopper-autocomplete-results-3products .suggest_products .suggest_product .suggest_product_special_price {
  color: #FF6B39;
  font-weight: bold;
  display: inline-block;
}

#js-aligent_fredhopper-autocomplete-results-3products .suggest_products .suggest_product .suggest_product_old_price {
  text-decoration: line-through;
  display: inline-block;
}

#js-aligent_fredhopper-autocomplete-results-3products .suggest_products .suggest_product a {
  text-decoration: none;
}

#js-aligent_fredhopper-autocomplete-results .suggest_heading {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}

#js-aligent_fredhopper-autocomplete-results-3products .suggest_product_name,
#js-aligent_fredhopper-autocomplete-results-3products .suggest_product_name * {
  text-transform: capitalize;
}

#js-aligent_fredhopper-autocomplete-wrapper h3 {
  margin-bottom: 0.5rem;
}

#js-aligent_fredhopper-autocomplete-results-3products .suggest_product_link:hover,
#js-aligent_fredhopper-autocomplete-results-3products .suggest_product_link:hover .suggest_product_special_price,
#js-aligent_fredhopper-autocomplete-results-3products .suggest_product_link:hover .suggest_product_price {
  text-decoration: underline !important;
}

/* Hide Search Terms */
.catalogsearch-result-index .message.notice dl.block {
  display: none !important;
}

/* 1218 - Search Styling Changes */
.search.results .toolbar {
  padding: 0;
  margin-bottom: 3rem;
}
.toolbar_fh_response .fh_search_term {
  text-align: left;
  padding: 0;
  font-size: 24px;
  padding-top: 0 !important;
}
.toolbar_fh_response .fh_search_term .fh_searched_word,
.toolbar_fh_response .fh_search_original, .toolbar_fh_response .fh_suggested_terms {
  font-weight: bold;
}
.toolbar_fh_response .fh_suggested_terms_block {
  font-size: 18px;
  margin-left: 10px;
}

/* Mobile Search Suggest - MMI-99 */
@media (max-width: 960px) {
  .site-header__banner .siteHeaderBannerInnerContent > * {
    display: none;
  }
  .site-header__banner .siteHeaderBannerInnerContent #js-aligent_fredhopper-autocomplete-wrapper  {
    display: block;
    z-index: 2;
  }
  #js-aligent_fredhopper-autocomplete-wrapper {
    width: 100%;
  }
  #js-aligent_fredhopper-autocomplete-results {
    left: 0;
    width: 100% !important;
    top: 100px !important;
  }
  .site-header__mobile {
    padding-top: 0;
  }
  .search-suggest-menu-open {
    transform: translateX(0) !important;
  }
}