.react-plp-product-filter.react-plp-product-filter--colour-swatch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .react-plp-product-filter__aspect {
    width: auto;
  }

  .filter-control__button--colour-swatch {
    width: 2rem;
    height: 2rem;
    margin: 0.25rem;

    display: flex;
    overflow: hidden;

    border: 1px solid rgba(#000000, 0.25);

    &:hover,
    &:focus {
      border-width: 2px;
      border-color: #000000;
      // fix for background images moving inside swatch hover due to border changes. 
      background-position: -1px -1px !important;
    }
  }

  .filter-control__button--colour-swatch-active {
    border-width: 2px;
    border-color: #000000;
  }

  .filter-control__button--colour-swatch-circular {
    border-radius: 1000px;
  }

  .filter-control__button-label {
    display: none;
  }

}
