
.checkout-app-form {
  .react-autosuggest__container {
    position: relative;

    .postcode-autosuggest-container-inputwrap {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.react-autosuggest__suggestions-container {
  display: none;
  padding: 1rem 0;
  border: 1px solid black;
  margin-top: -1px;
  position: absolute;
  background-color: white;
  width: calc(100% - 2rem);
  max-height: 300px;
  overflow-y: auto;
  top: 70px;
  left: 1rem;
  right: 1rem;
  z-index: 1;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  margin: 2px 0 0;
  border-radius: 5px;
}

.react-autosuggest__suggestion {
  padding: 7px 15px;
  font-size: 0.9rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $address-validation-hover;
  cursor: pointer;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.react-autosuggest__container--open .input-container input { border-radius: 5px 5px 0 0; }
