/**
 * Styling for checkout messages.
 * These are different to normal site messages as they don't use the same mechanic, therefore don't automatically
 * get displayed in the same way that the rest of the site messages do
 */

.checkout-cart-index {
  .messages {
    max-width: $max-width;
    margin: 0 auto;
    padding: 5px 0;

    .message-error {
      color: red;
      font-weight: 600;
    }

    .message-success {
      color: green;
    }
  }

  .cart-table__message {
    &.error {
      color: red;
      font-weight: 600;
      text-transform: none;
    }
  }
}

div[data-ui-id="checkout-cart-validationmessages-message-success"] {
  display: none;
}