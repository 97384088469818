@media (max-width: 960px) {
    .popup_content .popup-close button:before {
        font-size: 16px;
    }
}

@media (max-width: 640px) {
    .popup_content {
        top: 50% !important;
    }
}

/* MMI-1112 BV position fix */
[data-bv-show="rating_summary"] .bv_main_container .bv_button_buttonMinimalist {
    display: none !important;
}

[data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
    margin-right: -1em !important;
}

.product-info__main .product-info__title {
    margin-top: 0px !important;
    padding-top: 12px !important;
}

/* Fix for layout caching issue */
.cms-page-view .page-layout--two-col .page-layout__item--primary,
.cms-home .page-layout--two-col .page-layout__item--primary {
    width: 100% !important;
}

/* Fix for banner sale issue. */
@media (max-width: 960px) {
    .catalog-product-view .product-info .sale-banner-exists {
        position: relative;
        top: 0 !important;
    }
    .catalog-product-view .product-img-inc-banner {
        margin-top: -100px;
    }
}

/* Fix for specific page alignment issues */
.cms-fusion-rewards .full-site-banner .wrapper--gutter,
.cms-privacy-policy .wrapper--gutter,
.contact-index-index .wrapper--gutter {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
    max-width: 82.5rem !important;
    padding-top: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
/* End Gutter Fix */
.campaign-site-banner {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 960px) {
    .home-main-panels {
        margin-top: 0px !important;
    }
}

.checkout-cart-index .main-content {
    margin-top: 10px;
}

.stockists-index-index .stockists-search__heading {
    display: block !important;
}

/* Fix for BIS button to hide initially */
.product-notify-me-wrapper .hidden-notify {
    display: none;
}

/* Fix for sale badge */
.wrapper--gutter {
    position: relative;
}
@media (max-width: 750px) {
    .product-info .badge-container {
        margin-left: 0px !important;
    }
}
@media (max-width: 960px) {
    .product-info .badge-container {
        top: 10px !important;
    }
}

/* Mobile Fix for Insta feed Magenest */
@media (max-width: 767px) {
    .widget-detail-outer-container {
        padding-top: 8vh;
    }
}
.block-photo-slider .photos-slick li.instagram-photo > a .text img {
    display: none !important;
}

@media (max-width: 767px) {
    .widget-timeline-detail .widget-text-product img {
        max-width: 100% !important;
        border: 0px !important;
    }
}
