/**
 * @example
    <li class="product-list__item">
        <div class="product-tile product-tile--new-arrival product-tile--best-seller" data-container="product-grid">
            <div class="product-tile__badges">
                <div class="product-tile__badge product-tile__badge--is-new product-tile__badge--circle">New</div>
            </div>
            <a href="https://local.williamsshoes.com.au/lorikeet-black-patent-leather.html" class="product-tile__link  product-tile__link--image"
                tabindex="-1">
                <img class="product-tile__image" src="https://local.williamsshoes.com.au/static/version1542070169/frontend/Munro/williams/en_AU/Magento_Catalog/images/product/placeholder/small_image.jpg"
                    alt="LORIKEET BLACK PATENT LEATHER">
            </a>
            <h2 class="product-tile__title">
                <span class="product-title__brand">DIANA FERRARI</span>
                <a class="product-tile__link  product-tile__link--name" href="https://local.williamsshoes.com.au/lorikeet-black-patent-leather.html">
                    lorikeet black patent leather
                </a>
            </h2>
            <div class="price-box price-final_price" data-role="priceBox" data-product-id="128576">
                <span class="price-container ">
                    <span id="product-price-128576" data-price-amount="149.95" data-price-type="finalPrice" class="price-wrapper ">
                        <span class="price">$149.95</span>
                    </span>
                </span>
            </div>
            <!-- Prevent swatches from showing up, as they're only to be displayed in the quickview -->

            <div class="product-tile__attributes">
                <div class="product-tile__attribute product-tile__attribute--new-arrival">
                    <div class="product-tile__attribute-text">
                        NEW ARRIVAL </div>
                </div>
                <div class="product-tile__attribute product-tile__attribute--best-seller">
                    <div class="product-tile__attribute-text">
                        BEST SELLER </div>
                </div>
            </div>

            <div data-role="add-to-links" class="product-tile__actions  product-tile__actions--secondary">
                <button class="product-tile__action  product-tile__action--add-to-wishlist" title="Save to Wish List"
                    aria-label="Save to Wish List" data-post="{&quot;action&quot;:&quot;https:\/\/local.williamsshoes.com.au\/wishlist\/index\/add\/&quot;,&quot;data&quot;:{&quot;product&quot;:&quot;128576&quot;,&quot;uenc&quot;:&quot;aHR0cHM6Ly9sb2NhbC53aWxsaWFtc3Nob2VzLmNvbS5hdS93b21lbi1zLXNob2VzLmh0bWw_cD0xJnByaWNlPTkwLTI0MSZzb3J0X2J5PXVuZGVmaW5lZA,,&quot;}}"
                    data-action="add-to-wishlist" type="button">
                  <span class="product-tile__action-label">
                    Save
                  </span>
                  <span class="product-tile__action-label product-tile__action-label--on-hover">
                    Add to wishlist
                  </span>
                </button>
                <button id="128576" class="product-tile__action  product-tile__action--quick-view" title="Quick View"
                    aria-label="Quick View" data-action="quick-view" type="button">
                  <span class="product-tile__action-label">
                    Quick View
                  </span>
                  <span class="product-tile__action-label product-tile__action-label--on-hover">
                    Quick View
                  </span>
                </button>
            </div>
        </div>
    </li>

    <li class="product-list__item">
        <div class="product-tile product-tile--new-arrival product-tile--best-seller" data-container="product-grid">
            <div class="product-tile__badges">
                <div class="product-tile__badge product-tile__badge--on-sale product-tile__badge--circle">Sale</div>
            </div>
            <a href="https://local.williamsshoes.com.au/lorikeet-black-patent-leather.html" class="product-tile__link  product-tile__link--image"
                tabindex="-1">
                <img class="product-tile__image" src="https://local.williamsshoes.com.au/static/version1542070169/frontend/Munro/williams/en_AU/Magento_Catalog/images/product/placeholder/small_image.jpg"
                    alt="LORIKEET BLACK PATENT LEATHER">
            </a>
            <h2 class="product-tile__title">
                <span class="product-title__brand">DIANA FERRARI</span>
                <a class="product-tile__link  product-tile__link--name" href="https://local.williamsshoes.com.au/lorikeet-black-patent-leather.html">
                    lorikeet black patent leather
                </a>
            </h2>
            <div class="price-box price-final_price" data-role="priceBox" data-product-id="128576">
                <span class="price-container ">
                    <span id="product-price-128576" data-price-amount="149.95" data-price-type="finalPrice" class="price-wrapper ">
                        <span class="price">$149.95</span>
                    </span>
                </span>
            </div>
            <!-- Prevent swatches from showing up, as they're only to be displayed in the quickview -->

            <div class="product-tile__attributes">
                <div class="product-tile__attribute product-tile__attribute--new-arrival">
                    <div class="product-tile__attribute-text">
                        NEW ARRIVAL </div>
                </div>
                <div class="product-tile__attribute product-tile__attribute--best-seller">
                    <div class="product-tile__attribute-text">
                        BEST SELLER </div>
                </div>
            </div>

            <div data-role="add-to-links" class="product-tile__actions  product-tile__actions--secondary">
                <button class="product-tile__action  product-tile__action--add-to-wishlist" title="Save to Wish List"
                    aria-label="Save to Wish List" data-post="{&quot;action&quot;:&quot;https:\/\/local.williamsshoes.com.au\/wishlist\/index\/add\/&quot;,&quot;data&quot;:{&quot;product&quot;:&quot;128576&quot;,&quot;uenc&quot;:&quot;aHR0cHM6Ly9sb2NhbC53aWxsaWFtc3Nob2VzLmNvbS5hdS93b21lbi1zLXNob2VzLmh0bWw_cD0xJnByaWNlPTkwLTI0MSZzb3J0X2J5PXVuZGVmaW5lZA,,&quot;}}"
                    data-action="add-to-wishlist" type="button">
                  <span class="product-tile__action-label">
                    Save
                  </span>
                  <span class="product-tile__action-label product-tile__action-label--on-hover">
                    Add to wishlist
                  </span>
                </button>
                <button id="128576" class="product-tile__action  product-tile__action--quick-view" title="Quick View"
                    aria-label="Quick View" data-action="quick-view" type="button">
                  <span class="product-tile__action-label">
                    Quick View
                  </span>
                  <span class="product-tile__action-label product-tile__action-label--on-hover">
                    Quick View
                  </span>
                </button>
            </div>
        </div>
    </li>
 */
    .product-tile__actions {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .product-tile__actions::after {
      content: none;
    }

    .product-tile__action {
      display: flex;
      align-items: center;

    }


    .product-tile__action-label {
      margin-left: 0.25rem;
      text-transform: initial;
    }

    .product-tile__action[disabled] {
      color: $color-dark-grey;
    }

    .product-tile__action:focus,
    .product-tile__action:hover {
      background-color: transparent;
    }

    .product-tile__action:not([disabled]):hover {
      color: $color-shop-accent;
    }

    .product-tile__action-label--on-hover {
      display: none;
    }

    .product-tile__action:not([disabled]):hover .product-tile__action-label {
      display: none;
    }

    .product-tile__action:not([disabled]):hover .product-tile__action-label--on-hover {
      display: block;
    }

    .product-tile__actions--secondary .product-tile__action--add-to-wishlist:before {
      @include icomoon(wishlist);
      font-size: 26px;
    }

    .product-list__item .product-tile .product-tile__actions--secondary:after {
      display: none;
    }

    .product-tile__attributes {
      .product-tile__attribute {
        display: inline-block;
      }
    }


    .product-tile__badges {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 1;
    }

    .product-tile__badge {
      width: 38px;
      height: 38px;
      display: flex;
      font-size: $product-badge__font-size;
      justify-content: center;
      align-items: center;
      text-transform: $product-badge__text-transform;
    }

    .product-tile__badge--circle {
      border-radius: 1000px;
    }

    .product-tile__badge--is-new {
      background: $product-badge__background--new;
      border: $product-badge__border--new;
      color: $product-badge__color--new;
    }

    .product-tile__badge--on-sale {
      background: $product-badge__background--sale;
      border: $product-badge__border--sale;
      color: $product-badge__color--sale;
    }

