.checkout-app-tab-section {
  width: 33.333%;
}

.checkout-app-tab-section__content {
  padding: 2rem 1.3rem;
}

.checkout-app__shoppingbag-section--with-account {
  width: 66.666%;
}

.checkout-app__shoppingbag-section .checkout-app-tab-section__content {
  flex-direction: row;
}
