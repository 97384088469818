/*------------------------------------*\
    #PRODUCT-INFO
\*------------------------------------*/

/*div*/
.product-info {
    padding-top: 20px;
    @include media-query(large-and-up) {
        padding-top: 60px;
    }

    @include clear();
}


/*div*/
.product-info__main {
    @extend %u-1-of-2-medium-and-up;
    @extend %u-2-of-5-large-and-up;
    padding-top: 40px;
    @include media-query(large-and-up) {
        padding-top: 0;
    }

    @include media-query(medium-and-up) {
        float: right;
        padding-left: $spacing-unit-base--small;
    }

    .product-info__title {
        @include product-title;
        margin: 0 55px 0 0; //Prevent the heading text from displaying under the sale badge
        padding: 0;
        text-align: left;
    }

    .product-info__attributes {
        position: absolute;
        top: 10px;
        @include media-query(large-and-up) {
            top: -53px;
        }
    }
    .product-info__attribute {
        @include product-tag;
    }

    .add-to-cart__submit {
        @include add-to-bag-button;
    }

    .add-to-cart__out_of_stock {
        @include out-of-stock-button;
    }

    .product-addto-links {
        text-align: center;
        padding-top: 27px;
        padding-bottom: 40px;
        .towishlist {
            @include save-for-later-button;
            text-decoration: none;
        }
    }

    .product-info__brand {
        display: $product-list-brand-display;
    }
}


/*div*/
.product-info__media {
    @extend %u-1-of-2-medium-and-up;
    @extend %u-3-of-5-large-and-up;

    @include media-query(medium-and-up) {
        float: left;
        padding-right: $spacing-unit-base--small;
    }

    // The following is styling that will place the initial image in the same place that it will be displayed when the gallery finishes loading
    .gallery-placeholder {
        position: relative;
        text-align: center;

        .placeholder-image {
            margin: 50px auto;
        }
    }
}


/*div*/
.product-info__details {
    clear: both;
}


/* PDP Padding/Margin Fixes - #1153 */
@media screen and (min-width: 1024px) {
    .product-info {
        padding-top: 30px;
    }
    .product-info__main .product-info__attributes {
        top: -28px;
    }
}

.product-info__main .product-title__brand, .product-info__main .product-info__title {
    font-size: 20px;
}

.product-info__main .related-products {
    padding-top: 10px;
}

.product-info__main .product-info__add-to-cart {
    padding-top: 0;
}

.product-info__main .product-addto-links {
    padding-top: 15px;
    padding-bottom: 15px;
}

.additional-attributes-wrapper {
    padding-top: 10px;
}

#product-attribute-specs-table td, #product-attribute-specs-table th {
    padding: 3px 10px;
}

#product-attribute-specs-table {
    margin-bottom: 1rem;
}

#product_addtocart_form .swatch-opt {
    margin: 10px 0;
}

.product-info__main .product__stock-message {
    margin-top: -16px;
    min-height: 36px;
}

.product__stock-message {
    padding: 5px 0;
}

/* End #1153 Fixes */
