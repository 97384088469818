/**
 * Styling for the Checkout
 */


/* End Of Coupon Block */
/* Shipping Information */
.shipping-information {
  .action { /* button */
    @include widget-button;
  }
}


.registered-user-login-buttons {
  @include media-query(small-and-up) {
    display: flex;
    align-items: center;
  }
}


/* Fix for new address popup scroll */
.checkout-index-index .new-address-popup .modal-inner-wrap {
  height: 100% !important;
  overflow-y: auto !important;
}