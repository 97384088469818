/*------------------------------------*\
    #PAGE-LAYOUT
\*------------------------------------*/

/*div, ol, ul*/
.page-layout {
    @extend %clearfix;
    @extend %layout;
}

.page-layout--rev {
    @extend %layout--rev;
}


/*div, li*/
.page-layout__item {
    @extend %layout__item;
}

.page-layout__item--primary {
    .page-layout--two-col & {
        @extend %u-3-of-4-medium-and-up;
        @extend %u-4-of-5-large-and-up;

        /**
         * Float the primary layout item to the right, so the previous item
         * (secondary) the next item (tertiary) will be positioned to the left
         * on medium and up devices.
         **/
        float: right;
    }
}

.page-layout__item--secondary {
    .page-layout--two-col & {
        @extend %u-1-of-4-medium-and-up;
        @extend %u-1-of-5-large-and-up;
    }
}

.page-layout__item--tertiary {
    .page-layout--two-col & {
        @extend %u-1-of-4-medium-and-up;
        @extend %u-1-of-5-large-and-up;
    }
}
