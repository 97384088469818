.checkout-delivery-timeframe {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}
.delivery-timeframe-title {
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 10px;
    font-family: proxima-nova, Helvetica, Arial, sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
}
.delivery-timeframe-block {
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1em;
    font-family: proxima-nova, Helvetica, Arial, sans-serif;
    letter-spacing: 0.2px;
}
.delivery-timeframe-block:before {
    font-family: "FontAwesome";
    font-size: 14px;
    content: "\f105";
    color: red;
    font-weight: 900;
    padding-right: 5px;
}
.delivery-timeframe-info {
    display: inline-block;
    font-size: 11px;
    color: #898989;
    margin-bottom: 10px;
    font-family: proxima-nova, Helvetica, Arial, sans-serif;
    letter-spacing: 0.2px;
}
.delivery-timeframe-cta {
    margin-bottom: 0px;
    font-family: proxima-nova, Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: -0.1px;
    text-decoration: underline;
    color: #6b6b6b;
}
@media (max-width: 400px) {
    .checkout-delivery-timeframe {
        padding: 10px;
    }
}
