/**
 * Styling for the registration modal content of the login/registration modal
 */

.modal-content-register-form {
  .form__field-group {
    font-size: 0;

    .form__field {
      font-size: 16px;
      width: 100%;
      display: inline-block;

      @media (min-width: 550px) {
        width: calc(50% - 5px);
      }
    }
  }

  .form__field {
    text-align: left;
    margin-bottom: 0;
    padding: 0;


    &:last-of-type:not(:first-of-type) {
      padding-top: 25px;

      @media (min-width: 550px) {
        padding: 0;
        margin-left: 10px;
      }
    }

    .form__label {
      line-height: 1em;
      padding-bottom: 2px
    }
  }

  .note {
    padding: 13px 0 20px;
  }

  .actions-toolbar {
    //padding-top: 0;
  }

  .create-account__item--personal-information {
    @extend %u-1-of-1;
  }

  .form__field--email {
    @extend %u-1-of-1;
    padding: 25px 0;
  }

  .form__field--newsletter {
    padding: 15px 0 0 20px;

    .form__label {
      text-transform: none;
    }
  }

  .create-account__item {
    @extend %u-1-of-1;
  }
}
