/*------------------------------------*\
    #RESONSIVE TABLE
\*------------------------------------*/

/**
 * A responsive table that displays data as header/value pairs on small
 * devices, and as a standard table on larger ones.
 *
 * Use the structure:
 *  <table class="responsive-table">
 *      <thead class="responsive-table__header-group">
 *          <tr class="responsive-table__row">
 *              <th class="responsive-table__header">Header content for large devices goes here</th>
 *          </tr>
 *      </thead>
 *      <tbody>
 *          <tr class="responsive-table__row">
 *              <td data-th="Header content for small devices goes here" class="responsive-table__data">Data goes here</td>
 *          </tr>
 *      </tbody>
 *  </table>
 **/

$responsive-table-breakpoint: 'small-and-up' !default;
$responsive-table-delimiter: ":\00a0" !default;
$cell-padding: 0.5em;
$action-spacing: 0.3em;

%responsive-table {
    width: 100%;

    tbody {
        tr td {
            @extend %order-table__cell;
            @extend %responsive-table__data;
        }
    }

    .actions {
        .action {
            @extend %button;
            @extend %button--small;

            margin-bottom: $action-spacing;
        }
    }

    thead {
        @extend %responsive-table__header-group;

        border-bottom: 1px solid currentColor;

        tr {
            @extend %responsive-table__row;

            @include media-query($responsive-table-breakpoint) {
                padding: $cell-padding 0;
            }

            * + & {
                border-top: 1px solid currentColor;

                .order-table--history & {

                }
            }
        }
    }
}

%responsive-table-no-caption {
    caption {
        display: none;
    }
}

%responsive-table__header-group {
    display: none;
    visibility: hidden;

    @include media-query(#{$responsive-table-breakpoint}) {
        display: table-header-group;
        visibility: visible;
    }
}

%responsive-table__row {
    @extend %clearfix;  // IE won't display cells as block unless they're floated

    display: block;

    @include media-query(#{$responsive-table-breakpoint}) {
        display: table-row;
    }
}

%responsive-table__data {
    display: block;
    float: left;    // IE won't display cells as block unless they're floated
    text-align: right;
    width: 100%;

    @include media-query(#{$responsive-table-breakpoint}) {
        display: table-cell;
        float: none;
        width: auto;
    }

    &[data-th]:before {
        content: attr(data-th) "#{$responsive-table-delimiter}";
        float: left;
        font-weight: bold;

        @include media-query(#{$responsive-table-breakpoint}) {
            display: none;
        }
    }
}
