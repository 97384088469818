@mixin clear ($type : fix) {
  @if $type == left {
    clear: left;
  } @else if $type == right {
    clear: right;
  } @else if $type == both {
    clear: both;
  } @else {
    *zoom: 1;
    &:before, &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
  }
}

/**
 * To center a block element (B2) inside a parent block element (B1):
 *
 * @include centerUnknown(parent) applied to B1 - Essentially saying, this is the parent container
 * @include centerUnknown(child) applied to B2 - Essentially saying, this is the child container
 */
@mixin centerUnknown ($elem : child) {
  @if $elem == parent {
    text-align: center;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -0.25em;
    }
  } @else {
    display: inline-block;
    vertical-align: middle;
  }
}

@keyframes spin {
  from {
    transform:rotate(360deg);
  }
  to {
    transform:rotate(0deg);
  }
}
/**
 * A mixin that will allow a spinning animation to be applied to any element
 */
@mixin animate-spin-counter-clockwise {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
/**
 * This mixin will help with setting the colours for the text and buttons in a widget on the homepage
 */
@mixin widgetColours {
  /* Button/banner background/text colours */
  &.color-default {
    background-color: $color-default;
  }
  &.color-shop {
    background-color: $color-shop-accent;
  }
  &.color-sale {
    background-color: $color-sale;
  }

  /* Text only colours */
  &.text-color-sale {
    color: $color-sale;
  }
  &.text-color-shop {
    color: $color-shop-accent;
  }
  &.text-color-white {
    color: #FFF;
  }
  &.text-color-black {
    color: #000;
  }
}

@mixin product-tag {
  @include small-text-black;
  border: 1px solid $color-menu-items-border;
  padding: 0 25px;
}

/**
 * Applying the .hr-text class to a <hr> element will display the text that is in the data-content attribute
 * over the top of the hr an centered
 *
 * Params:
 *
 * $colour           - The colour of the text. Default #000000
 * $backgroundColour - The background colour of the text container. This needs to match the background of the
 *                     element where the hr is placed. Default #FFFFFF
 * $lineThickness    - Thickness of the horizontal rule line. Default 1px
 * $lineColour       - Colour of the line, if you want it different to the text colour. Default matches text colour
 *
 * Usage:
 *
 *  <hr class="hr-text" data-content="OR" />
 *
 * CSS
 *
 * .hr-text {
 *   @include hr-text(#000, #fff, 1px); // Not passing value for line colour, so it matches the colour of the text
 * }
 *
 */
@mixin hr-text($colour: #000000, $backgroundColour: #FFFFFF, $lineThickness: 1px, $lineColour: $colour) {
  position: relative;
  line-height: 1em;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;

  &:before {
    content: '';
    background: $lineColour;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: $lineThickness;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: $colour;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    background-color: $backgroundColour;
  }
}

/**
 * Placeholders for inputs. Works both at root or nested
 * over the top of the hr an centered
 *
 * Params: @content - the styles to be applied to the placeholder
 *
 * Usage:
 *
 *  .foo {
 *    @include placeholder {
 *      color: green;
 *    }
 *  }
 *
 *  @include placeholder {
 *    color: red;
 *  }
 *
 */
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin u-visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}