@use "sass:math";

/**
 * Styling for the Checkout
 */

/* From what I've seen, this loader doesn't really do anything, and is always showing, giving the impression that
   something is always loading */
.one-page-checkout__loader {
  display: none;
}

.afterpay-method-title {
  border-top: 1px solid #000 !important;
}

.page-layout-checkout {
  /*
    This is the loader that is displayed when moving from the Shipping step to the Review & Payments step
  */
  .loading-mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 500;

    .loader {
      position: absolute;
      top: 20%;
      left: 50%;
      margin-left: -200px;
      width: 400px;
      text-align: center;
      z-index: 501;
    }
  }
}


.checkout-page-header {
  padding-top: 20px;
  padding-left:20px;
}

.one-page-checkout__progress-bar {
  @extend %u-1-of-1;
  @extend %u-1-of-2-medium-and-up;
  display: inline-block; /* Fallback if display: flex isn't an option */
  display: flex;
  justify-content: space-around;
  font-size: 1.2em;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
  padding-bottom: 10px;

  .one-page-checkout__progress-step {
    &:before {
      @include icon-font;
      display: block;
      text-align: center;
      /*
        Adding the 'in progress' icon to both steps, and making the icons transparent, ensures the two steps'
        text stays lined up correctly. When a step is 'active' or 'complete', the font colour will be set to black,
        showing the icon
      */
      content: '\e5d3';
      color: transparent;
    }
    &._complete {
      cursor: pointer;

      &:before {
        content: '\e876';
        color: #000;
      }
    }
    &._active {
      font-weight: 500;
      &:before {
        color: #000;
      }
    }
  }
}

#checkout {
  @include functional-text-black;
  .one-page-checkout__steps {
    h2 {
      border-bottom: 1px solid #000;
    }

    padding-bottom: 1em;
  }

  .message-error {
    color: #FF0000;
    padding: 10px;
    font-weight: bold;
  }
}

/* Shipping Step */
.one-page-checkout__shipping-address {
  /* Styling for the height adjustable select element used to display selected address in Shipping Address section */
  .height-adjustable-select {
    margin: 0;
    padding: 0;
    position: relative;
    //width: 100%;
  }

  .height-adjustable-select__value {
    $svg-triangle: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: #{$color-text}"></polygon></svg>';

    appearance: none;
    background-image: url('data:image/svg+xml;charset=utf8,#{_encode($svg-triangle)}'); // SVGs must be URL-encoded to work in IE
    background-position: right 0.5rem center, right;
    background-repeat: no-repeat;
    background-size: 9px 6px, auto;

    min-height: 20px;
    padding: 5px 20px 5px 8px;
    background-color: #fff;
    white-space: pre-wrap;
    position: absolute;
    top: 1px;
    cursor: default;
    left: 1px;
    pointer-events: none;
    width: calc(100% - 2px);
    //height: calc(100% - 2px);
  }
}

/* Payment Methods */
.checkout-payment-method {

  .payment-methods {
    .actions-toolbar {
      padding-top: 15px;
      button {
        @include widget-button;

        span {
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            @include icon-font();
            /*content: '\e897';*/
            margin-right: 5px;
          }
        }
        span[data-bind="i18n: 'Place Order'"] {
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            @include icon-font();
            content: '\e897';
            margin-right: 5px;
          }
        }
        span[data-bind="text: $t('Place Order')"] {
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            @include icon-font();
            content: '\e897';
            margin-right: 5px;
          }
        }
      }
    }
  }

  .payment-method:not(._active) {
    .payment-method-content {
      @extend %u-visually-hidden;
    }
  }

  .payment-method-title {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-top: 1px solid #000 !important;

    .radio {
      outline: none;
    }

    .label {
      flex-grow: 1;
      height: 100%;
      padding: 10px;

      .payment-icon {
        margin-top: -8px;
      }

      .action-help {
        @include small-text-black
      }
    }

    .payment-method-title__credit-card {
      vertical-align: middle;
    }

    .credit-card-types {
      margin: 0;
      padding: 0;
      display: inline-block;
    }
  }

  .payment-method-content {
    padding: 20px;
  }

  .primary.checkout {
    @include widget-button;
  }

  .label {
    display: inline-block;
    cursor: pointer;
  }

  .tooltip-comment__valid-cvv-number {
    span {
      display: inline-block;
    }
  }
}
.payment-method-billing-address {
  .checkout-billing-address {
    padding-top: 15px;
  }

  .billing-address-same-as-shipping-block {
      @media only screen and (max-width: 520px)  {
          display: block;
          input {
              position: relative;
              bottom: 22px;
          }
          label {
              padding-left: 10px;
              width: 90%;
          }
      }
    label {
      display: inline-block;
    }
  }

  .billing-address-details {
    padding: 20px;
  }

  & > .fieldset {
    margin-top: -30px;
  }
}

.payment-method-billing-address form {
  @include address-form;
}

.checkout-agreements-block {
  .newsletter-signup {
    padding-top: 10px;
    label {
      display: inline-block;
    }
  }
}

#co-transparent-form-braintree {
  .hosted-error {
    @include small-text-black;
  }

  .field:not(.type) + .field{
    padding-top: 20px;
  }

  .field.type {
    padding-top: 10px;
  }

  .field-tooltip {
    cursor: pointer;
  }

  .control__cvv:not(._active) + .field-tooltip,
  .field-tooltip__save-for-later:not(._active) {
    .field-tooltip-content {
      @extend %u-visually-hidden;
    }
  }

  .field-tooltip__save-for-later {
    position: relative;
    display: inline-block;

    .field-tooltip-action {
      outline: none;
      width: 27px;
      display: inline-block;
      overflow: hidden;
      vertical-align: top;

      &:before {
        font-family: Arial;
        content: '?';
        text-decoration: underline;
      }
    }
    .field-tooltip-content {
      position: absolute;
      background-color: #fff;
      padding: 10px;
      top: -18px;
      left: 20px;
      border: 1px solid #000;
      //On mobile, make the edge of the tooltip stick to the edge of the container
      @media (max-width: 574px) {
        right: 0;
      }
      //Large than mobile gets a fixed width
      @media (min-width: 575px) {
        width: 320px;
      }

      &:before {
        border: solid;
        border-color: transparent #333;
        border-width: 6px 6px 6px 0;
        top: 24px;
        content: "";
        left: -7px;
        position: absolute;
        z-index: 99;
      }
    }
  }

  .action-cvv {
    margin-left: 5px;
    font-size: 0.85em;
  }
}

/* End of Payment Methods */

.one-page-checkout__shipping-address {
  padding-bottom: 20px;

  .action {
    @include widget-button;
    margin-top: 20px;
  }
}

//Styling for the inline login form shown when a users email is found to be associated with an account
.one-page-checkout__email-exists {
  .form__list-item {
    padding-top: 20px;
  }

  .form__action--forgot {
    display: inline-block;
    vertical-align: top;
    padding-top: 11px;
    padding-left: 5px;
    font-size: 0.9em;
  }
}

.shipping-address-item {
  line-height: 30px;
}

#checkout-shipping-method-load {
  table {
    td {
      padding: 3px 5px;
    }
  }
}

.modal-popup:not(.confirm) {
  .action-close { /* button */
    @include widget-button;
    @include clear();
    position: relative;
    float: right;

    span {
      margin-left: -21px;
    }

    &:after {
      @include icon-font;
      content: "\e5cd";
      display: inline-block;
      margin-left: 5px;
      font-size: 22px;
      position: absolute;
      top: 6px;
    }
  }

  .modal-content {
    @include address-form;
  }

  .modal-footer {
    button {
      @include widget-button;
    }
  }
}

.one-page-checkout__summary {
  background-color: #F7F7F7;
  padding: math.div($spacing-unit-base, 2);
  margin-bottom: 20px;

  @include media-query(medium-and-up) {
    margin-bottom: 0;
  }

  & > .opc-block-summary > .title {
    font-size: 1.3em;
    display: block;
    padding-bottom: 20px;
  }

  .items-in-cart {
    .title {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #000;
      outline: none;
      cursor: pointer;

      &:after {
        @include icon-font;
        content: '\e313';
        display: inline-block;
        position: absolute;
        right: 0;
      }

    }
    &.active .title:after {
      content: '\e316';
    }
  }

  ol.minicart-items { /*Target the ordered list, not the div that this class is also applied to*/
    border-bottom: 1px solid #000;
  }
}

/* Order Summary Totals */
.opc-block-summary {
  .table-totals {
    @include cart-totals;
    @extend %u-1-of-1;
  }
}

/* Order Summary Minicart */
.product-item-inner {
  @include clear();
}
.product-item-inner .right-column {
  float: right;
  text-align: right;
}
.product-item-inner .left-column {
  float: left;
}
.product-item-name-block {
  display: inline-block;
}
.product-item-name {
  display: inline-block;
}
.product-item-name-block .subtotal {
  display: inline-block;
}
.item-options {
  @include small-text-black;
}
.details-qty {
  @include small-text-black;
}
/* End of Order Summary Minicart */

/* Coupon Block */
#discount-form {
  @include discount-coupon;
}
/* End Of Coupon Block */
/* Shipping Information */
.shipping-information {
  .action { /* button */
    @include widget-button;
    @include small-text-white;
    min-width: 0;
    float: right;
  }

  .shipping-information-content {
    padding: 5px 10px;
  }
}

/*This is for the shipping form shown to guests when they checkout*/
#co-shipping-form {
  @include address-form;
}
/*This is for the email confirmation form shown to guests when they checkout*/
.one-page-checkout__email {
  @include address-form;
}
/* End of Shipping Information */

/* Checkout Success */
.print {
  @extend %button;
  @extend %button--small;
  margin-bottom: 10px;
}
.checkout-success {
  .continue {
    @extend %button;
  }
}
/* End ofCheckout Success */

/* Asterisk for required fields */
#checkoutSteps .required-entry {
  color: red;
}
.one-page-checkout__steps #customer-email-fieldset label:after, .street.required legend:after {
  content: " *";
  color: red;
}
/* End Asterisk for required fields */

/* Form hint formatting */
.one-page-checkout__steps .form__hint {
  color: #666666 !important;
  font-size: 12px !important;
}
.one-page-checkout__steps #customer-email-fieldset p {
  color: #666666 !important;
  font-size: 12px !important;
}
/* End Form hint formatting */

#onepage-checkout-cc-errors, #co-payment-form .message.notice span {
  font-weight: bold;
  color: red;
}

.paypal-action-help {
  display: none;
}

#checkout .one-page-checkout__summary {
  margin-bottom: 20px;
}

/* 1501 - Checkout Newsletter Fixes */
.registered-user-login-buttons {
  margin: 10px 0 20px 0;
  text-align: left;
}

.registered-user-login-buttons li:first-child {
  margin-right: 10px;
}
.registered-user-login-buttons li:first-child button {
  margin-top: -1px;
}

.newsletter-sub-field .newsletter-sub-box, .newsletter-sub-field .newsletter-sub-label {
  display: inline-block;
  height: initial;
}

.registered-user-login-buttons li {
  display: inline-block;
}

/* Authority To Leave Styling */
.authority-to-leave-checkbox label {
  display: none !important;
}
.authority-to-leave-checkbox .form__field--checkbox label {
  display: inline-block !important;
  width: 94%;
  vertical-align: top;
}
.authority-to-leave-delivery-notes input {
  border: 1px solid #ccc !important;
  height: 2.2rem !important;
}
.authority-to-leave-delivery-notes label {
  position: relative !important;
}

.authority-to-leave-delivery-notes label * {
  display: inline-block;
}

.authority-to-leave-checkbox, .authority-to-leave-delivery-notes {
  padding: 0 10px 0 16px;
}
.authority-to-leave-delivery-notes label {
  padding: 0 !important;
}

.authority-to-leave-checkbox .form__field--checkbox input {
  position: initial !important;
  height: initial !important;
  width: initial !important;
  outline: none;
}

.authority-to-leave-checkbox .form__field--checkbox .form__label:before {
  content: none !important;
}

#atl-tandc {
  color: #666;
}

.checkout-postcode-field, .checkout-phone-field {
  border: 1px solid #000;
  height: 2.75rem;
  padding: 0 1rem;
}
#co-shipping-form .form__hint:not(.form__hint--optional) {
  margin-top: 0;
}


/*  MMI-60  */
body.checkout-index-index {
  overflow-y: hidden;
}

/* MMI-146 */
.checkout-index-index .discount-code {
  outline: 0;
}

/* Fix for new address popup scroll */
.checkout-index-index .new-address-popup .modal-inner-wrap {
  height: 100% !important;
  overflow-y: auto !important;
}

/* Fix What Is This hide/show functionality post 2.3 */
.payment-method-braintree .field-tooltip.toggle [aria-hidden='true'] {
  display: none;
}

#co-transparent-form-braintree .action-cvv {
  margin-left: 0;
}

/* MMI-883 */
#co-shipping-form .postcode-autosuggest .postcode-suggestion,
.my-account__group--address-book-address .postcode-autosuggest .postcode-suggestion,
.react-autosuggest__suggestion {
  cursor: pointer;
}

.postcode-autosuggest {
  max-height: 215px;
  overflow-y: scroll;
}

/* Copy in Apply Pay button styling from new Braintree Module */

.braintree-applepay-container-product {
  display: inline-block;

  .braintree-apple-pay-button {
    padding: 10px 0;
    margin: 0;
    margin-bottom: 15px;
  }
}

.braintree-apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  display: block;
  width: 200px;
  min-height: 40px;
  border: 1px solid black;
  background-image: -webkit-named-image(apple-pay-logo-black);
  background-repeat: no-repeat;
  background-color: black;
  background-position: 50% 50%;
  border-radius: 5px;
  padding: 2px;
  margin: 20px auto;
  transition: background-color .15s;
  cursor: pointer;
}

.one-page-checkout .payment-option-content{
  margin-top: auto !important;
}

.is-discount-coupon {
    dl.item-options {
        margin-bottom: 10px;
    }
}

.new-cart-page-rule-description-container {
    .rule-description {
        color: #006f40;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        span {
            white-space: initial !important;
            i {
                width: 1px;
                height: 12px;
                background-color: $color-rule-coupon-green;
                display: inline-block;
                margin: 0 10px;
            }
        }
    }
}

/* adyen cc section Ui issues*/
.adyen-checkout__card__form {
    .adyen-checkout__input-wrapper {
        iframe {
            border: none;
            height: 100%;
            width: 100%;
            overflow: hidden;
        }
    }
    .adyen-checkout__field__exp-date {
        .adyen-checkout-input__inline-validation {
            top: 20%;
        }
    }
    .adyen-checkout__field__cvc {
        .adyen-checkout-input__inline-validation {
            top: 32%
        }
    }
}
