@mixin badge {
  @include functional-text-white;
  font-weight: 500;
  display: table-cell;
  width: $badgeDiameter;
  height: $badgeDiameter;

  text-align: center;
  vertical-align: middle;

  border-radius: 50%;
}

@mixin sale-badge {
  background-color: $color-sale;
}

/* Google Review Container - MMI-955 */
.google-reviews-badge-container {
  right: initial;
  left: 0;
}

.catalog-category-view .google-reviews-badge-container {
  display: none !important;
}

.checkout-index-index .google-reviews-badge-container {
  display: none !important;
}