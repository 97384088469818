/*------------------------------------*\
    #SORTER
\*------------------------------------*/

/**
 * PLP list sorter
 **/

/*div*/
.sorter {
    & > * {
        display: inline-block;
        vertical-align: middle;
    }
}


/*label*/
.sorter__label {
    &:after {
        content: ":";
    }
}


/*select*/
.sorter__options {
    margin: 0;
}


/*button*/
.sorter__action {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
    vertical-align: middle;
}

.sorter__action--asc {
    &:before {
        content: "\2191";
    }
}

.sorter__action--desc {
    &:before {
        content: "\2193";
    }
}