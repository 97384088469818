/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

/**
 * Basic heading styles
 *
 * Note that each heading style should be accompanied by a corresponding
 * extend-only class so that heading styles can easily be applied to other
 * elements.
 **/

$enable-headings-utility-classes: false !default;  // Create utility classes to mimic heading styles - csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css

// Set default heading behaviour

h1, h2, h3, h4, h5, h6,
%alpha, %beta, %gamma, %delta, %epsilon, %zeta {
    page-break-after: avoid;

    & strong, & b {
        font-weight: inherit;    // Prevent CMS users from applying font weights within headings
    }

    font-family: $font-family-headings;
    font-weight: $font-weight;
}

#maincontent h1 {
    margin-bottom: 0;
}

h1,
%alpha {
    @extend %wrapper;
    @extend %wrapper--gutter;

    padding: rems(30px) 0 rems(20px) 0;
    text-align: center;
    margin-top: 0;
}

h2,
%beta {}

h3,
%gamma {}

h4,
%delta {}

h5,
%epsilon {}

h6,
%zeta {}





// Create heading utility classes, if required

@if $enable-headings-utility-classes == true {
    .alpha {
        @extend %alpha;
    }

    .beta {
        @extend %beta;
    }

    .gamma {
        @extend %gamma;
    }

    .delta {
        @extend %delta;
    }

    .epsilon {
        @extend %epsilon;
    }

    .zeta {
        @extend %zeta;
    }
}

h1 {
    text-transform: capitalize;
}

.sub-heading-level-two{
    margin-bottom: 15px;
    font-size: 40px;
    letter-spacing: 2px;
    margin-top: 0 !important;
    text-transform: inherit !important;
    color: inherit !important;
}
.sub-heading-level-two-mathers{
    margin-bottom: 15px;
    font-size: 40px;
    letter-spacing: 2px;
    margin-top: 0 !important;
    text-transform: inherit !important;
    color: inherit
}