.react-plp-app {

}

.react-plp-app__results {
  width: 75%;
}

.react-plp-app__results-header {
  padding: 0 0 1rem 1rem;
}

.react-plp-app__filters {
  width: 25%;
  max-width: 15rem;
}

.react-plp-app__result-count {
  position: static;
  width: auto;
}

.react-plp-app-product-filters-accordion .accordion-key__header {
  display: none;
}
