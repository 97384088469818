
.site-footer-store-search__form {
  margin-bottom: 1rem;
}

.site-footer-store-search__heading {
  margin-bottom: 10px;
  font-size: 0.875rem;
  font-weight: bold;
}

.site-footer-store-search__join {
  display: flex;
  justify-content: center;
}

.site-footer-store-search__input {
  width: 70%;
  height: 2.2rem;
  padding: 0 1rem;
  border: 1px solid #CCCCCC;
  border-radius: 3px 0 0 3px;
  background-color: #FFFFFF;
  font-size: 1rem;

  &::placeholder {
    font-size: inherit;
    text-transform: inherit;
  }
}

.site-footer-store-search__button {
  min-width: auto;
  padding: 0.2rem 1rem;
  border-radius: 0 3px 3px 0;
  font-size: 0.9rem;
}


@media #{media-query(medium-and-up)} {
  .site-footer-store-search__join {
    justify-content: flex-start;
  }
}
