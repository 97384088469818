.react-checkout .afterpay-info {
  text-align: center;
}

.afterpay-info__title img {
  height: 50px;
  margin-right: 5px;
}

.afterpay-info__more-info {
  font-size: 11px;
}

.afterpay-info__payments-total {
  font-weight: 600;
}

.afterpay-info__redirect-info {
  font-size: 14px;
  margin: 1em 0 0;
  padding: 0 30px;
}
