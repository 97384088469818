.free-shipping-indicator {
    padding: 1rem;

    /**
     * @TODO: refactor this component so it takes prop types for BEMISH classnames
     *        do this by opening a pull request on the aligent react components repo
     */
    .progress-label {
        font-size: 12px;
    }

    .meter-container {
        height: 5px;
        max-width: calc(100% - 2rem);
        background-color: lighten($color-shop-accent, 45%);
    }
        .meter {
            background-color: $color-shop-accent;
        }
}