/*------------------------------------*\
    #SVG
\*------------------------------------*/

/**
 * Note: inline SVGs can be problematic for responsive design:
 * some browsers will retain their aspect ratio, while others
 * will retain the initial height. As such, we can't reliably
 * add `max-width`: any responsive sizing will need to be handled
 * on a case-by-case basis.
 **/

svg {
    vertical-align: middle;
}
