.react-checkout-errors {
	padding: 1rem;
	background: $color-grey;
	border: 1px solid $color-border-outline-light;
	color: $color-dark-grey;
}
	.react-checkout-errors__header {
		font-size: 14px;
		font-weight: bold;
	}
	.react-checkout-errors__list {
		font-size: 14px;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.react-checkout-errors__item {}
	.react-checkout-errors__error-message {}