@use "sass:math";

$header-tabs__icon--size: 4.75rem !default;
$header-tabs__icon--width: $header-tabs__icon--size !default;
$header-tabs__icon--height: $header-tabs__icon--size !default;
$header-tabs__icon--fg: $color-shop-accent !default;
$header-tabs__icon--index: 10;
$header-tabs__icon-completed--fg: $color-shop-accent !default;
$header-tabs__icon-active--fg: $color-shop-accent !default;

$header-tabs__completed-icon--fg: white !default;
$header-tabs__completed-icon--bg: $color-shop-accent !default;
$header-tabs__completed-icon--size: $header-tabs__icon--size * 0.4;
$header-tabs__completed-icon--width: $header-tabs__completed-icon--size;
$header-tabs__completed-icon--height: $header-tabs__completed-icon--size;
$header-tabs__completed-icon--border-width: 2px !default;
$header-tabs__completed-icon--border-fg: white !default;

$header-tabs__label--index: 10;

$header-tabs__line--fg: $color-border-outline-light !default;
$header-tabs__line-completed--fg: $color-border-outline-light !default;
$header-tabs__line--thickness: 2px !default;
$header-tabs__line--width: calc(100% - 18rem) !default;
$header-tabs__line--index: 1;

.checkout-app-progress-tabs {
  margin-top: 0;
  margin-bottom: math.div($header-tabs__icon--size, 2);
  justify-content: space-around;
  position: relative;
}

.checkout-app-progress-tab {
  width: 33.333%;
  position: relative;
  display: flex;
  justify-content: center;
}

.checkout-app-progress-tab::after {
  content: '';

  position: absolute;
  z-index: $header-tabs__line--index;
  top: 50%;
  left: 0%;
  transform: translateY(-50%) translateX(50%);
  width: 100%;
  height: 1px;

  border-bottom: $header-tabs__line--thickness solid $header-tabs__line--fg;
}

.checkout-app-progress-tab--completed::after {
  border-bottom: $header-tabs__line--thickness solid $header-tabs__line-completed--fg;
}

.checkout-app-progress-tab:last-child::after {
  content: initial;
}

.checkout-app-progress-tab__button {
  width: $header-tabs__icon--width;
  height: $header-tabs__icon--height;

  z-index: $header-tabs__icon--index;
}

.checkout-app-progress-tab__button:hover,
.checkout-app-progress-tab__button:focus {
  background-color: transparent;
  outline: none;
}

.checkout-app-progress-tab__icon {
  background-color: $color-white;
  color: $header-tabs__icon--fg;
  border-radius: 1000%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-app-progress-tab__icon .u-icomoon {
  font-size: 48px;
  opacity: 0.5;
}

.checkout-app-progress-tab--active,
.checkout-app-progress-tab--completed {
  .u-icomoon {
    opacity: 1;
  }
}

.checkout-app-progress-tab--active .checkout-app-progress-tab__icon {
  color: $header-tabs__icon-active--fg;
}


.checkout-app-progress-tab--completed .checkout-app-progress-tab__icon {
  color: $header-tabs__icon-completed--fg;
}

.checkout-app-progress-tab--active,
.checkout-app-progress-tab--completed {
  .checkout-app-progress-tab__label {
    color: $color-black;
    opacity: 1;
  }
}

.checkout-app-progress-tab__completed-indicator {
  position: absolute;
  transform: translateX(50%) translateY(20%);
  z-index: 100;

  width: $header-tabs__completed-icon--width;
  height: $header-tabs__completed-icon--height;
  border-radius: 1000%;
  border: $header-tabs__completed-icon--border-width solid $header-tabs__completed-icon--border-fg;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $header-tabs__completed-icon--fg;
  background-color: $header-tabs__completed-icon--bg;
}

.checkout-app-progress-tab__completed-indicator .material-icons {
  font-size: 18px;
}

.checkout-app-progress-tab__label {
  display: flex;
  justify-content: center;
  align-content: center;

  position: absolute;
  top: 85%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: $header-tabs__label--index;

  text-indent: 0;
  font-family: $font-family-headings;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.5;
}
