#shopping-cart-table {
  .cart-item-actions__remove-btn,
  .cart-item-actions__wishlist-btn,
  .cart-item-actions__edit-btn {

    &:hover,
    &:focus {
      color: $color-black;
    }
  }
}

#block-discount .action-apply, #block-discount .action.apply {
  background-color: initial !important;
}