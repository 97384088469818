/*
  Overrides for the price box styling
*/
.price-box {
  .special-price {
    .price {
      color: $color-sale !important;
    }
  }
}
