/**
 * Tabbed Header
 */
// .checkout-app-form .tabbed-ui__tabs-header {}

.checkout-app .tabbed-ui__tabs-header {
  display: flex;
}

.checkout-app .tabbed-ui__tabs {
  width: 100%;
}

.checkout-app .tabbed-ui__tabs-header {
}

.checkout-app .tabbed-ui__tabs {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
}

.checkout-app .payment.apple-pay-layout .tabbed-ui__tabs {
  list-style: none;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  margin-right: 33%;
  row-gap: 20px;
}

.checkout-app .tabbed-ui__tab {
  cursor: pointer;
  padding: 1rem;
}

/**
 * 1: trick to make borders overlap
 */
.checkout-app .tabbed-ui__tab {
  width: 100%;

  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5px;

  z-index: 10; /* 1 */

  border-color: $color-dark-grey;
  border-width: 1px;
  border-style: solid;
  color: $color-dark-grey;
  text-align: center;

  &:hover,
  &:focus {
    border-color: $color-black;
    color: $color-black;
  }
}

.tabbed-ui__content {
  margin-top: 1rem;
}

.checkout-app .tabbed-ui__tab.active {
  // border-color: $color-black;
  // color: $color-black;
  font-weight: bold;
  z-index: 20; /* 1 */
  border-bottom-width: 3px;
  border-bottom-color: $color-shop-accent;
  color: $color-shop-accent;
}

.checkout-app .tabbed-ui__tab img {
  max-height: 24px;
  height: auto;
}


.checkout-app .tabbed-ui__tab:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.checkout-app .tabbed-ui__tab:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.checkout-app .payment.apple-pay-layout .tabbed-ui__tab:nth-child(1n) {
  border-radius: 5px 0 0 5px;
}

.checkout-app .payment.apple-pay-layout .tabbed-ui__tab:nth-child(2n) {
  border-radius: 0 5px 5px 0;
}

.checkout-app .payment.apple-pay-layout .tabbed-ui__tab:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
