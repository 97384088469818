.my-account .my-account__action--back {
  background-color: #FFF;
  color: $color-shop-accent;
  border-color: $color-shop-accent;
}

.my-account__action.my-account__action--link-account {
  color: $color-white;

  &:focus,
  &:hover {
    color: $color-black;
    background-color: $color-shop-accent-hover;
  }
}

.wishlist-index-index .product-list--wishlist .product-tile__action--add-to-cart {
  &:focus,
  &:hover {
    color: $color-black !important; // overriding important in base theme
    background-color: $color-shop-accent-hover;
  }
}
