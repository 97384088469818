
.checkout-app__sections {
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
}

.checkout-app__tab-section {
  padding: 0 2rem;

  width: calc(100% / 3);
}

.checkout-app-tab-section__content {
  display: flex;
}

.checkout-app-shopping-bag-footer {
  display: none;
}

.checkout-app-shopping-bag-footer--visible {
  display: block;
}
