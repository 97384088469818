@mixin counter-number {
  @include small-text-white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  top: 5px;
  background-color: $color-background-counter-number;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;

  right: -15px;
  @include media-query(medium-and-up) {
    right: 15px;
  }
}
