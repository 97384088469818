@use "sass:math";
/*------------------------------------*\
    #FORM
\*------------------------------------*/

/**
 * Additional form helper classes
 **/

$form-input-height: rems(44px) !default;
$form-input-padding: 0 rems(16px) !default;
$form-spacing-vertical: rems(20px);

.form {}

.form--narrow,
%form--narrow {
    margin: {
        left: auto;
        right: auto;
    }
    max-width: math.div($max-width, 2);
}

.form--password {
    @extend %form--narrow;
}


.form__field {
    clear: both;
    margin-bottom: $form-spacing-vertical;
    position: relative;
}


.form__label {
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;

    .form__field--text & {
        line-height: rems(44px);
        overflow: hidden;
        padding: 0 rems(16px);
        position: absolute;
        transition: opacity $transition-time;
        z-index: 0;

        &._dirty {
            opacity: 0;
            transition: opacity $transition-time, z-index 0s $transition-time;
            z-index: -1;
        }
    }

    .form__field--select & {
        @extend %u-visually-hidden;
    }

    .form__field--checkbox &,
    .form__field--radio & {
        $size: rems(13px);

        cursor: pointer;
        display: inline-block;

        &:before {
            background: {
                position: center;
                repeat: no-repeat;
                size: 100%;
            }
            border: 1px solid currentColor;
            content: '';
            display: inline-block;
            height: $size;
            margin-right: 0.5em;
            margin-top: -0.2em; // Manually adjust to compensate for font baseline differences
            right: 100%;
            vertical-align: middle;
            width: $size;
        }
    }

    // Style the fake input based on the real input's status
    .form__field--checkbox *:checked + &:before {
        $svg: '<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13"><path stroke="currentColor" stroke-width="1.5px" d="M3 3l7 7M10 3l-7 7"/></svg>';

        background-image: url('data:image/svg+xml;charset=utf8,#{_encode($svg)}'); // SVGs must be URL-encoded to work in IE
    }

}

/*
    The New Address form on the Checkout has the Street Address input field in a <fieldset> element, which doesn't
    match any of the other form elements, so the styling rules applied to the other elements don't match this field.
     This rule is just so the label will be consistent with the other form elements, the <input> styling is updated
     with the rest of the <input> styling
*/
.admin__control-fields {
    .form__label {
        line-height: rems(44px);
        overflow: hidden;
        padding: 0 rems(16px);
        position: absolute;
        transition: opacity $transition-time;
        z-index: 0;
    }
}

.form__label--field-group {
    @extend %u-visually-hidden;
}


.form__input {
    .form__field--text &,
    &[type="text"] {
        @include form-input;
    }

    .form__field--select & {
        width: 100%;
    }

    .form__field--checkbox &,
    .form__field--radio & {
        @extend %u-visually-hidden; // Hide the real input, as we'll be using the label to create a custom input
    }

    .form__field--radio & {
        &:before {
            border-radius: 50%;
        }
    }
}

.input-text {
    @include form-input;
}

.form__hint {
    display: block;
}

.form__hint--inline {
    display: inline-block;

    &:before {
        content: "(";
    }

    &:after {
        content: ")";
    }
}


.form__error {
    color: $color-danger;
    font-weight: bold;
}


%form__list,
.form__list {
    @extend %list-bare;
}

%form__list--action,
.form__list--action {
    @extend %clearfix;

    margin-top: -$form-spacing-vertical;
    text-align: center;

    & > * {
        display: inline-block;
        margin: $form-spacing-vertical (math.div($layout-gutter, 2)) 0;

        @include media-query(medium-and-up) {
            margin: 0;
            display: block;
        }
    }
}


%form__list-item,
.form__list-item { }


%form__action,
.form__action {
    @extend %button;
}

%form__action--list,
.form__action--list {
    display: block;

    @include media-query(medium-and-up) {
        float: right;
        margin-left: $layout-gutter;
        margin-top: $form-spacing-vertical;
    }
}

%form__action--left {
    @include media-query(medium-and-up) {
        float: left;
        margin-left: 0;
        margin-right: $layout-gutter;
    }
}

%form__action--back,
.form__action--back {
    @extend %button--outline;
    @extend %form__action--left;
}
