.navigation-bar-mobile__toggle {
  display: block;

  &::before {
    margin-top: 0;
  }

  //Change to an X icon when the menu is open
  &.open::before {
    content: '\e5cd';
  }
}


button, .button {
  cursor: pointer;
}

button:hover,
.button:hover,
button:focus,
.button:focus {
  background-color: $color-shop-accent-hover;
}

.button--outline:hover,
.button--outline:focus {
  border-color: $color-shop-accent-hover;
  background-color: $color-shop-accent-hover;
  color: $color-white;
  outline: none;
}

.button--link {
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  color: $color-shop-accent;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: transparent;
    color: $color-shop-accent-hover;
    outline: none;
  }

}

.button--secondary {
  border-color: $color-darker-grey-accent;
  background-color: transparent;
  color: $color-darker-grey-accent;
  outline: none;

  &:hover,
  &:focus {
    border-color: $color-darker-grey-accent;
    background-color: $color-darker-grey-accent;
    color: $color-white;
  }
}
