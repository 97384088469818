/**
 * Styling for the contact page
 */
$contact-form-width: 832px;

.contact-index-index {
  .main-content {
    margin-bottom: 0;
  }

  .wrapper--gutter {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}

.contact__subheader {
  @include large-text;
  width: 100%;
  @include media-query(small-and-up) {
    width: 490px;
  }
  text-align: center;
  margin: -30px auto 30px;
}

#contact-form { /* form */
  margin: 0 auto;
  padding: 20px 30px 67px 30px;

  @include media-query(large-and-up) {
    width: $contact-form-width;
    padding-left: 20px;
    padding-right: 20px;
  }

  .form__label {
    @extend %u-hidden;
  }

  .form__input {
    &::-webkit-input-placeholder {
      @include contact-form-placeholder;
    }

    &:-moz-placeholder { /* Firefox 18- */
      @include contact-form-placeholder;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      @include contact-form-placeholder;
    }

    &:-ms-input-placeholder {
      @include contact-form-placeholder;
    }

    &::placeholder {
      @include contact-form-placeholder;
    }
  }

  textarea {
    border: 1px solid currentColor; // Use pixels to make sure border always displays
    padding: 5px 1rem 0 1rem;
    height: 133px;
  }

  /* Start of form layout */
  .row.two-columns {
    font-size: 0;

    .form__field {
      width: 100%;
      @include media-query(small-and-up) {
        width: 50%;

        &:first-child {
          padding-right: 8px;
        }
        &:last-child {
          padding-left: 8px;
        }
      }
      display: inline-block;
      vertical-align: top;
      font-size: medium; /* IE Fix */
      font-size: initial;
    }
  }

  //Layout for submit button
  .contact-us__list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;

    li {
      button {
        @extend %button;
        width: 100%;
        @include media-query(small-and-up) {
          width: 194px;
        }
        display: inline-block;
      }
    }
  }

  /* End of form layout */
}

/* /#contact-form */

.contact-details-container {
  background-color: $color-mid-white;
  padding: 50px;

  .contact-details {
    @include media-query(large-and-up) {
      width: $contact-form-width;
    }
    margin: 0 auto;

    h1 { //Has to be h1 to allow client to edit the text in the CMS. They'll be using h1 for the headings
      @include large-text;
      text-align: left;
      margin: 0;
      padding: 0;
    }

    table {
      tr {
        td {
          padding-right: 20px;
        }
      }
    }

    a {
      text-decoration: none;
    }

    .phone-icon,
    .email-icon {
      vertical-align: middle;

      &:before {
        @include icon-font();
        display: inline-block;
        content: '\e0cd';
      }
    }

    .phone-icon {
      &:before {
        content: '\e0cd';
      }
    }

    .email-icon {
      &:before {
        content: '\e0be';
      }
    }
  }

  /* /.contact-details */
}

/* /.contact-details-container */

.form--contact {
  select {
    border-radius: 0 !important;
  }

  div.mage-error {
    font-size: 14px;
    color: #FF0000;
    margin-top: 2px;
  }

  input, select, textarea {
    &.mage-error {
      border: 1px solid #FF0000 !important;
      border-radius: 0 !important;
    }

    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      background-color: white !important;
    }
  }
}