.navigation-bar .primary-navigation > li {
  font-size: 16px;
}

.navigation-bar-scrolled {
  .search-suggest-icon-wrapper {
    .search-suggest-icon:before {
      color: $color-black;
      @include icomoon('search');
      font-size: $scrolled-nav-icon-font-size;
    }
  }

}

@media (min-width: 960px) {
  .navigation-bar-scrolled .minicart__summary .minicart__count {
    top: 4px !important; // overriding import in base theme
  }
}
