.header-sub-strip {
  background-color: $color-white;
  border-bottom: 1px solid $color-light-grey;
}

.header-sub-strip__list {
  justify-content: center;

  li {
    flex: 0 auto;

    a {
      i {
        color: $color-black;
      }
    }
  }
}


