/*------------------------------------*\
    #IFRAME
\*------------------------------------*/

/**
 * Remove borders from iFrames
 */

iframe {
    border-style: none;
}
