.filter .filter__category .size-switcher,
.filter .filter__category ol input+label,
.filter .filter__category ol.filter__options a.filter__option {
  font-family: $font-family-base;
  text-transform: capitalize;
  font-size: 15px;
}

.swatch-option.image:not(.disabled).active, .swatch-option.color:not(.disabled).active {
  border: 2px solid #000 !important;
}

.filter .filter__category .left-menu-Colour,
.filter .filter__category .left-menu-size,
.filter .filter__category .left-menu-Price {
  min-height: 100% !important;
  max-height: 100% !important;
}