/**
 * Styling for the instructions that are placed below the Buy Now button on the Styletread one step checkout only
 */
.osc-afterpay-redirect-notice {
  text-align: right;
  display: none;

  &.field.choice.col-mp.mp-12 { // Required to beat specificity in grid-mageplaza.css file
    margin: 0 0 20px;
  }

  &.visible {
    display: block;
  }

  .redirect-notice {
    text-transform: none;
    max-width: 80%;
    float: right;
    margin: 0;
  }

  .terms-conditions-link-container {
    float: right;

    &::after {
      content: '';
      display: block;
      clear: both;
    }

    a {
      color: #808080;
    }
  }
}
