/**
 * Styling for the afterpay payment method in the checkout
 */
.afterpay-payment-method {
  &.payment-method {
    // The following styles are nested inside &.payment-method to overcome specificity
    .payment-method-title,
    .payment-method-content {
      border-bottom: none;
    }

    .label {
      padding-top: 15px;
    }

    .payment-icon {
      padding-top: 5px;
      max-width: 150px;
    }

    .payment-method-content {
      padding-top: 0;
    }
  }

  .afterpay-explanation {
    text-transform: none;
    padding: 0 20px 0;
  }

  .afterpay-checkout-redirect {
    border-top: 1px solid #000;
  }

  .installments-information {
    padding-left: 20px;
    font-weight: 600;
  }

  .redirect-notice {
    text-transform: none;
    font-size: 15px;
  }

  .terms-conditions-container {
    a {
      color: #808080;
    }
  }

  &#afterpaypayovertime-method { // Have to use ID to overcome afterpay's rules
    .payment-method-content {
      background: none;
    }

    .afterpay-checkout-redirect {
      display: block;
      margin-top: 0;
      padding: 15px 0;
    }

    .actions-toolbar {
      float: none;
      text-align: right;
      padding-bottom: 15px;

      .primary {
        display: inline-block;
        width: auto;
      }
    }

    .afterpay-checkout-redirect .instalment-footer {
      text-transform: none;
      float: none;
      text-align: right;
      width: auto;
    }
  }
}

/**
 * The following is styling for the afterpay installments notice listed in the order summary container
 */
.afterpay-installments-totals {
  text-align: right;

  .afterpay-installments {
    position: relative;
    padding: 0;

    .installments-notice {
      position: absolute;
      left: 0;
      right: 0;
      top: -26px;
      font-size: 15px;
      display: none;

      &.visible {
        display: block;
      }
    }
  }
}

/* Radio Button Fix */
.page-layout-checkout .payment-method .payment-method-title {
  width: 100%;
}
.page-layout-checkout .payment-method .payment-method-title input.radio {
  width: 10%;
  margin-left: -4%;
}
.page-layout-checkout .payment-method .payment-method-title label {
  width: 90%;
}

.afterpay-logo-height {
  height:30px !important;
}