/*
  Styling for the password reset form located at `/customer/account/createpassword`
*/

form.password.reset {
  @extend %form--narrow;

  label {
    @include functional-text-black;
  }

  .control {
    padding-top: 5px;
  }

  .field.confirmation {
    margin-top: 20px;
  }

  .actions-toolbar {
    text-align: center;
    padding-top: 20px;

    .action.submit {
      @include button;
    }
  }
}

#password-strength-meter-container {
  display: none !important;
}

form.password.reset label {
  font-weight: 600;
}
form.password.reset .control {
  position: relative;
}

form.password.reset input,
form.password.reset .action {
  border-radius: 6px;
}

form.password.reset .mage-error {
  color: red;
  border-color: red !important;
}

form.password.reset .password-validated {
  color: green;
  border-color: green !important;
}

#custom-errors-password {
  display: none;
  font-size: 14px;
}

.password-status,
.confirm-password-status {
  position: absolute;
  right: 10px;
  top: 12px;
}

.password-status.invalid:after,
.confirm-password-status.invalid:after {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f057";
  display: block;
  color: red;
}

.password-status.valid:after,
.confirm-password-status.valid:after {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f058";
  display: block;
  color: green;
}
