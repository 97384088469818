/*------------------------------------*\
    #ACCOUNT NAVIGATION
\*------------------------------------*/

/**
 * Account navigation - eg sign in, etc
 **/

/*div, ul*/
.account-navigation {}

.account-navigation__item {
    & + & {
        margin-left: 0.5em;
    }
}


.account-navigation__link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
