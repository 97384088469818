$color-fusion-rewards: #DE0031;
$color-fusion-rewards-button-color: $color-white;
$frb-rewards-font: "proxima-nova", Helvetica, Arial, sans-serif;

/**
@example
<article class="fusion-rewards-signup">
  <div class="fusion-rewards-signup__container">
    <div class="fusion-rewards-signup__header">
      <span class="fusion-rewards-signup__icon u-icomoon u-icomoon--rewards"></span>
      <h1 class="fusion-rewards-signup__heading">Fusion Rewards</h1>
    </div>
    <div class="fusion-rewards-signup__content">
      <p>Earn points and enjoy amazing rewards every time you shop with your favourite Fusion Brands.</p>
    </div>
    <div class="fusion-rewards-signup__actions">
        <a class="button fusion-rewards-signup__action"
            href="/fusion-rewards">
          <span class="fusion-rewards-signup__action-icon u-icomoon u-icomoon--rewards"></span>
          <span class="fusion-rewards-signup__action-label">Join Fusion Rewards</span>
        </a>
    </div>
  </div>
</article>
 */
.fusion-rewards-signup {
  font-family: $frb-rewards-font;
  font-weight: 300;
  display: flex;
  min-height: 220px;
  background-color: #9B9DA0;
  background-image: radial-gradient(circle at 50% 85%, rgba(#ffffff, 0.45) 0%, rgba(#E8E8E8, 0) 75%);

  .fusion-rewards-signup__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    padding: 1rem 0;
    max-width: 371px;
  }
  .fusion-rewards-signup__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .fusion-rewards-signup__icon {
    font-size: 48px;
    color: $color-fusion-rewards;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fusion-rewards-signup__heading,
  h4 { //using element selector since this is coming from static block
    font-family: $frb-rewards-font;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 5px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;

    padding: 0;
    margin: 0 0 10px;
  }

  .fusion-rewards-signup__content {
    margin-bottom: 13px;

    text-align: center;
    color: #ffffff;
  }

  .fusion-rewards-signup__content > :last-child {
    margin-bottom: 0;
  }

  .fusion-rewards-signup__action {
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    line-height: 2;
    background: $color-fusion-rewards;
    color: $color-fusion-rewards-button-color;
    font-weight: 300;
    color: $color-white;
  }

  .fusion-rewards-signup__action-icon {
    font-size: 26px;
    display: flex;
    margin-right: 1rem;
  }

  .fusion-rewards-signup__action-label {
    font-size: 14px;
  }
}
