/* FWU-183 */
.product-info__main .product-info__brand {
  display: block !important;
  color: #797979;
}

.product-info__main .product-info__title {
  font-size: 29px;
  //font-weight: bold;
  font-family: 'ProximaNovaRegular', Helvetica, Arial;
}

.product-info__main .price-final_price .price,
.product-info__main .price-final_price .stock.unavailable,
.product-info__main .price-box .special-price .price {
  font-size: 18px;
  font-weight: bold;
}

.product-info__main .afterpay-installments-amount {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px 0px;
  margin-top: 10px;
}

.product-info__main .product__sizing-size-guide--link {
  font-weight: bold;
  margin-left: 10px;
}

.product-info__main .swatch-opt .size-switcher {
  height: 2rem;
  padding: 0 0.5rem;
}

.product__stock-message {
  color: $color-shop-accent;
}
.product-info__main .add-to-cart__submit {
  overflow: hidden;
}
.product-info__main .add-to-cart__submit span {
  position: relative;
  top: -17px;
  outline: none;
}
.product-info__main .add-to-cart__submit span:before {
  content: "";
  font-family: 'icomoon';
  font-size: 38px;
  position: relative;
  top: 10px;
}

.product-info__main .product-addto-links .towishlist:before {
  content: "";
  font-family: 'icomoon';
  margin-right: 10px;
  color: $color-shop-accent;
  font-size: 36px;
}
.product-info__main .product-addto-links .towishlist span {
  position: relative;
  top: -5px;
}

#product-attribute-specs-table tbody tr th {
  padding-left: 0;
}

.recently-viewed-pdp-container {
  background-color: initial;
}
.recently-viewed-pdp-container > .block-viewed-products-list .block-title {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 32px;
}

@media (max-width: 960px){
  .recently-viewed-pdp-container {
    display: none;
  }
}

.product-info__main .product-title__brand {
  font-family: 'ProximaNovaLight', Helvetica, Arial;
  font-size: 14px;
}

.product-info__main .add-to-cart__submit:hover {
  background-color: $color-shop-accent-hover;
}

.find-in-store__postcode-submit-button {
  background-color: $color-shop-accent;
}
.find-in-store__postcode-submit-button:hover {
  background-color: $color-shop-accent-hover;
}

.fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
  cursor: pointer;
}

.find-in-store__container .find-in-store__stores-list .find-in-store__store-item:hover {
  background-color: initial;
}
.find-in-store__store-item {
  padding: 0;
  margin: .25rem .5rem;
}

/* M257 */
.selling-feature-pdp {
  padding-left: 0 !important;
}
.selling-feature-pdp:before {
  content: "\2022";
  margin-right: 5px;
}

/* MMI-920 */
.fotorama__thumb-border {
  border: 2px solid #ff5501 !important;
}

/* MMI-1112 BV position fix */
.product-info__main .product-info__brand {
  position: relative !important;
}

[data-bv-show="rating_summary"] .bv_main_container {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.product-compare-wrapper a:before {
  background-image: url(/media/wysiwyg/Williams/compare-icons/Compare1.png);
  background-size: contain;
  width: 25px;
  height: 25px;
  margin: 0;
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  vertical-align: text-top;
  margin-right: 5px;
}

.recently-viewed-pdp-container li.product-item {
    margin-right: 5px;
    vertical-align: top;
}

/* MMI-1759 PDP Hero image */
.fotorama__wrap .fotorama__stage__frame .fotorama__img {
    max-height: 94.20%;
    max-width: 79.5%;
}