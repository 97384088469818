/*------------------------------------*\
    #PRODUCT-LIST
\*------------------------------------*/

/*ul*/.product-list {
    @extend %layout;
}

/*li*/.product-list__item {
    @extend %layout__item;
    @extend %u-1-of-2-small-and-up;
    @extend %u-1-of-3-medium-and-up;
}

#plp-to-top {
    background-color: #4a4b4f;
    background-color: #666666;
    position: fixed;
    cursor: pointer;
    display: none;
    height: 40px;
    width: 110px;
    bottom: 20px;
    left: 10%;
    z-index: 10;
    border: 1px solid #FFFFFF;
    outline: none;
    color: white;
}

/* Recently Viewed PLP */
// Reduce image size down to not obstruct PLP so much
.page-layout--two-col .block-viewed-products-list .product-image-photo {
    width:155px !important;
    height: 160px !important;
}

/* Search Result Page */
.search.results dl.block {
    display: none;
}

.catalogsearch-result-index .main-content h1 {
    padding-top: 10px;
    padding-bottom: 0;
}

@media (max-width: 960px) {
    .catalogsearch-result-index .main-content h1 {
        padding-bottom: 0;
        padding-top: 10px;
        font-size: 22px;
    }
}

.filter .filter__category .left-menu-Category .filter__options {
    margin-bottom: 0 !important;
}

/* 1652 - 2 Up for all sites */
@media (max-width: 960px) {
    .product-list__item {
        width: 50%;
    }
    // Hide quickview/wishlist buttons block
    .product-list__item .product-tile .product-tile__actions--secondary {
        display: none;
    }
    .product-list__item .product-tile {
        padding: 15px 0 !important;
    }
}

/* MMI-627 */
.paging-container .toolbar__pager {
    display: none !important;
}

/* MMI-1759 Blurry images fix on PLP */
.product-list .product-list__item .product-tile__image {
  width: 100%;
}