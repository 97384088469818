$save-button-container-height:    80px;
$view-full-details-button-height: 44px;

@mixin spinning-icon {
  &:before {
    @include icon-font;
    @include animate-spin-counter-clockwise;
    display: inline-block;
    content: '\e86a';
  }
}

@mixin quickview-background-image {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.quickview-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9991;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quickview {
  display: flex;
  border: 1px solid $color-menu-items-border;
  background-color: #FFFFFF;
  position: relative;
  // override js position.
  height: initial !important;
  left: auto !important;
  top: auto !important;
  min-height: 450px; /* In case there is no image to display for a product */

  .badge-container {
    position: absolute;
    right: 30px;
    top: 68px;
    .badge {
      @include badge;
      @include sale-badge;
    }
  }
}

.quickview__images {
  position: static;
  width: 50%;
  display: flex;
}
.quickview__thumbnails {
  width: 92px;
}
.quickview__thumbnail {
  position: relative;
  height: 20%;
  padding: 5px;
  @include spinning-icon;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    z-index: 0;
  }

  .quickview__thumbnail-image {
    @include quickview-background-image;
    cursor: pointer;
  }
}
.quickview__main-image {
  @include spinning-icon;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
  }
  position: relative;
  width: 100%;
  left: 0;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .quickview__main-image-image {
    @include quickview-background-image;
  }
}

.quickview__details {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  // width: 407px;
  border-left: 1px solid $color-menu-items-border;
  padding: 0 20px;
  flex-grow: 1;
}
.quickview__close-button {
  position: absolute;
  top: 15px;
  right: 9px;

  &:before {
    @include icon-font;
    content: "\e14c";
    display: block;
    /* Remove any space between the elements' top border and the top of the actual content (I.e. the X) */
    height: 17px;
    line-height: 17px;
  }
}

.quickview__brand {
  @include product-title;
  margin: 20px 70px 0 0;
}

.quickview__title {
  @include product-title;
  margin: 0px 70px 0 0;
  text-transform: capitalize;
}

.product__sizing {
  padding-top: 27px;
  .product__sizing--top {
    padding-bottom: 15px;
  }
}

.ReactModal__Content {
  .product__sizing {
    .product__sizing--top {
      .product__sizing-selector {
        height: auto;
      }
    }
  }

}

.quickview__add-to-bag-container {
  padding: 0 28px;
  //position: absolute;
  left: 0;
  right: 0;
  bottom: $save-button-container-height + $view-full-details-button-height;

  .quickview__add-to-bag-button {
    @include add-to-bag-button;
    padding-top: 5px;
  }
}

.quickview__save-container {
  //position: absolute;
  left: 0;
  right: 0;
  bottom: $view-full-details-button-height;
  text-align: center;
  height: $save-button-container-height;
  @include centerUnknown(parent);

  .quickview__save-button {
    @include centerUnknown(child);
    @include save-for-later-button;
    padding-top: 8px;
  }
}

.quickview__view-full-details-container {
  //position: absolute;
  left: 0;
  right: 0;
  bottom: 1px;

  .quickview__view-full-details-button {
    @include button-text-black;
    width: 100%;
    display: inline-block;
    background-color: $color-header-background;
    color: $color-top-info-bar-text;
    height: $view-full-details-button-height;
    line-height: $view-full-details-button-height; /* To vertically center the text */
  }
}

.product__stock-message {
  padding: 10px 0;
  font-style: italic;
  text-align: center;
}

/* Loader Img Block */
#quickview__loader-img img { // Center the loader icon
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
}

.quickview__adding-to-cart {
  // Make this look nicer
  display: none;
  height: 30%;
  width: 50%;
  position: absolute;
  z-index: 110;
  background-color: #FFF;
  border: 1px solid #666;
  /* Centre div */
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
}

/* Fixes for quickview */
.quickview-container .quickview {
  padding: 20px;
}

.quickview__add-to-bag-container {
  padding: 0;
}
.quickview__view-full-details-container .quickview__view-full-details-button {
  color: #FFF;
  border: 1px solid #000;
  background-color: #4a4b4f;
}