.site-footer__site-map--fusion-retail-brand {
  display: flex;

  .site-map__links-column {
    flex-grow: 1;
    text-transform: unset;

    h4 {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

}
