.creditmemo {
  width: 100%;

  thead {
    text-align: left;
  }

  thead, tbody {
    border-bottom: 1px solid $color-navigation-border;
  }

  td {
    padding: 5px 0;
  }

  caption {
    display: none;
  }

  .actions {
    text-align: right;

    .action {
      @extend %button;
      @extend %button--small;

      margin-bottom: $action-spacing;
    }
  }

  //Hide the 'Print All Refunds' button
  .actions-toolbar {
    display: none;
  }

  caption{
    display: none;
  }

  .order-title {
    font-size: 24px;
    padding: 10px 0 30px;

    .action { //Hide the print button
      display: none;
    }
  }
}

.order-items-creditmemo .name.giftcard .item-options {
  text-transform: initial !important;
}