/**
 * Styling for the login modal
 *
 * Note: These styles will apply to both the login/register combination modal, as well as the login form in the checkout
 *
 * This has been placed in a mixin because the same styling needs to be reused in both of the above mentioned modals,
 * however the HTML structure to each modal is slightly different. See individual components files for examples
 */

@mixin loginRegisterModal {
  &.modal-popup:not(.confirm) { // Specificity
    .action-close {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      padding: 0;
      min-width: 0;
      width: 20px;
      height: 20px;
      left: inherit;

      &:focus {
        outline: none;
      }

      &::after {
        color: #000000;
        margin-left: 0;
        top: 6px;
        right: 7px;
        font-size: 30px;
      }

      span {
        display: none;
      }
    }
  }

  .modal-inner-wrap {
    position: relative;
    padding: 25px;
    max-width: 589px;
    width: 100%;
  }

  .modal-content {
    text-align: center;
    padding: 10px 0 20px;

    @media (min-width: 550px) {
      padding: 10px 90px 20px;
    }
  }

  .block-title {
    font-size: 28px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .form-login {
    padding: 0 10px;
  }

  .field {
    padding-top: 20px;

    .control {
      padding-top: 5px;
    }
  }

  .label {
    text-align: left;
    text-transform: uppercase;
  }

  .actions-toolbar {
    padding-top: 20px;

    .secondary {
      padding-top: 11px;
    }
  }

  .modal-primary {
    .modal-action {
      @include widget-button;
      width: 100%;
      font-weight: 500;
      padding: 11px 0;
      box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .3), inset -2px -2px 2px rgba(0, 0, 0, .3)
    }
  }

  .modal-action {
    &.remind { // Forgot password link
      @include small-text(#999999 );
      font-family: $font-family-base;
    }
  }
}
