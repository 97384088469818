#product-attribute-specs-table tbody tr th {
  text-align: left;
}

.product__stock-message {
  color: $color-red;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  padding: 8px;
  flex: 1 100%;
}

.product-info__main .product-info__title,
.product-info__main .product-title__brand {
  text-transform: uppercase;
  font-family: $font-family-base;
}

.product-info__main .product-info__brand {
  display: none !important; // overriding important
}

/* MMI-1112 BV position fix */
[data-bv-show="rating_summary"] .bv_main_container {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

/* MMI-1161 Show BV stars on PDP */
.product-info__main .product-info__brand {
  display: block !important;
}
.product-info__main .product-title__brand {
  display: none !important;
}

/* MMI-1112 BV position fix */
.product-info__main .product-info__brand {
  position: static !important;
}

@media (min-width: 960px) {
  .product-info {
    margin-bottom: 30px;
  }
}

/* MMI-1759 PDP Hero image */
.fotorama__wrap .fotorama__stage__frame .fotorama__img {
    max-height: 94.20%;
    max-width: 79.5%;
}