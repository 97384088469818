/*------------------------------------*\
    #PRIMARY NAVIGATION
\*------------------------------------*/
.primary-navigation {
    position: static;
    width: 100%;
    display: flex;
    background-color: $color-navigation-background;
    color: $color-navigation-text;

    &,
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    a {
        display: block;
        color: $color-text;
        text-decoration: none;

        &:hover {
            color: $color-black;
            text-decoration: underline;
        }
    }

    // Level 0
    li.level0 {
        & > a {
            text-transform: uppercase;
            position: relative;
            padding: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: $heading-letter-spacing;

            &:after {
                @include icomoon('arrow-down');
                font-size: 15px;
                margin-left: 5px;
            }

            &:hover {
                text-decoration: none;
            }
        }

        // Open state
        &.level--open ul.level0 {
            pointer-events: all;
            opacity: 1;

            li.level1 {
                transform: none;
            }
        }

        // Default & transition out state
        ul.level0,
        &.level--out ul.level0 {
            opacity: 0;

            li.level1 {
                transform: translateY(-15px);
            }
        }

        // Transition in & out state
        &.level--in ul.level0,
        &.level--out ul.level0 {
            transition: opacity 300ms ease;

            li.level1 {
                transition: transform 300ms ease;
            }
        }

        // Hover & open state
        &.level--open > a,
        & > a:hover {
            background-color: $color-background;
        }
    }

    // Submenu
    ul.level0 {
        display: flex;
        pointer-events: none;
        z-index: 10;
        background-color: #fff;
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        right: 0;
        padding: 25px calc(((100vw - 78.75rem) / 2) - 25px);
        box-shadow: 0 6px 5px 0px rgba(0,0,0,0.1);
    }

    // Level 1
    li.level1 {
        flex: 1 1 auto;
        padding: 0 25px;
        border-right: 1px solid $color-navigation-border;

        & > .submenu-title {
            text-transform: uppercase;
            display: block;
            margin-bottom: 5px;
        }

        &:last-child {
            border-right: 0;
        }
    }

    // Hide desktop navigation on mobile
    @media (max-width: $breakpoint2 - 1px) {
        display: none;
    }
}
