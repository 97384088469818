@use "sass:math";
/*------------------------------------*\
    #ORDER SUMMARY
\*------------------------------------*/

.order-summary {
    @extend %wrapper;

    margin-bottom: $layout-gutter;
    text-align: center;
}

.order-summary__details {
    display: inline-block;
    margin: 0 (math.div($layout-gutter, 2));
}


.order-summary__details-label {
    &:after {
        content: ":";
    }
}


.order-summary__list {
    @extend %list-inline;
}

.order-summary__action {
    @extend %button;
    @extend %button--small;

    margin: 0 0.15em;
}
