.two-up__widget + .two-up__widget {
  padding-top: 15px;

  @include media-query(medium-and-up) {
    padding-left: 15px;
    padding-top: 0;
  }
}
.two-up {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @include media-query(medium-and-up) {
    height: 384px;
  }
}
.two-up ul,
.two-up li {
  margin: 0;
}
.two-up__widget {
  @extend %u-1-of-1;
  @extend %u-1-of-2-medium-and-up;

  box-sizing: border-box;
  height: 100%;
  vertical-align: top;

  @include media-query(medium-and-up) {
    display: inline-block;
  }
}
.two-up__widget ul {
  height: 100%;
}
.two-up__picture a {
  display: block;
  min-height: 275px;
  background-position: center;
  background-size: cover;
  text-indent: -9999px;
  height: 100%;
}
.two-up__picture,
.two-up__content {
  @extend %u-1-of-1;
  @extend %u-1-of-2-medium-and-up;

  vertical-align: top;
  height: 100%;
  padding-bottom: 20px;

  @include media-query(medium-and-up) {
    display: inline-block;
    padding: 0;
  }
}
.two-up__content {
  position: relative;
  text-align: center;
  background-color: $widget-background-color;
  @include centerUnknown(parent);
  font-size: 0;
}

.two-up__content-child {
  @include centerUnknown(child);
  padding:0 20px;
  font-size:24px;
  line-height: 1.1em;
}

.two-up__title-container {
  margin:0 0 17px;
}

.two-up__title {
  @include font-widget-title;
  font-size: 30px;
  line-height:1.1em;
  margin:0;


  @include media-query(large-and-up) {
    font-size:36px;
  }

}


.two-up__button-container {
  margin:20px 0 0 0;

  @include media-query(medium-and-up) {
    position: absolute;
    bottom:40px;
    left:0;
    right:0;
  }


}
