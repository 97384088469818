@mixin discount-coupon {
  @extend %u-1-of-1;
  @extend %u-2-of-3-medium-and-up;
  padding: 30px 20px;
  background-color: #F7F7F7;

  .label {
    @include functional-text-black;
    padding-bottom: 20px;
  }
  .input-text {
    &::placeholder {
      @include functional-text-black;
    }
    border-radius: 0;
    border: 1px solid #000;
    height: 43px;
    padding: 0 12px;
  }

  .actions-toolbar {
    padding-top: 10px;
  }

  .action-apply, /* This is the class applied to the Apply button in the checkout */
  .action.apply {
    width: 100%;
    border: 2px solid $color-shop-accent;
    @include functional-text-black;
    color: $color-shop-accent;
    height: 43px;
    font-weight: 500;
  }
}
