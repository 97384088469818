/*------------------------------------*\
    #PRODUCT-TILE
\*------------------------------------*/

.product-list__item {
  /*li*/

  /*div*/
  .product-tile {
    text-align: left;
    padding: 15px;
    border: 1px solid transparent;
    position: relative;

    .badge-container {
      position: absolute;
      right: 15px;
      .badge {
        @include badge;
        @include sale-badge;
        font-size: 12px;
      }
    }

    .product-tile__actions--secondary {
      visibility: hidden;
      margin-top: 20px;

      &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
      }

      .product-tile__action--add-to-wishlist {
        float: left;

        &:before {
          @include icon-font;
          content: "\e87d";
          vertical-align: middle;
        }
      }

      .product-tile__action--quick-view {
        float: right;

        &:before {
          @include icon-font;
          content: "\e417";
          vertical-align: middle;
        }
      }
    }

    @include media-query(medium-and-up) {
      &:hover {
        border-color: $color-menu-items-border;

        /* Only display the Save and QuickView buttons if the product is in stock, and therefore has data to display */
        div[data-product-id] ~ .product-tile__actions--secondary {
          visibility: visible;
        }
      }
    }

    .product-tile__link--image {
      text-align: center;
      outline: none;
    }

    /*a*/
    .product-tile__link {
      @include functional-text-black;
      text-decoration: none;
    }

    .product-tile__link--image {
      display: block;
    }

    /*h2*/
    .product-tile__title {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      margin-bottom: 0;
      padding: $spacing-unit-base--small 0;
      text-decoration: none;
    }

    .product-tile__actions form {
      display: none;
    }

    .product-tile__attribute {
      @include product-tag;
      white-space: nowrap;
    }
  }

  .product-title__brand {
    display: $product-list-brand-display;
  }
}

.price-final_price {
  display: inline-block;

  /*Both the special and old price labels need to be hidden*/
  .price-label {
    display: none;
  }

  .special-price {
    margin-left: 5px;
    .price {
      color: $color-sale;
    }
  }

  .price,
  .stock.unavailable {
    @include functional-text-black;
    font-weight: 500;
  }

  .old-price {
    float: left;
    .price {
      text-decoration: line-through;
    }
  }

  &:after {
    /* Clearfix */
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .minimal-price-link {
    text-decoration: none;

    &:after {
      content: "-";
      display: inline-block;
      font-weight: 600;
    }
  }
}

.product-list__item .product-tile .product-tile__link {
  text-transform: capitalize;
}

.product-tile__actions--secondary .tocompare {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.product-list__item .product-title__brand ,.product-list__item .product-tile__link--name{
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 0;
  padding: 0;
}
