.product-info__main .product-info__add-to-cart {
    display: flex;
    flex-flow: row wrap;
}

.product-info__main .product-addto-links .towishlist,
.product-info__main .product-addto-links .towishlist:before {
    color: $color-diana-ferrari-blue;
}
.product-info__main .product__stock-message {
    flex: 1 100%;
}

.product-info__main .product-addto-links {
    flex: 1 0 0;
    border: 1px solid $color-diana-ferrari-blue;
    height: 40px;
    padding: 8px;
    margin: 7px 0 0;
}
.product-info__main .product-addto-links .towishlist span {
    position: relative;
    top: -1px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}

.product-info__main .product-addto-links .towishlist:before {
    margin-right: 0px;
}

.product-info__main .add-to-cart__submit {
    flex: 1 0 0;
    height: 40px;
    padding: 8px;
    margin: 7px 7px 0 0;
    color: #ffffff;
}
.product-info__main .add-to-cart__submit span {
    position: relative;
    top: -27px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}

@media (max-width: 640px) {
    .product-info__main .product-addto-links {
        flex: 1 100%;
        margin: 7px 0px 0px 0;
    }

    .product-info__main .add-to-cart__submit {
        flex: 1 100%;
        margin: 7px 0px 10px 0;
        padding: 0;
    }
    .product-info__main .add-to-cart__submit span {
        top: -18px;
    }
}

.product-info__details {
    margin-top: 15px;
}
