/**
 * Styles for PLP React app star rating
 */

.ratings-star {
  display: flex;
  align-items: center;
  margin-top: 8px;

  &.ratings-star--empty {
    height: 24px;
  }

  .ratings-star__count {
    font-size: 15px;
    vertical-align: middle;
    line-height: 1.3;
  }
}

.ratings-star__value {
  $star-colour: #FFD700;
  $star-size: 15px;
  $star-spacing: 1px;

  position: relative;
  margin-right: 0.4rem;

  &::before {
    content: "\f005 \f005 \f005 \f005 \f005";
    font-family: "Font Awesome 5 Free";
    font-size: $star-size;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: $star-colour;
    letter-spacing: $star-spacing;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    &::before {
      content: "\f005 \f005 \f005 \f005 \f005";
      font-size: $star-size;
      font-style: normal;
      font-weight: 900;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-family: "Font Awesome 5 Free";
      color: $star-colour;
      letter-spacing: $star-spacing;
    }

    span {
      position: absolute;
      overflow: hidden;
      clip: rect(0 0 0 0);
      height: 1px;
      width: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }
}
