.navigation-bar-scrolled {
    #primary-navigation {
        background-color: transparent !important;
    }

    .mobile-logo {
        display: none;
    }

    .site-header__logo-container {
        padding-top: 8px;

        img {
            max-height: 25px;
        }
    }

    .account-navigation {
        height: 100%;
    }

    .navigation-bar__group--account > li > a:before,
    .account-navigation__item > .showcart:before {
        font-size: $scrolled-nav-icon-font-size;
    }
}


