/**
 * Styling for the Afterpay Learn More modal on PDP
 */

.afterpay-findoutmore-modal {
  $breakpoint: 758px;

  .modal-title {
    display: none;
  }

  // .action-close is nested like this to overcome specificity
  .modal-inner-wrap {
    max-width: 1100px;
    padding: 0;

    .modal-header {
      position: relative;

      .action-close {
        position: absolute;
        top: 10px;
        right: 10px;
        min-width: 0;
        width: 20px;
        height: 20px;
        background-color: #FFF;
        padding: 0;
        border: 0;

        span {
          display: none;
        }

        &::after {
          margin-left: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #000000;
          font-size: 31px;
        }
      }
    }
  }

  .modal-title {
    padding: 0;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }

  .modal-content {
    padding: 15px;
    margin-top: 10px;

    @media (min-width: $breakpoint) {
      padding: 25px 80px;
    }
  }

  .logo-container {
    text-align: center;
    max-width: 180px;
    margin: 0 auto;
  }

  .subtitle-container {
    text-align: center;
    font-weight: 600;
    font-size: 19px;
  }

  .columns {
    font-size: 0;
    padding: 15px 0 20px;
    border-bottom: 1px solid #000000;
  }

  .column {
    font-size: 16px;
    vertical-align: top;
    text-align: center;
    padding-bottom: 20px;

    @media(min-width: $breakpoint) {
      display: inline-block;
      width: calc(100% / 3);
      padding: 0 10px;
    }
  }

  .column-title {
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
  }

  .column-title__image {
    width: 85px;
    margin: 0 auto;
    padding-bottom: 15px;

    img {
      max-width: 100%;
    }
  }

  .column-content {
    padding: 0;
    font-size: 14px;
    max-width: 65%;
    margin: 0 auto;

    @media(min-width: $breakpoint) {
      padding: 0;
    }
  }

  .details-container {
    font-size: 0;
    padding-top: 25px;
  }

  .detail-column {
    font-size: $font-size-base;
    vertical-align: top;

    @media(min-width: $breakpoint) {
      width: 50%;
      display: inline-block;
    }

    &:last-child {
      .detail__list-item {
        padding-bottom: 15px;
      }
    }
  }

  .detail-container {
    padding-bottom: 15px;

    @media (min-width: $breakpoint) {
      padding-bottom: 0;

      &:nth-child(2) {
        padding-top: 30px;
      }
    }
  }

  .detail-title {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 3px;
  }

  .detail__list {
    padding: 0;
    margin: 0;
    font-size: 14px;
    list-style-position: inside;
  }

  .detail__list-item {

  }

  .list-item__content {
    margin-left: -9px;
  }

  .footer {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #8E8E8E;
    margin-top: -15px;

    @media (min-width: $breakpoint) {
      margin-top: -35px;
    }
  }
}

.afterpay__find-out-more-modal-trigger {
  font-weight: 600;
}

.afterpay-findoutmore-modal-content {
  // Make sure the modal content doesn't flash up on the page while the checkout waits for the modal content to be registered as a modal
  display: none;
  .modal-content & {
    display: block;
  }
}

@media (max-width: 400px) {
  .four-up-icon-wrapper .four-up-icon {
     display: flex;
     margin-bottom: 12px !important ;
     align-items: center;
  }
}

@media (max-width: 380px) {
  .two-up-section-info-block {
    margin-top: 0px !important;
  }
  .four-up-icon-wrapper {
    margin-bottom: 13px!important;
    margin-top: -10px !important;
  }
  .four-up-icon-wrapper .four-up-icon {
    margin-bottom: 8px!important;
  }

  .ap-header-section {
    margin-top: 2px!important;
  }
  .ap-header-section  img{
    margin-bottom: 5px !important;
  }
}

@media (max-width: 300px) {
  .modal-popup.afterpay-modal.afterpay-findoutmore-modal._show {
    position: absolute !important;
  }
}

  .modal-popup.afterpay-modal.afterpay-findoutmore-modal._show {
    position: fixed;
    top: 0px !important;
  }