/*------------------------------------*\
    #BUTTON
\*------------------------------------*/

/**
 * Use a mixin to define button styles, so they can be applied inside
 * media queries.
 **/

$button-height: $form-input-height !default;

@mixin button {
    background-color: $color-shop-accent;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    color: $color-background-content;
    display: inline-block;
    min-width: rems(120px);
    padding: rems(9px) rems(24px);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}


/**
 * A fixed height button modifier.
 *
 * Variable height buttons may vary in height due to font-size or differences
 * in the rounding methods used by browsers. Use this fixed height element
 * next to inputs (which should always be fixed height due to cross-browser
 * line-height differences).
 **/

@mixin button--fixed {
    height: $button-height;
    padding: {
        bottom: 0;
        top: 0;
    }
    &:before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle;
    }
}


/*a, button*/
.button,
%button {
    @include button;
}

%button {
    @include button;
}

.button--fixed,
%button--fixed {
    @include button--fixed;
}

.button--outline,
%button--outline {
    background: none;
    border-color: currentColor;
    color: $color-shop-accent;
}

.button--small,
%button--small {
    @include font-small;
    min-width: rems(100px);
    padding: rems(4px) rems(14px);
}

.button--full {
  width: 100%;
}