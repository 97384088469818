#js-aligent_fredhopper-autocomplete-wrapper {
  #js-aligent_fredhopper-autocomplete-search {
    border: solid $color-border-outline;
    border-width: 0 0 1px;
    padding: 9px 120px 8px 20px;
    border-radius: 3px 0 0 3px;
    font-size: 16px;

    @include placeholder {
      text-transform: none;
      color: $color-input-placeholder;
    }
  }

  .minisearch__control--submit {
    border: solid #666666;
    border-width: 0 0 1px;
    position: relative;
    color: $color-black;
    background-color: $color-background;
    border-radius: 0 3px 3px 0;
    padding: 5px 10px;

    &:hover {
      background-color: $color-background;
    }
  }

}

/* MMI-371 */
@media (max-width: 960px) {
  .site-header__banner .siteHeaderBannerInnerContent > * {
    display: none;
  }
  #js-aligent_fredhopper-autocomplete-results {
    top: -10px;
  }
}