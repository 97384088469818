@mixin cart-totals {
  table {
    width: 100%;
    margin-bottom: 0;
  }

  .table-caption {
    @include functional-text-black;
    text-align: left;
    padding-bottom: 25px;
  }

  th {
    @include functional-text-black;
    text-align: left;
    padding-bottom: 25px;
    width: 50%;
    font-weight: 400;
  }

  td {
    text-align: right;
    width: 50%;
    vertical-align: top;
  }

  tr:last-child {
    th, td {
      padding-bottom: 10px;
    }
  }

  .grand.totals td span {
    @include functional-text-black;
    font-weight: 600;
  }
}
