.modal-popup.confirm {
  .modal-inner-wrap {
    position: relative;
  }

  .modal-header {
    position: absolute;
    top: 0;
    right: 0;

    .action-close {
      &:before {
        @include icon-font;
        content: '\E5CD';
      }

      /* Hide the word 'Close' on the button (replaced with an X icon above) */
      span {
        display: none;
      }
    }
  }

  .modal-content {
    padding-bottom: 50px;
  }

  .modal-footer {
    @include clear();

    .action-dismiss {
      @include widget-button;
      @include functional-text-black;
      background-color: #fff;
      border: 1px solid $color-navigation-border;
      float: left;

    }
    .action-accept {
      @include widget-button;
      float: right;
    }
  }
}

.modals-overlay {
  background-color: #000000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity $transition-time;

  ._show ~ & {
    opacity: 0.4;   // Increase the opacity when the modal is shown
  }
}
