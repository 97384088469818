.munro-accordion {
    margin-top: 30px;
    p {
      margin-bottom: $layout-gutter;
    }
    section {
      border-top: 1px solid #000;
      padding: 0 10px;
       &:last-child {
        border-bottom: 1px solid #000;
      }
    }
     .accordion-title {
      @include functional-text-black;
      padding: 7px 0 3px 0;
      cursor: pointer;
       &:after {
        @include icon-font;
        font-size: 18px;
        content: '\e145';
        display: inline-block;
        float: right;
        margin-right: 10px;
      }
       &.open:after {
        content: '\e15b';
      }
    }
     .accordion-content {
      padding: 5px 0;
    }
  }