
.checkout-app-form {
  font-family: $font-family-base;
  font-size: 1rem;
}

.checkout-app-form .select-container,
.checkout-app-form .input-container {
  margin: 12px 0;
}

.checkout-app-form .select-container:first-child,
.checkout-app-form .input-container:first-child {
  // margin-top: 0;
}

.checkout-app-form .input-field--password,
.checkout-app-form .input-field--email,
.checkout-app-form .input-field--tel,
.checkout-app-form .input-field--text,
.checkout-app-form .autocomplete-container,
.checkout-app-form .select-container,
.checkout-app-form .input-container {
  display: flex;
  flex-direction: column;

  .input-field__label,
  label {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 4px;
    line-height: 22px;
    color: $color-black;
    text-transform: uppercase;
    cursor: pointer;
  }

  .input-field__input,
  input {
    border: 1px solid $color-border-outline-light;
    border-radius: 5px;
    padding: 10px 15px;

    &:focus {
      border: 1px solid $color-black;
      outline: none;
    }
  }

  &.promo-code-input-container .form-input {
    border-radius: 5px 0 0 5px;
  }

  select {
    border: 1px solid $color-border-outline-light;
    border-radius: 5px;

    &:focus {
      border: 1px solid $color-black;
      outline: none;
    }
  }
}

.checkout-app-form {
  .account-newsletter-container label,
  .same-as-shipping-container label {
    font-size: 1rem;
  }
}

.checkout-app-form .same-as-shipping-container label {
  font-weight: normal;
  text-transform: inherit;
}


.checkout-app-form .field-group.field-group--horizontal {
  display: flex;

  flex-direction: row;
  flex: 0 0 auto;
  justify-content: space-between;

  width: 100%;
  margin: 6px 0;
}

.checkout-app-form .input-field--reset-password-button {
  margin-left: auto; // keeps this item aligned right
}

.checkout-app-form .input-field--radio,
.checkout-app-form .input-field--checkbox,
.checkout-app-form .modifier {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

.checkout-app-form .input-field--checkbox .input-field__label,
.checkout-app-form .modifier label {
  margin-left: 7px;
  font-size: 1rem;

  cursor: pointer;
}

.checkout-app-form .input-field--checkbox .input-field__input ,
.checkout-app-form .modifier input {
  appearance: none;
  position: relative;
}

.checkout-app-form .input-field--checkbox .input-field__input:checked::before,
.checkout-app-form .modifier input[type='radio']:checked::before,
.checkout-app-form .modifier input[type='checkbox']:checked::before {
  content: '✔';

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  color: $color-shop-accent;
  font-size: 18px;
  line-height: 22px;
}

.checkout-app-form .input-field--checkbox .input-field__input::after,
.checkout-app-form .modifier input[type='radio']::after,
.checkout-app-form .modifier input[type='checkbox']::after {
  content: '';

  width: 20px;
  height: 20px;
  display: block;

  border: 2px solid $color-border-outline;
  border-radius: 4px;

  cursor: pointer;

}

.checkout-app-form .input-field--as-button .input-field__input {
  display: none;
}

.checkout-app-form .input-field--as-button .input-field__label {
  cursor: pointer;
}

.checkout-app-form  .account-newsletter-container {
  margin-top: 2rem;
}

.checkout-app-form  .validation-error {

  input,
  select {
    border-color: red;
  }

}

.deliver-manual-address {
  margin-bottom: 1rem;
}

.manual-address-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -1rem;

  .input-container,
  .select-container {
    width: 100%;
    padding: 0 1rem;
  }

  .region-manual-billing-address-container,
  .region-manual-shipping-address-container {
    width: 100%;
  }

  .react-autosuggest__container {
    position: relative;
    width: 100%;
    margin: 12px 0;
  }

}

.checkout-app-payment__label {
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 4px;
  line-height: 22px;
  color: $color-black;
  text-transform: uppercase;
  cursor: pointer;
}

.checkout-app-payment__message {
  text-align: center;
  margin: 1rem 0;
}

.checkout-app-form__atl {
  margin-top: 1rem;
  border-top: 1px solid $color-grey;
}
