/*------------------------------------*\
    #SIDEBAR NAVIGATION
\*------------------------------------*/

@include private {
  $breakpoint: medium-and-up;

  .sidebar-navigation {
    margin-bottom: $layout-gutter;
  }

  .sidebar-navigation__heading {
    @extend %u-hidden-#{$breakpoint};
    @include functional-text-black;

    display: block;
    font-size: inherit;
    margin-bottom: 0;
    border-bottom: 1px solid #000;
    padding: 0 15px 0 0; // Center the text including the icon
    text-align: center;
    position: relative;
    cursor: pointer;

    &:after {
      @include icon-font;
      content: '\e313';
      display: inline-block;
      position: absolute;
      top: -1px;
    }

    &._open:after {
      content: '\e316';
    }
  }

  .sidebar-navigation__list {
    @extend %collapse;
    @extend %list-bare;

    @include functional-text-black;

    @include media-query($breakpoint) {
      height: auto;
      visibility: visible;
    }

    // Ideally we'd use BEM to style elements to avoid specificity issues
    // with multiple selectors.
    .nav {
      border-color: black;
      border-style: solid none;
      border-top-style: none;
      border-width: 1px;
      display: table; // Ensure children using `table-cell` occupy the full width this element
      width: 100%;

      @include media-query($breakpoint) {
        &:first-child {
          border-top-style: solid;
        }
      }

      & > * {
        display: table-cell; // Easy vertical alignment on variable-height elements
        height: rems(40px); // `height` on table-cell behaves like `min-height`
        padding: 4px 8px;
        text-decoration: none;
        transition: background ($transition-time * 1.5);
        vertical-align: middle;
      }

      & > a:hover {
        background-color: $color-background;
      }

      &.current > * {
        color: $color-shop-accent;
      }
    }
  }
}
