/* Cart Header */
.cart-page-header {
  display: flex;
  flex-direction: column;
  @media (min-width: 593px) {
    flex-direction: row;
  }
  padding-top: 0;
  padding-bottom: 14px;

  .cart__list--action {
    /*
    Can't use the "@extend %u-1-of-4;" helpers here because I need custom breakpoints
  */
    width: 100%;
    padding: 0;
    @media (min-width: 593px) {
      width: 25%
    }
    @include small-text-black;
    display: flex;
    align-items: center;

    &:before {
      @include icon-font;
      content: "\e5cb";
      font-size: 20px;
      margin-right: 2px;
    }

    a {
      text-decoration: none;
    }
  }
}

/* This needs to be here, starting from the ID, to overcome another CSS rule */
#maincontent .cart-page-heading {
  flex-grow: 1;
  h1 {
    margin: 10px 0 20px 0;
    @media (min-width: 593px) {
      margin-bottom: 0;
    }
    padding: 0;
  }
}
.checkout-button-container {
  /*
    Can't use the "@extend %u-1-of-4;" helpers here because I need custom breakpoints
  */
  width: 100%;
  @media (min-width: 593px) {
    width: 25%
  }
  display: flex;
  align-items: center;

  .cart__action--checkout {
    @include checkout-button;
  }
}
/* End of Cart Header */

/* Cart Table */
.cart-table__tbody {
  border-bottom: 1px solid #000;
}
#shopping-cart-table {
  width: 100%;
  @include functional-text-black;

  dl {
    margin-bottom: 0;
  }

  thead {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

    th {
      font-weight: normal;
      padding: 3px 0;
      text-align: center;
      width: 20%;

      &:first-child {
        padding-left: 80px;
        text-align: left;
        width: 39%
      }
    }
  }

  tbody {
    td {
      text-align: right;
      @media (min-width: 400px) {
        text-align: center;
      }
      &:first-child {
        padding-left: 10px;
        text-align: left;
      }
    }

    /*
      The second <tr> is only visible on mobile, so when viewing on desktop, the first <tr> needs to have the
      bottom padding applied to it, which is the reason for the media query just below.
      When on mobile, the second (or last) <tr> is the one that should have the bottom padding, not the first
    */
    tr:first-child td:first-child {
      padding-top: 25px;
      @include media-query(small-and-up) {
        padding-top: 62px;
      }
    }
    tr:last-child td:first-child {
      padding-bottom: 25px;
    }

    .new-cart-page-rule-description-container {
        .cart-page-rule-description {
            .rule-description {
                color: $color-rule-coupon-green;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                span {
                    i {
                        width: 1px;
                        height: 12px;
                        background-color: $color-rule-coupon-green;
                        display: inline-block;
                        margin: 0 10px;
                    }
                }
            }
        }
    }
    .cart-info-mobile.new-cart-page-rule-description-container{
        .cart-page-rule-description {
            .rule-description {
                line-height: 25px;
            }
        }
    }

    .child-one {
        .cart-info-desktop {
            display: none;
            @include media-query(small-and-up) {
                display: table-row;
            }
        }
    }

    @include media-query(small-and-up) {
      tr:first-child td:first-child {
        padding-bottom: 25px;
      }
      .child-one {
          width: 100%;
          tr:first-child td:first-child {
              width:25%;
              padding: 0;
          }
          .sub-child-one {
              width: 100%;
              .cart-item-info {
                  padding:0 0 32px;
              }
              .cart-item-actions-container {
                  padding: 0px;
              }
              .cart-page-rule-description {
                  padding: 64px 0 0 !important;
                  width:100% !important;
              }
              .equal_table_colums td {
                  width: 25%;
              }
          }
      }
    }
  }

  .cart-table__header--size,
  .cart-table__data--size,
  .cart-table__header--price,
  .cart-table__data--price {
    display: none;
    @include media-query(small-and-up) {
      display: table-cell;
    }
  }

  .cart-info-mobile {
    @include media-query(small-and-up) {
      display: none;
    }
    font-size: 0;

    td {
      padding-top: 20px;
    }

    .cart-item-options-mobile {
      @extend %u-1-of-2;
      display: inline-block;
      font-size: medium; /* IE Fix */
      font-size: initial;
    }
    .cart-item-price-mobile {
      @extend %u-1-of-2;
      display: inline-block;
      font-size: medium; /* IE Fix */
      font-size: initial;
      text-align: right;
    }
    .cart-info-mobile__actions-container {
      text-align: right;
        button{
            width:auto !important;
        }
    }
  }

  .cart-table__image-container {
    text-decoration: none;
  }

  .cart-table__name {
    @include functional-text-black;
    text-transform: capitalize;
    font-size: 24px;
    text-decoration: none;
    margin-bottom: 35px;
  }

  .cart-table__data--qty {
    vertical-align: top;
    padding-top: 50px;
    @media (min-width: 400px) {
      padding-top: 90px;
    }
    white-space: nowrap;
    @include media-query(small-and-up) {
      vertical-align: middle;
      padding-top: 0;
    }

    .cart-table__input {
      width: 43px;
      height: 43px;
      border: 1px solid #000;
      border-radius: 0;
      text-align: center;
      display: block;
      margin: 0 0 20px auto;
      @media (min-width: 400px) {
        display: inline-block;
        margin: 0 15px 0 0;
      }
    }
  }
  .cart__action--update {
    display: block;
    margin: 0 10px 20px auto;

    @media (min-width: 400px) {
      display: inline-block;
      margin: 0 21px 0 0;
    }

    &:before {
      @include icon-font;
      content: '\e5d5';
      font-size: 23px;
    }
  }
  .cart-table__action--edit {
    text-decoration: none;
    display: block;
    margin-right: 6px;
    @media (min-width: 400px) {
      display: inline-block;
    }
  }

  .cart-item-image-container {
    display: inline-block;
    vertical-align: top;
  }

  .cart-item-info {
    display: block;
    @include media-query(small-and-up) {
      padding-left: 30px;
      display: inline-block;
    }
  }

  .cart-item-actions-container {
    display: none;
    @include media-query(small-and-up) {
      display: block;
    }
    padding-top: 35px;

    .cart-table__action--delete { /* button */
      @include small-text-black;
      vertical-align: top;
      display: block;
      margin-bottom: 10px;
      @media (min-width: 1093px) {
        display: inline-block;
      }
      @include media-query(small-and-up) {
        margin-right: 44px;
        margin-bottom: 0;
      }
    }

    a {
      @include small-text-black;
      text-decoration: none;
      vertical-align: top;
      display: block;

      @include media-query(small-and-up) {
        display: inline-block;
      }

      span {
        vertical-align: top;
      }
    }
  }

  .cart-info-mobile__actions-container {
    font-size: 0;
    padding-top: 10px;

    @include media-query(small-and-up) {
      display: none;
    }

    .cart-table__action--delete {
      @extend %u-1-of-2;
      @include small-text-black;
      display: inline-block;
      vertical-align: top;
      text-align: left;
    }

    a.action {
      @extend %u-1-of-2;
      @include small-text-black;
      text-decoration: none;
      display: inline-block;
      vertical-align: top;
      text-align: right;
    }
  }

}
/* End Of Cart Table */

/* Cart Footer */
.cart-summary {
  font-size: 0;
  @include clear();
}

.cart-summary-left-column {
  @include media-query(medium-and-up) {
    float: left;
  }
}

.cart-summary-right-column {
  padding-bottom: 20px;
  @include media-query(medium-and-up) {
    float: right;
    padding-bottom: 0;
  }
}

.cart-summary-left-column,
.cart-summary-right-column {
  @extend %u-1-of-1;
  @extend %u-1-of-2-medium-and-up;
  display: inline-block;
  font-size: medium; /* IE Fix */
  font-size: initial;
  vertical-align: top;

  /*
    This is hiding the "Estimate Shipping and Tax" section. It can't be hidden using XML, because the JS that populates
    the cart totals section depends on this being in the DOM (for some reason)
    I have made sure to restrict it to only elements with the 'cart__shipping' class that are inside the '.cart-summary-right-column'
    div because that is a custom container that has been added to just this page, so there is no chance of this CSS rule
    affecting another part of the site that does require a '.cart__shipping' element
  */
  .cart__shipping {
    display: none;
  }
}

#cart-totals {
  @include cart-totals;
  @extend %u-1-of-1;
  @extend %u-2-of-3-medium-and-up;
  margin-left: auto;
  margin-bottom: 20px;
  border-bottom: 1px solid #000;

  table.totals {
    .table-caption {
      display: none;
    }
  }
}

.cart__list--action { /* ul */
  margin: 0;
  padding: 0 0 20px 0;
  list-style: none;

  .cart__list-item {
    @extend %u-1-of-1;
    @extend %u-2-of-3-medium-and-up;
    margin-left: auto; /* Push element all the way to the right */
  }

  .cart__action--checkout { /* button */
    @include checkout-button;
  }
}

/*   Accepted Payment Methods */
.payment-methods {
  list-style: none;
  margin: 0;
  padding: 0 0 40px 0;

  li {
    display: inline-block;
  }
}
/*   End Of Accepted Payment Methods */

/*   Cart Info Links */
.cart-info-links {
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
  }

  li {
    @include info-list-item;

    @include functional-text-black;
    font-size: 20px;
    text-transform: none;

    a:before {
      margin-right: 15px;

      display: inline;

      @include media-query(medium-and-up) {
        display: inherit;
      }
    }

    + li {
      margin-top: 15px;
    }
  }
}
/*   End Of Cart Info Links */
/* End Of Cart Footer */

/* Coupon Block */
#block-discount {
  @include discount-coupon;
  margin-left: auto;
}
/* End Of Coupon Block */

/* 1623 - Ship Here Button */
.one-page-checkout__shipping-address .shipping-address-item .action-select-shipping-item {
  display: none;
}

/* MMI-330 */
#shopping-cart-table .cart-table__data--price .sale-item-cart-rrp, .cart-item-price-mobile .sale-item-cart-rrp {
  color: $color-mid-grey;
  text-decoration: line-through;
}
#shopping-cart-table .cart-table__data--price .sale-item-cart-price, .cart-item-price-mobile .sale-item-cart-price {
  color: $color-sale;
}
@media (max-width: 960px) {
  .cart-item-price-mobile .sale-item-cart-rrp {
    display: block;
  }
}

/* MMI-337 - discounted line items in cart */
.price-including-tax, .price-excluding-tax {
  .discounted-item-price-wrapper {
    white-space: nowrap;
  }

  .discounted-item-cart-rrp {
    color: $color-mid-grey;
    text-decoration: line-through;
  }

  .discounted-cart-coupon-message {
    color: $color-cart-coupon-message;
    font-size: 80%;
    text-transform: uppercase;
  }

  .discounted-cart-coupon-code {
    font-weight: bold;
  }
}
