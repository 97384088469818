.react-plp-app__sorting-controls-container {
	display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 12rem;
  width: 50%;

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;

  .select-label {
    display: none;
    padding-right: 1rem;
  }

  .select-wrapper {
    flex-grow: 1;
  }
}
