/*------------------------------------*\
    #MIXINS
\*------------------------------------*/

/**
 * private {
 *    // Content goes here
 * }
 *
 * Create a new scope without introducing any nesting.
 **/

@mixin private {
    @content;
}





/**
 * aligent-ui-link
 *
 * Remove the text-decoration from <a> tags, except on hover.
 *
 * Useful for styled UI elements, such as menus, buttons, etc.
 **/

@mixin aligent-ui-link {
    text-decoration: none;

    &:focus, &:hover {
        text-decoration: underline;
    }
}





/**
 * aligent-vertical-align
 *
 * Taken from http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
 **/

@mixin aligent-vertical-align {
    position: relative;
    text-decoration: inherit;
    top: 50%;
    transform: translateY(-50%);
}





/**
 * aligent-font-snap($font-size)
 *
 * $font-size: the desired font size, in rems
 *
 * Adjust the line-height based on the font-size to keep it an exact multiple
 * of the base line height for consistent vertical spacing.
 **/

@mixin aligent-font-snap($font-size) {
    font-size: $font-size;
    line-height: ceil($font-size / $spacing-unit-base) * ($spacing-unit-base / $font-size);
}


/**
 * aligent-mb-snap($height)
 *
 * $height: the height of the element, in rems
 *
 * Adjust the bottom margin based on the height to keep it an exact multiple
 * of the base spacing unit for consistent vertical spacing.
 **/

@mixin aligent-height-snap($height, $margin: null) {
    margin-bottom: ceil($height / $spacing-unit-base) * ($spacing-unit-base / $height) - $height;
}
