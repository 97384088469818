.shipping-tracking-popup {
  //Hide the top info bar and the mobile header
  .site-header__mobile-top,
  .mobile-navigation-container,
  .site-header__strip {
    display: none;
  }

  h1 {
    @media (max-width: 350px) {
      @include large-text;
      text-align: left;
    }
  }

  .page.tracking {
    @media (min-width: 351px) {
      text-align: center;
    }

    .order.caption {
      font-weight: 600;
      @include media-query(small-and-up) {
        @include large-text;
        font-weight: normal;
      }
    }

    .table {
      width: 100%;
      @include media-query(small-and-up) {
        width: 30%;
      }
      margin: 20px auto 0 auto;

      caption {
        font-weight: 600;
        padding-bottom: 10px;
        text-align: left;
        @media (min-width: 351px) {
          text-align: center;
        }
      }

      tbody {
        @media (max-width: 350px) {
          th, td {
            text-align: left;
          }
        }
        @media (min-width: 351px) {
          th {
            text-align: left;
          }
          td {
            text-align: right;
          }
        }

      }
    }
  }

  .action.close {
    @include widget-button;
    margin-top: 20px;

    &:after {
      @include icon-font;
      content: "\e5cd";
      display: inline-block;
      margin-left: 5px;
      font-size: 22px;
      position: absolute;
      top: 6px;
    }
  }
}
