.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
    text-align: left;
    border-radius: 0px;
    outline: 0;
}

@media (max-width: 960px) {
    .product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
        text-align: center;
        border-radius: 0px;
        outline: 0;
    }
}

.product-notify-me-block-wrapper .product-notify-me-heading,
.product-notify-me-thanks-wrapper .product-notify-me-heading {
    font-family: classico-urw, sans-serif;
}
.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-note {
    font-weight: 300;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-info .boldwrapper {
    font-weight: bold;
}

.product-notify-me-block-wrapper .product-notify-me-heading,
.product-notify-me-block-wrapper .product-notify-me-in-stock-info,
.product-notify-me-thanks-wrapper .product-notify-me-heading {
    font-weight: inherit;
}

.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-submit {
    height: 40px;
    background-color: $color-diana-ferrari-blue;
    padding: 0;
    outline: 0;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-button,
.product-notify-me-thanks-wrapper .product-notify-me-continue-button {
    height: 40px;
    background-color: $color-diana-ferrari-blue;
    padding: 5px;
    font-size: 20px;
}

.product-notify-me-block-wrapper .product-notify-me-in-stock-button:before {
    @include icomoon("cart");
    font-size: 38px;
    position: relative;
    top: -2px;
}

.product-notify-me-wrapper .product-notify-me-btn {
    border: 1px solid #bcc2cb;
}

.product-notify-me-wrapper .product-notify-me-btn,
.product-notify-me-block-wrapper .product-notify-me-form .product-notify-me-email {
    height: 40px;
}
