.material-icons {
  @include icon-font;
}


.icon-local_shipping:before {
  content: '\e558';
}

.icon-sync:before {
  content: '\e627';
}

.icon-local_phone:before {
  content: '\e551';
}