.react-plp-product-filter--active-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.react-plp-product-filter__aspect {
  width: auto;
}

.react-plp-product-filter__button {}

.react-plp-product-filter__button:hover {
  background: transparent;
}
