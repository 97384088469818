.action.return {
  @extend %button;
  @extend %button--small;

  //background-color: $return-button-colour;

  margin: 0 0.15em;
}
#rma_create_form {
  .form-create-return-title {
    font-weight: bold;
  }

  .label {
    font-weight: 600;
  }

  .action {
    @extend %button;
    @extend %button--small;

    &.add,
    &.remove {
      @extend %button--outline;
    }

    margin: 0 0.15em;
  }

  .field {
    margin: 10px;
    input[type="text"], input[type="number"] {
      @include form-input;
    }

    textarea {
      border: 1px solid currentColor; // Use pixels to make sure border always displays
      padding: 5px;
      height: 5rem;
    }
  }

  #registrant-options { //Display a loading animation whilst the form fields are loaded
    min-height: 100px;
    background: url('../images/loader.gif') center center no-repeat;

    .fields { //Once the form has loaded, giving it a white background will mean the loading image will be hidden
      background-color: #fff;
    }
  }

  .actions-toolbar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px 0;

    &.remove-return-product {
      flex-direction: row;
    }

    .back {
      border-color: $color-shop-accent;
      background: none;
      color: $color-shop-accent;
    }
  }

  /*Positioning the Add Item to Return button*/
  // This button will be displayed in the same row as a 'Remove' button, if there are multiple products added to a return
  // on the Create New Return page
  // If there is only 1 item added to the return, then there is no Remove button, so having a negative margin on the Add Item button
  // would make it overlap the form fields.
  // To fix this, when there is only one .fields.additional div in the #registrant-options container, it will have the same
  // margin applied to its bottom to cancel out the negative top margin on the Add Item button, therefore preventing the
  // button from overlapping
  #registrant-options {
    .fields:only-child {
      margin-bottom: 50px;
    }
  }
  .add-item-to-return-toolbar {
    margin-top: -50px;
  }
}

/* Create Return Page*/
#add-item-to-return {
  &[disabled] {
    visibility: hidden
  }
}
/* End of Create Return Page*/

/* Returns Page */
.rma {
  .order-title {
    @include heading1;
  }
}


#my-returns-table {
  width: 100%;

  thead {
    text-align: center;
  }

  thead, tbody {
    border-bottom: 1px solid #000000;
  }

  td {
    padding: 5px 0;
  }

  caption {
    display: none;
  }

  .actions {
    text-align: right;

    .action {
      @extend %button;
      @extend %button--small;

      margin-bottom: $action-spacing;
    }
  }
}
/* End of Returns Page */

/* View Return Page */
.return-status {
  display: block;
  text-align: center;
}
.block-return-details, .block-returns-comments {
  .box {
    padding: 1.5em 0;
  }

  strong {
    font-weight: 600;
  }

  .returns-comment-date {
    width: 100%;
    padding-top: 1.5em;
  }

}
.table-returns {
  @extend %responsive-table;
  @extend %responsive-table-no-caption;

  .table { //Questions and answers table
    width: 100%;
    margin-top: 30px;
  }

  .qna-table {
    thead {
      tr {
        th {
          text-align: left;
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: left;
        }
      }
    }
  }
}
.returns-details-items {
  .block-title strong {
    font-weight: 600;
  }
}
.download-label-button {
  @extend %button;
  @extend %button--small;
}
/* End of View Return Page */

/* Guest Orders and Returns Search Form */
#oar-widget-orders-and-returns-form {

  .label {
    padding: 10px 0 5px;
  }

  .field.find { //'Find Order By' drop down - it's a required form field, but will always be email, so just visually hiding it
      display: none;
  }

  #oar-zip {
    display: none;
  }

  .submit.primary { //Continue button
    @include widget-button;
    margin-top: 10px;
  }
}
/* End of Guest Orders and Returns Search Form */

/* Guest View Order Page */
.guest-rma-header {
  .my-account__action {
    float: none;
    display: inline-block;
  }
  .my-account__list--action {
    text-align: center;
  }

  .my-account__action {
    @extend %button;
    @extend %button--small;

    margin: 0 0.15em;
  }

  .my-account__action--print {
    display: none;
  }

  .order-summary-instructions {
    padding-bottom: 10px;
  }
}
/* End of Guest View Order Page */

.guest-rma-content {
  .my-account__order-navigation {
    border-bottom: 1px solid #000;
  }

  .my-account__order-navigation-item {
    margin-bottom: -1px;

    .my-account__order-navigation-link {
      border-bottom: none;
    }

    &--current {
      .my-account__order-navigation-link {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .my-account__order-navigation-link {
    @extend %button--small;
  }
}

.my-account__group {
  .my-account__action {
    @extend %button;
    @extend %button--small;

    margin: 0 0.15em;
  }
  .my-account__order-title {
    @include large-text;
    font-size: 18px;
  }

  .my-account__action--print {
    display: none;
  }
}

.returns-please-note {
  font-size: 14px;
}
.returns-please-note span {
  font-weight: bold;
}