.fotorama.fotorama--fullscreen {
  @include media-query(large-and-up) {
    bottom: 50px !important;
    left: 40px !important;
    position: fixed !important;
    right: 71px !important;
    top: 50px !important;
    width: inherit !important; /* IE Fix */
    width: initial !important;
    border: 1px solid black !important;
  }
}

.fotorama--fullscreen {
  .fotorama__stage {
    @include media-query(large-and-up) {
      height: 100% !important;
    }
  }

  .fotorama__wrap {
    position: relative;
    height: 100% !important;
  }

  .fotorama__nav-wrap {
    position: absolute;
    bottom: 0;
  }

  .fotorama__stage {
    .fotorama__stage__frame {
      .fotorama__img--full {
        max-height: calc(100% - 114px); //Subtract height of thumbnails at base of popup
      }
    }
  }
}

.fotorama.fotorama--fullscreen {
  @include media-query(large-and-up) {
    .fotorama__stage__frame .fotorama__img--full {
      margin-top: 3px;
    }
    .fotorama__nav__shaft {
      top: 3px;
    }
  }
}

.fotorama.fotorama--fullscreen .fotorama__nav-wrap {
  text-align: center;
}
