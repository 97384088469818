.footer-payment-methods__list {
  margin: 1rem 0;
  padding: 0;
  list-style: none;
}

.footer-payment-methods__item {
  display: inline-block;
  margin: 0 3px;
  width: 50px;
}
