.modal-popup.afterpay-learn-more {
  .modal-header,
  .modal-content {
    @media (min-width: 992px) {
      width: 597px;
    }
  }

  .modal-header {
    .action-close {
      padding: 6px !important; // !important to overcome specificity in afterpay.css
    }
  }
}
