.braintree-hosted-fields {
  height: 2.74rem;
  border: 1px solid currentColor;
  font-size: inherit;
  font-family: inherit;
  padding: 0 1rem;
  border-radius: 5px;
}

.braintree-hosted-fields-focused {
  border-color: #000000;
}

.braintree-hosted-fields-invalid {
  border-color: #DE0031 !important;
}

.braintree-hosted-fields-valid {
  border-color: green !important;
}

.braintree-form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  .react-checkout-loading__block {
    height: 100%;
  }
}

.braintree-form .input-container {
  width: 50%;
  padding: 0 1rem;
}

.braintree-form .cc-input-container {
  width: 100%;
}
