/*------------------------------------*\
    #MunroIcons
\*------------------------------------*/

@font-face {
    font-family: "munro-icons";
    src: url("#{$path-fonts}/munro-icons.eot?lvt4mo");
    src: url("#{$path-fonts}/munro-icons.eot?lvt4mo#iefix") format("embedded-opentype"),
    url("#{$path-fonts}/munro-icons.woff?lvt4mo") format("woff"),
    url("#{$path-fonts}/munro-icons.ttf?lvt4mo") format("truetype"),
    url("#{$path-fonts}/munro-icons.svg?lvt4mo#munro-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}
