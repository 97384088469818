//Need to ovecome the ._dirty class that is added to the form label for some reason..
.one-page-checkout__email {
  .form__field--email-address,
  .form__field--password {
    label {
      opacity: 1 !important;
      z-index: 1 !important;
    }
  }
}
