.site-footer__wrapper .newsletter-footer-block-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: nowrap;
}

.newsletter-footer-block-wrapper .site-footer__subscribe.newsletterSignUp,
.newsletter-footer-block-wrapper .fusion-rewards-signup {
  flex: 1 1 50%;
}

.site-footer__subscribe {
  color: #000;
  background-color: #E5E6E8;
}

.site-footer__subscribe-input {
  background-color: #FFF;
  color: #000;
}

.site-footer__subscribe-input::placeholder {
  color: #000;
  font-size: 14px;
  text-transform: capitalize;
}

.site-footer__subscribe-container li {
  padding: 0;
}

.site-footer__submit {
  margin-left: -10px;
}

.site-footer__email-container {
  width: 65%;
}

.site-footer__subscribe p {
  margin: 0 auto;
  max-width: 75%;
  font-size: 17px;
}

.newsletterSignUp .newsletter-signup_line {
  white-space: initial;
}

.subscribe__label {
  font-size: 30px;
  margin-bottom: 20px;
}

.site-footer__subscribe-container li + li {
  margin-top: initial;
}

.newsletter-footer-block-wrapper .site-footer__subscribe.newsletterSignUp {
  padding: 20px;
}

.subscribe-top-icon:before {
  font-family: 'FontAwesome';
  content: "\f0e0";
  font-size: 34px;
}

@media (max-width: 960px) {
  .site-footer__wrapper .newsletter-footer-block-wrapper {
    flex-wrap: wrap;
  }
  .newsletter-footer-block-wrapper .site-footer__subscribe.newsletterSignUp, .newsletter-footer-block-wrapper .fusion-rewards-signup {
    flex: 1 1 100%;
  }
}

@media (max-width: 640px) {
  .site-footer__email-container {
    width: 100%;
  }
  .site-footer__submit-container {
    width: 100%;
  }

  .site-footer__subscribe-container li + li{
    margin-top: 10px;
  }
  .site-footer__submit {
    margin-left: 0;
  }
  .site-footer__subscribe p {
    font-size: 14px;
  }
  .site-footer__subscribe-input::placeholder {
    text-align: center;
  }
}
