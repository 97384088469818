@use "sass:math";

.site-footer {
  padding: 0;
}

.site-footer__subscribe {
  text-align: center;
  background-color: $color-site-footer-subscribe;
  padding: 83px 20px;
  color: $color-site-footer-subscribe-text;

  p {
    font-size: 24px;
    margin:5px 0 0;
  }

}

.site-footer__subscribe-container {
  margin: 27px auto 0;
  width:100%;
  max-width:832px;

  * {
    display: inline-block;
  }

  li {
    padding:0 5px;
  }

  li + li {
    margin-top: 10px;
    @include media-query(medium-and-up) {
      margin-top: 0;
    }
  }

}

.subscribe__label {
  font-size: 36px;
  text-transform: uppercase;
  line-height: 1em;
  font-family: $font-family-headings;
  margin:0;
}

.site-footer__email-container {
  width:100%;
  vertical-align: top;

  @include media-query(small-and-up) {
    max-width: 620px;
    width: percentage(math.div(620, 832));
  }

}

.site-footer__subscribe-input {
  width:100%;
  @include media-query(medium-and-up) {
    text-align: left;
  }
  border: 1px solid $color-site-footer-subscribe-text;
  padding: 15px;
  height: 44px;

  &::-webkit-input-placeholder { /*Chrome*/
    color: $color-site-footer-subscribe-text;
    text-transform: uppercase;
  }
  &::-moz-placeholder { /*Firefox 18-*/
    color: $color-site-footer-subscribe-text;
    text-transform: uppercase;
  }
  &:-moz-placeholder { /*Firefox 19+*/
    color: $color-site-footer-subscribe-text;
    text-transform: uppercase;
  }
  &:-ms-input-placeholder { /* IE */
    color: $color-site-footer-subscribe-text;
    text-transform: uppercase;
  }

}

.site-footer__submit-container {
  width:100%;

  @include media-query(small-and-up){
    width: percentage(math.div(212, 832));
  }

}

.site-footer__submit {
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding:0;

}




/* Styling for the container that holds the sitemap links and contact us block */
 div.footer-sitemap-contact {
  background-color: $color-background-footer-sitemap-contact;
  color: #A3A3A3;
   padding: 0;
  @include media-query(medium-and-up) {
    padding: 40px 70px;
  }
}

.footer-sitemap-contact:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.footer-seo-text {
  display: inline-block;
  color: #6A6C6E;
  padding: 0 10px;

  @include media-query(medium-and-up){
    width: 90%;
  }

  .title {
    text-align: left;
    padding-top: 1.85rem;
    font-size: 12px;
    color: #000;
    text-decoration: none;
    font-family: $font-family-base;
    margin-bottom: 10px;

    @media (max-width: 960px) {
      font-weight: bold;
    }
  }

  p {
    font-size: 11px;
    margin-bottom: 10px;

    a {
      color: $color-shop-accent;
    }
  }
}
