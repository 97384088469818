/*------------------------------------*\
    #FILTER
\*------------------------------------*/

/**
 * PLP page filter
 **/

/*aside*/
.filter { }


/*h1, h2, dt*/
.filter__title {
    color: inherit;
    font-size: 100%;
    font-family: inherit;
    font-weight: bold;
    padding: 0;
    margin-bottom: 0;
    text-align: left;
}


/*div*/
.filter__current {
    margin-bottom: $spacing-unit-base;
}


/*span*/
.filter__remove {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    width: $spacing-unit-base;

    &:before {
        content: "\00d7";
    }
}


/*dl*/
.filter__category {
    & > * {
        float: none;
        margin: 0;
    }
}


/*ol*/
.filter__options {
    @extend %list-bare;

    margin-bottom: $spacing-unit-base;
}


/*a*/
.filter__option {
    @include aligent-ui-link;
}


/*span*/
.filter__count {

    &:before {
        content: "(";
    }

    &:after {
        content: ")";
    }
}