/*------------------------------------*\
    #CREATE ACCOUNT
\*------------------------------------*/

.create-account {
  @extend %layout;
}

.create-account__item {
  @extend %layout__item;
}

.create-account__item--personal-information,
.create-account__item--sign-in-information {
  @extend %u-1-of-2-medium-and-up;
}
.form--create-account {
  margin: 0 10px 0 10px !important;
}

.create-account .mage-error {
  color: red;
  border-color: red !important;
}

.create-account .password-validated {
  display: block !important;
  color: green;
  border-color: green !important;
}

.customer-account-create .create-account__item--personal-information,
.customer-account-create .create-account__item--sign-in-information,
.customer-account-create .create-account__item--newsletter-signup,
.captcha .captcha-image {
  width: 100% !important;
  padding-left: 0px !important;
}
.customer-account-create .create-account__item--personal-information .form__field,
.customer-account-create .create-account__item--newsletter-signup .captcha {
  width: calc(50% - 4px);
  display: inline-block;
  vertical-align: top;
}

.customer-account-create .create-account__item--personal-information .form__field--last-name,
.customer-account-create .create-account__item--personal-information .customer-dob-wrapper,
.customer-account-create .create-account__item--personal-information .form__field--confirm-password,
.customer-account-create .create-account__item--personal-information .captcha .label,
.customer-account-create .create-account__item--personal-information .captcha .control {
  padding-left: 20px;
}
.customer-account-create .create-account__item--personal-information .customer-dob-wrapper {
  margin-top: -2px !important;
}
.form__field--text .form__label,
.form__field--text .form__label._dirty,
.form__field--text .form__label ._focus,
.form__field--text .form__label ._active {
  opacity: 1;
  z-index: 0;
}
.customer-account-create .create-account__item--personal-information .captcha .control {
  width: 100%;
}
.create-account {
  .form__input,
  .form__input[type="text"],
  ._has-datepicker,
  input[type="text"] {
    border-radius: 6px;
    color: black;
  }

  .form__field--text .form__label,
  .customer-dob-wrapper .label,
  .captcha .label {
    margin-bottom: 5px;
    font-weight: 600;
  }
}

#custom-errors-password, #custom-errors-password-customer{
  display: none;
  font-size: 14px;

  .password-length-cond,
  .password-letters-cond,
  .password-characters-cond {
    display: block;
  }
}
.password-length-cond:before,
.password-letters-cond:before,
.password-characters-cond:before {
  font-family: "FontAwesome";
  font-size: 4px;
  content: "\f111";
  padding-right: 8px;
  padding-left: 20px;
  vertical-align: middle;
}

#custom-errors-password .invalid-cond , #custom-errors-password-customer .invalid-cond{
  color: red;
}
#custom-errors-password .valid-cond ,#custom-errors-password-customer .valid-cond{
  color: green;
}
#custom-errors-password .invalid-cond:before, #custom-errors-password-customer .invalid-cond:before {
  color: red;
  content: "\f00d";
  font-size: 10px;
}
#custom-errors-password .valid-cond:before, #custom-errors-password-customer .valid-cond:before {
  content: "\f00c";
  font-size: 10px;
}

@media (max-width: 960px) {
  .customer-account-create .create-account__item--personal-information .form__field,
  .customer-account-create .create-account__item--newsletter-signup .captcha {
    width: 100%;
    padding-left: 0 !important;
  }
}
.form__field--password .password-status,
.form__field--password .confirm-password-status {
  position: absolute;
  right: 10px;
  top: 34px;
}

.form__field--password .password-status.invalid:after,
.form__field--password .confirm-password-status.invalid:after {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f057";
  display: block;
  color: red;
}

.form__field--password .password-status.valid:after,
.form__field--password .confirm-password-status.valid:after {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f058";
  display: block;
  color: green;
}
.create-account {
  .password-validated {
    color: green;
  }
}

.checkout-index-index .login-password-container.password-container--validation-error .form-input {
  color: #c7002c;
  border: 1px solid #c7002c;
}

.checkout-index-index .login-password-container.password-container--empty .form-input {
  color: black;
  border: 1px solid #ccc;
}

.checkout-index-index .login-password-container .form-input {
  color: green;
  border: 1px solid green;
}
