.atl-container .checkbox-container .input-label {
    font-weight: normal;
    text-transform: initial;
}

.atl-container .checkbox-container .input-label p {
    font-size: 16px;
}

.atl-container .checkbox-container .input-label p a{
    display: block;
    font-size: 14px;
    text-decoration: underline;
}


.atl-container .checkbox-container .input-label .atl-terms-and-conditions {
    display: block;
    font-size: 14px;
    text-decoration: underline;
}

.checkout-index-index .ReactModalPortal .ReactModal__Content {
    max-width: initial !important;
    width: 80% !important;
}

.atl-container .checkbox-container .input-label .atl-terms-and-conditions:hover,
.atl-container .checkbox-container .input-label .atl-terms-and-conditions:focus,
.ReactModalPortal .ReactModal__Content .button-component-button:hover,
.ReactModalPortal .ReactModal__Content .button-component-button:focus {
    background-color: initial;
}

.ReactModalPortal .ReactModal__Content .button-component-button {
    right: 10px;
    position: absolute;
    top: 15px;
}
.ReactModalPortal .ReactModal__Content .button-component-button span {
    display: none;
}

.ReactModalPortal .ReactModal__Content .button-component-button:before {
    font-family: Material Icons;
    font-size: 26px;
    content: "\e14c";
    display: block;
    height: 17px;
    line-height: 17px;
}

.ReactModalPortal .ReactModal__Content h2 {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}
