.pdp-promo-wrapper {
    background-color: #E6E6E6;
    padding: 10px;
}
.pdp-promo-wrapper p  {
    margin-bottom: 0;
}
.promo-code-wrapper p strong {
    color: #de0031;
    font-weight: bold;
    text-transform: uppercase;
}
.promo-info-wrapper .promo-step {
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 5px;
}
.promo-info-wrapper .promo-block-tandc {
    display: inline-block;
    font-size: 12px;
    color: #666;
    margin-bottom: 0;
}
.promo-info-wrapper .promo-step strong {
    background-color: #de0031;
    color: #FFF;
}
.promo-info-wrapper .promo-step-two,
.promo-info-wrapper .promo-step-three,
.promo-code-wrapper #promo-code-click-copy,
.promo-code-wrapper #promo-code-copied {
    display: none;
}
.promo-code-wrapper #promo-code-click-copy {
    cursor: pointer;
    color: #de0031;
    font-weight: bold;
    text-transform: uppercase;
}

.promo-code-wrapper #promo-code-copied {
    color: #de0031;
    text-transform: uppercase;
}
