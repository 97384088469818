/*------------------------------------*\
    #WRAPPER
\*------------------------------------*/

/**
 * Container with max-width for ensuring content width is restricted uniformly
 * on large screens.
 */

$spacing-unit-page-gutter: $spacing-unit-base !default;


.wrapper,
%wrapper {
  margin: {
    left: auto;
    right: auto;
  }
  max-width: $max-width;
}


.wrapper--gutter,
%wrapper--gutter {
  padding: {
    left: $spacing-unit-page-gutter;
    right: $spacing-unit-page-gutter;
  }
  max-width: $max-width + 2 * $spacing-unit-page-gutter;

  @each $breakpoint, $gutter in $spacing-unit-page-gutter-responsive {
    @include media-query($breakpoint) {
      padding: {
        left: $gutter;
        right: $gutter;
      }
      max-width: $max-width + 2 * $gutter;
    }
  }
}
