/*------------------------------------*\
    #TOOLBAR
\*------------------------------------*/

/*div*/
.toolbar {
  @extend %clearfix;
  padding: 0 20px;
  margin-bottom: $spacing-unit-base;
  height: 35px;
}


/*div*/
.toolbar__sorter {
  width: 50%;
  float: left;
  height: 100%;

  @include media-query(medium-and-up) {
    float: right;
    width: inherit; /* IE Fix */
    width: initial;
  }

  .sorter__action {
    display: none;
  }

  .sorter-container {
    width: 100%;
    height: 100%;

    border: 1px solid #000000;
    position: relative;

    @include media-query(medium-and-up) {
      min-width: 192px;
    }

    .sorter__label:after {
      @include icon-font;
      position: absolute;
      top: 3px;
      right: 5px;
      pointer-events: none;
      @include media-query(medium-and-up) {
        right: 0;
      }
      display: inline-block;
      content: "\e313";
    }

    select {
      @include functional-text-black;
      background: transparent;
      border: 0;
      border-radius: 0;
      -webkit-appearance: none;
      width: 100%;
      padding: 0 20px;
      height: 2rem;
      @include media-query(medium-and-up) {
        padding: 0 5px;
      }
    }
  }
}


/*div*/
.toolbar__pager {
  display: none;
}

.paging-container {
  .toolbar__prod-count,
  .toolbar__prod-count-mobile {
    display: none;
  }
  // Make sure the pagingation at the end of the page isn't hidden by CSS (for SEO)
  .toolbar__pager {
    display: block;
  }
  .toolbar__sorter {
    display: none;
  }
}

.toggle-refine-filters-container {

  width: 50%;
  float: right;
  height: 100%;
  padding-left: 20px;
  @include media-query(medium-and-up) {
    display: none;
  }

  .toggle-refine-filters {
    @include black-white-button;
    width: 100%;
    height: 100%;
  }
}

/* 1527 - Product Count PLP */
.toolbar__prod-count {
  display: inline-block;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 6px;
}
.toolbar__prod-count-mobile {
  display: none;
  text-transform: uppercase;
  font-size: 15px;
}
@media (max-width: 959px) {
  .toolbar__prod-count {
    display: none;
  }
  .toolbar__prod-count-mobile {
    display: block;
    text-align: center;
  }
}

/* Wishlist Pager */
.wishlist-toolbar .toolbar__pager {
  display: block;
}

/* Account Order Pager */
.sales-order-history .toolbar__pager {
  display: initial;
}