@use "sass:math";
/*------------------------------------*\
    # GENERAL SETTINGS
\*------------------------------------*/

/*------------------------------------*\
    ## FILE PATHS
\*------------------------------------*/

$path-fonts: '../fonts';


/*------------------------------------*\
    ## COLOURS
\*------------------------------------*/

/**
 * All colours should be defined here; colour values should not be set in
 * partials.
 *
 * All colour variables should be prefixed with `$color-`. For example,
 * `$color-red`. Note the US spelling; this has been chosen to reduce
 * typographic errors in the `color` property.
 *
 * Colour variables can either be descriptive (eg `$color-red`) or
 * functional (eg `$color-text`).
 *
 * Tints or shades should use the parent variables with a `--` suffix. For
 * examples, `$color-red--light`.
 **/
$color-light-grey: #dddddd;
$color-mid-white: #f7f7f7;
$color-mid-grey: #999;
$color-top-info-bar-text: #000;
$color-site-header-border: #fff;
$color-navigation-text: #000;
$color-navigation-text-hover: #000;
$color-navigation-border: #fff;
$color-navigation-background: #fff;
$color-stockists: $color-navigation-text;

$color-background: $color-mid-white;
$color-header-background: $color-background;
$color-background-content: #fff;
$color-links: inherit;
$color-text: #333;
$color-shop-accent: #65BBB0;
$color-background-counter-number: #65BBB0;
$color-site-footer-subscribe: #333;
$color-site-footer-subscribe-text: #fff;
$color-sale: #FF6B39;
$color-default: #2D2F3B;
$header-background-color: $color-background;
$menu-items-border-colour: #D3D3D3;
$color-popup-overlay: rgba(0, 0, 0, 0.5);
$color-popup-background: #FFFFFF;
$return-button-colour: $color-shop-accent;

$header-background-color: $color-background;
$menu-items-border-colour: #D3D3D3;
$color-popup-default: #E5E5E5;
$color-popup-background: #ffffff;

$color-cart-coupon-message: #32cd32;

$color-menu-items-border: #D3D3D3;

$color-background-footer-sitemap-contact: $color-background;

$widget-background-color: #fff;
$widget-hover-color: #F4F4F4;
$widget-button-border: none;

$product-list-brand-display: none;

$color-shop-accent-hover: $color-shop-accent;
$color-input-placeholder: #666;
$color-border-outline: #666;
$color-border-outline-light: #CCC;
$color-grey: #e8e8e8;
$color-grey-accent: #dadada;
$color-dark-grey: #737373;
$color-dark-grey-accent: #5e5e5e;
$color-darker-grey: #454545;
$color-darker-grey-accent: #292929;
$color-black: #000;
$color-black-accent: #5e5e5e;
$color-white: #FFFFFF;
$color-background-counter-number: $color-shop-accent;
$color-swatch-border: $color-shop-accent;
$color-delivery-message-font-color: #D08B0E;
$color-delivery-message-font-background: #F3E5C8;
$address-validation-hover: $color-light-grey;

$color-rule-coupon-green:#006F40;

/*------------------------------------*\
    ## FONTS
\*------------------------------------*/

/**
 * All fonts should be defined here.
 *
 * All font-family variables should be prefixed with `$font-family-`.
 **/

/*  At the moment Material Icons are used for many of the icons on the page, however for some sites in the components
    header we may need to override them. */
$font-family-icons: 'Material Icons' !default;
$font-family-components-header: $font-family-icons;

$wishlist-icon: '\e87d';
$minicart-icon: '\e8cb';

$font-family-base: "neuzeit-grotesk", verdana, helvetica, arial, sans-serif;

$font-size-base: 16px;  // Must be px
$line-height-base: 1.5;  // Must be unitless ratio

$font-size-icon-navigation-bar: 26px;
$font-family-headings: 'futura-pt', sans-serif;

$font-family-base-functional: 'futura-pt', sans-serif;



/*------------------------------------*\
    ## LAYOUT
\*------------------------------------*/

$spacing-unit-base: $line-height-base * 1rem; // Must be rems
$spacing-unit-base--small: math.div($spacing-unit-base, 2); // Used for compatibility with inuitcss

$spacing-unit-page-gutter: math.div($spacing-unit-base, 2);  // Padding at the viewport boundary
$spacing-unit-page-gutter-responsive: (
  "medium-and-up": rems(30px)
);

$max-width: rems(1260px);
$min-width: rems(320px);


$layout-gutter: 20px;

$social-links-width: 26px;

$filterGutterSize: 5px;

$swatch-size: 35px; /* A swatch is square, so this is width and height */

$stockist-icon-left-offset: -25px;

/*------------------------------------*\
    ## ANIMATION
\*------------------------------------*/

$transition-time: 300ms;


/*------------------------------------*\
    ## MEDIA QUERIES
\*------------------------------------*/

/**
 * This notation has been chosen as it is compatible with Inuit CSS
 * `media-query()` mixin found in `inui-responsive-tools/_tools.responsive`.
 *
 * Note that the largest media query should be smaller than $max-width,
 * while the smallest media query should be  larger than $min-width.
 **/

$media-queries: (
    "small-and-up"    "screen and (min-width: 480px)",
    "tablet-and-up"    "screen and (min-width: 720px)",
    "medium-and-up"    "screen and (min-width: 960px)",
    "large-and-up"  "screen and (min-width: 1024px)"
);

/* Stock Remaining Message */
.product__stock-message {
    color: $color-shop-accent;
}


/*------------------------------------*\
    ## BREAKPOINTS
\*------------------------------------*/
$breakpoint1: 1280px;
$breakpoint2: 960px;
$breakpoint3: 768px;
$breakpoint4: 600px;
$breakpoint5: 450px;
