// Hide the afterpay modal when it is not in the modal-content container
// Not being there means the element hasn't yet been initialised as a modal, so Magento's modal rules aren't yet applied
// which will cause the afterpay modal content to appear on the page
div:not(.modal-content) > #afterpay-modal-popup,
div:not(.modal-content) > #afterpay-learnmore-modal-popup {
  display: none;
}

// Hide the message from cart page
.checkout-cart-index .afterpay-checkout-note {
  display: none !important;
}

.checkout-payment-method #afterpaypayovertime-method .payment-icon {
  max-width: initial !important;
}