.wishlist-index-index { /* on body element - ensures the rules applied here don't affect any other pages*/

  .product-list__item {
    width: 100% !important;

    @media (min-width: 866px) {
      width: 50% !important;
    }
    @media (min-width: 1146px) {
      width: 33.33% !important;
    }
  }

  .fieldset {
    margin-bottom: $layout-gutter;

    &:after { /* Clear float */
      content: " ";
      display: block;
      clear: both;
    }
  }

  .product-tile__link--name {
    font-weight: 500;
  }

  .price-box {
    font-weight: 600; /*Makes it consistent with the font-weight on PLP, as the price is in a <h2> there*/
    display: block;
  }

  .product-list--wishlist {
    .product-tile__label--qty,
    .product-tile__input--qty {
      display: inline-block;
      vertical-align: middle;
    }
    .product-tile__label--qty {
      width: 30px;
    }
    .product-tile__input--qty {
      width: 40px;
      padding: 0 5px;
      text-align: center;
      border: 1px solid #000000;
    }
    .product-tile__action--add-to-cart {
      @include widget-button;
      width: 110px;
      padding: 5px;
      float: right;
    }
  }

  .my-account__list--action {
    margin-top: $layout-gutter;

    li {
      display: inline-block;
      vertical-align: top;

      &:nth-child(1),  /* parent of .my-account__action--update */
      &:nth-child(3) { /* parent of .my-account__action--add-all-to-cart */
        float: right;
        margin-left: $layout-gutter;
      }

      &:nth-child(2) { /* parent of .my-account__action--share */
        display: none;
      }

      &:nth-child(4) { /* parent of .my-account__action--back */
        float: left;
      }
    }
  }

  .product-list__item .product-tile {
    /* Action buttons */
    .product-tile__action--edit,
    .product-tile__action--delete {
      &:before {
        @include icon-font;
        font-size: 16px;
        line-height: 23px;
        vertical-align: top;
      }

      /* Transparent so there is no movement when the black border is shown */
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      visibility: hidden;

      &:hover {
        border-bottom-color: #000000;
      }
    }

    &:hover{
      .product-tile__action--edit,
      .product-tile__action--delete {
        visibility: visible;
      }
    }

    &:after { /* Clear float */
      content: " ";
      display: block;
      clear: both;
    }
  }

  .product-tile__action--edit {
    &:before {
      content: '\e3c9';
    }
    float: left;
    text-decoration: none;
  }

  .product-tile__action--delete {
    &:before {
      content: '\e14c';
    }
    float: right;
  }
}
.navigation-bar__group--account,
.site-header__mobile-links {
  .wishlist {
    position: relative;

    .counter {
      @include counter-number;
    }
  }
}

/* 1565 - Wishist Improvements */
.wishlist-index-index .product-list__item .product-tile .product-tile__action--edit,
.wishlist-index-index .product-list__item .product-tile .product-tile__action--delete {
  visibility: visible !important;
}

.wishlist-index-index .product-list__item .size-label {
  text-transform: capitalize;
}

/* M255 - Wishlist Menu Hide */
.wishlist-index-index .page-layout__item--secondary {
  display: none !important;
}
.wishlist-index-index .page-layout--two-col .page-layout__item--primary {
  width: 100% !important;
}
