.my-account__group {
  &.my-account__group--address-book-default {
    margin-bottom: 0;
  }
}
.my-account__address {
  margin-bottom: 10px;
}
.my-account__action {
  margin-top: 0;
}
.my-account__section {
  margin-bottom: 20px;
}
.my-account__group--address-book-additional {
  .my-account__list--action {
    .my-account__list-item {
      margin-bottom: 10px;
    }
  }
}
.my-account__group--address-book-address {
  .postcode-autosuggest {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #FFF;
    list-style: none;
    z-index: 1;
    padding: 0;
    margin: 2px 0 0 0;
    border: 2px solid #000;
    max-height: 195px;

    .postcode-suggestion {
        padding: 7px 15px;
        width: 100%;

        &:hover {
            background-color: $address-validation-hover;
            color: #000;
            cursor: pointer;
        }
    }
  }
}

/* START MMI-1597 - Address Validation My Account */
.address-validation-enabled.customer-address-form {
    fieldset.street, .form__field--zip, .form__field--city, .form__field--region {
        display : none;
    }
}

.customer-address-form {
    #show-full-address-line {
        color: $color-shop-accent;
        text-decoration: underline;
        margin-top: -23px;
        text-transform: uppercase;
        cursor: pointer;
        display: none;
    }
    
    #address-finder-text {
        display: block;
        text-align: right;
    }
    
    .full-line-address #address_suggestion {
        position: relative;
        left: 0;
        right: 0;
        background-color: #FFF;
        z-index: 1;
        margin: 2px 0 0 0;
        list-style: none;
        border: 2px solid #000;
    }

    .full-line-address #address_suggestion li {
        padding: 7px 15px;
        width: 100%;

        &:hover {
            background-color: $address-validation-hover;
            cursor: pointer;
        }
    }

    .full-line-address .address-suggest-manual-option {
        text-transform: initial;
        border-top: 2px solid #000;
        font-weight: 500;

        &:hover {
            background-color: $address-validation-hover;
            cursor: pointer;
        }
    }

    .clear-icon {
        text-transform: lowercase;
        font-size: 24px;
        position: absolute;
        right: 10px;
        cursor: pointer;
        top: 22px;
    }
}

/* General Styling Start */
.showField {
    display: block !important;
}

.hideField {
    display: none !important;
}

.address-validation-enabled fieldset.street > .form__label {
    display: none !important;
}

// Hide NZ State field as not needed
.address-validation-enabled.customer-address-form .form__field--region {
    input#region {
        display: none !important;
    }

    .form__label {
        display: none !important;
    }

    // Show country label
    .form__field--country .form__label {
        position: initial !important;
        text-align: left !important;
        line-height: initial !important;
        padding: initial !important;
        height: initial !important;
        width: initial !important;
    }
}

// Background colour for disabled inputs + remove pointer events to prevent focus
.my-account__group--address-book-address {
    .form__field--region .form__input, .form__field--city .form__input {
        background-color: #F2F2F2;
        pointer-events: none;
    }
}

.my-account__group--address-book-contact,
.my-account__group--address-book-address {
    width: 100%;
}

@media (max-width: 960px) {
    .my-account__group--address-book-contact {
        margin-top: 20px;
    }
}
/* General Styling End */
/* END MMI-1597 - Address Validation My Account  */