.checkout-app-form .input-field--password,
.checkout-app-form .input-field--email,
.checkout-app-form .input-field--tel,
.checkout-app-form .input-field--text,
.checkout-app-form .autocomplete-container,
.checkout-app-form .input-container,
.checkout-app-form .select-container {
  flex-direction: column;

  .input-field__label,
  label {
    margin-bottom: 5px;
  }

  select {
    border: 1px solid $color-border-outline;
    border-radius: 5px;
    padding: 10px 15px;
  }
}

.checkout-app-form {
  .input-container {
    margin-top: 25px;
  }

  h3 { // using element selector until react form gets updated
    margin-top: 20px;
    font-size: 22px;
    font-weight: 300;
    text-transform: uppercase;
  }
}

.shipping-method {
    .radio {
      display: none;

      &:checked {
        + .shipping-method__label {
          &:before {
            border: 0;
            background: #555;
            background-image: url("../images/check.svg");
            background-position: 4px 6px;
            background-repeat: no-repeat;
          }
        }
      }
    }

  .shipping-method__label {
    padding-left: 30px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      color: $color-white;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid rgba(#555, 0.2);
    }
  }
}

.checkout-app-form {
  hr {
    display: none;
  }

  .address,
  .manual-address-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input-container {
      width: 100%;
    }

    .delivery-address-first-name-container,
    .delivery-address-last-name-container {
      width: calc(50% - 10px);
      margin: 0;
    }

    .region-manual-shipping-address-container,
    .postcode-manual-container {
      width: calc(50% - 10px);
      margin-top: 25px;
    }

    .show-company {
      margin-top: 10px;
      text-decoration: underline;

      &:hover {
        background: none;
      }
    }
  }
}

.shipping-methods-container {
  .shipping-method {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .shipping-method__label {
    .shipping-label {
      text-transform: uppercase;
    }
  }
}

