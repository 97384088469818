/*------------------------------------*\
    #FORM
\*------------------------------------*/

/**
 * Apply universal styles to various inputs types.
 * Note that buttons have only minimal styling as they are used in a broader
 * range of applications than the other elements in this file. As such,
 * it's easier to add styles as required, than remove them when they're
 * not.
 **/


$form-input-height: rems(44px) !default;


/**
 * Style all button-style inputs together using a single placeholder selector
 **/

button,
[type="button"],
[type="file"],
[type="reset"],
[type="submit"] {
    @extend %button-input;
}

/**
 * Disable state for disabled buttons.
 */
button[disabled] {
    cursor: not-allowed;
}




/**
 * Style all single-line text-based inputs together using a single placeholder
 * selector
 **/

[type="date"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="range"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"] {
    @extend %text-input;
}





/**
 * Use minimal formatting for fieldsets and legends
 **/

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

/**
 * 1. Float and use 100% width to make sure <legend> elements are positioned
 *    correctly and consistently.
 **/

legend {
    float: left;    /* [1] */
    font-weight: bold;
    margin: 0;
    width: 100%;    /* [1] */
}





/**
 * Labels
 *
 * Labels will surround inputs to create large tap targets.
 **/

label {
    display: block;
}





/**
 * Override user-agent formatting on text-based inputs and buttons
 *
 * 1. Use inline-block for flexibility
 *
 * 2. Set vertical-alignment to prevent unwanted space below inline-block
 *    elements.
 **/

%button-input,
%text-input,
textarea {
    appearance: none;
    background: none;
    border: none;
    display: inline-block;  /* [1] */
    margin: 0;
    padding: 0;
    vertical-align: middle; /* [2] */
}

%text-input {
    height: $form-input-height;
}

%text-input,
textarea {
    width: 100%;
}





/**
 * Override user-agent styling on selects
 **/

%select,
select {
    $svg-triangle: '<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: #{$color-text}"></polygon></svg>';

    appearance: none;
    background-color: transparent;
    background-image: url('data:image/svg+xml;charset=utf8,#{_encode($svg-triangle)}'); // SVGs must be URL-encoded to work in IE
    background-position: right 0.5rem center, right;
    background-repeat: no-repeat;
    background-size: 9px 6px, auto;
    border: 1px solid currentColor;
    font-size: inherit;
    font-family: inherit;
    height: $form-input-height - 0.01rem; // Need to subtract 0.01rem to get the select to match height of input
    margin: 0;
    padding: 0 1rem;
    width: 100%;

    // Hide the dropdown arrow shown in newer IE versions
    &::-ms-expand {
        display: none;
    }
}

// Mark this module as imported, so it won't be imported again by modules that require it.
$form: true;

// Force non rounded corners on iOS
input[type="text"] {
  -webkit-appearance: none;
  border-radius: 0;
}