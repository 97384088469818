.site-footer__contact {
  @extend %u-1-of-1;
  @extend %u-1-of-3-medium-and-up;
  @include functional-text-grey;
  display: block;
  vertical-align: top;
  border-bottom: 1px solid #E8E8E8;
  padding: 30px 10px;

  @include media-query(medium-and-up) {
    display: inline-block;
    float: right;
    padding: 0;
    border-bottom: none;
  }
}
.site-footer__contact-button {
  @include widget-button;
  display: block;
  margin: 15px auto 0 auto;
}
.site-footer__contact {
  text-align: center;
  font-size:22px;
  a {
    text-decoration: none;
  }
}
