@use "sass:math";
/*------------------------------------*\
    #ONE-STEP-CHECKOUT
\*------------------------------------*/

/**
 * One-page checkout
 **/

@include private {
    $gutter: $spacing-unit-base;

    /*div*/
    .one-page-checkout {
        @extend %clearfix;
    }


    /*div*/
    .one-page-checkout__loader {
        padding: $spacing-unit-base;
        text-align: center;
    }


    /*ol*/
    .one-page-checkout__progress-bar {
        @extend %list-inline;
    }


    /*dl*/
    .one-page-checkout__estimate {}


    /*ol*/
    .one-page-checkout__steps {
        @extend %list-bare;
        @extend %u-2-of-3-small-and-up;
        @extend %u-3-of-5-medium-and-up;
        @extend %u-1-of-2-large-and-up;

        @include media-query(small-and-up) {
            float: left;
            padding-right: math.div($gutter, 2);
        }
    }


    /*aside*/
    .one-page-checkout__summary {
        @extend %u-1-of-3-small-and-up;
        @extend %u-2-of-5-medium-and-up;
        @extend %u-1-of-2-large-and-up;

        @include media-query(small-and-up) {
            float: right;
            padding-left: math.div($gutter, 2);
        }
    }
}
